import moment from "moment"

const allTargets = ['WA', 'WS', 'SP', 'KB', 'SD']

export default {
  namespaced: true,
  state: function () {
    return {
      newsData: [
        {
          date: moment("2024-06-20", "YYYY-MM-DD"),
          news: [
            {
              id: 37,
              title: "Sammlungsbenachrichtigungen",
              targets: ["WS"],
              message: "Sie haben ab sofort die Möglichkeit, sich über bevorstehende Sammlungen (immer Montags) und über beendete Sammlungen (am Folgetag) benachrichtigen zu lassen. Richten Sie dafür einfach die gewünschte(n) E-Mail-Adressen unter Organisation / Benachrichtigungen ein."
            }
          ]
        },
        {
          date: moment("2024-05-31", "YYYY-MM-DD"),
          news: [
            {
              id: 36,
              title: "Startseite",
              targets: allTargets,
              message: "Die Startseite für Nutzer mit mehr als einer Organisation ist übersichtlicher und intuitiver geworden."
            }
          ]
        },
        {
          date: moment("2024-04-29", "YYYY-MM-DD"),
          news: [
            {
              id: 35,
              title: "Individuelles Briefpapier für Zuwendungsbestätigungen",
              targets: ["WA", "WS"],
              message: "Um Zuwendungsbestätigungen in Ihrem Design auszustellen, können Sie ab sofort ein Briefpapier hinterlegen. Die Spenderdaten und Umstände der Spende werden wie gewohnt automatisch nach Geldeingang ausgefüllt. <a target=\"_blank\" href=\"https://digitalespende.de/zwb\">Mehr Informationen</a>"
            }
          ]
        },
        {
          date: moment("2024-03-28", "YYYY-MM-DD"),
          news: [
            {
              id: 34,
              title: "QR Code lässt sich einfacher speichern",
              targets: ['WA', 'WS'],
              message: "Die QR Codes zu kollekte.app / spende.app bzw. die Direktlinks lassen sich ab sofort mit einem Klick speichern - so behalten Sie einen Überblick, welcher QR Code zu welcher Sammlung gehört."
            }
          ]
        },
        {
          date: moment("2024-02-21", "YYYY-MM-DD"),
          news: [
            {
              id: 33,
              title: "Auswertungen mit mehreren Seiten sind druckbar",
              targets: allTargets,
              message: "Die Auswertungen unter Planung an der einzelnen Sammlung eignen sich gut, um sie auszudrucken und mit in ein Meeting oder eine Vorstandssitzung zu nehmen. Dabei werden jetzt auch mehr als 10 Zahlungen in der Druckansicht berücksichtigt."
            }
          ]
        },
        {
          date: moment("2023-09-01", "YYYY-MM-DD"),
          news: [
            {
              id: 32,
              title: "Dank in spende.app",
              targets: ['WS'],
              message: "Ändern Sie ab sofort selbstständig Ihren Dankestext für spende.app. Bedanken Sie sich z.B. mit einer persönlichen Nachricht oder informieren Sie die Spender mit einem Hinweis. Zusätzlich können Sie nun Social Links einpflegen. So können die Gebenden schnell und einfach auf ihren Social Media Profile über ihre Spende sprechen. So werden mehr Menschen auf Ihre Organisation aufmerksam."
            }
          ]
        },
        {
          date: moment("2023-02-03", "YYYY-MM-DD"),
          news: [
            {
              id: 31,
              title: "Verbesserungen am Dashboard",
              targets: allTargets,
              message: "Das Dashboard ist die Startseite, die sie nach dem Login begrüßt. Sie kommen jederzeit wieder dorthin, indem Sie auf das kollekte.digital Logo oben links in der Ecke klicken. Auch unter Einnahmen und Auswertungen finden Sie einen Link. Hier finden Sie jetzt allerlei Statistiken zu Ihren Sammlungen und Einnahmen. Wenn Ihnen eine bestimmte Auswertung fehlt, schreiben Sie uns eine kurze Mail an <a href=\"mailto:support@digitalwolff.de\">support@digitalwolff.de</a>!"
            }
          ]
        },
        {
          date: moment("2023-01-23", "YYYY-MM-DD"),
          news: [
            {
              id: 30,
              title: "Sammel-Zuwendungsbestätigungen",
              targets: ['WS'],
              message: "Das Dauerspendenmodul stellt standardmäßig Sammel-Zuwendungsbestätigungen je Kalenderjahr aus. Dabei werden alle zugehörigen Zahlungen in einer Bescheinigung zusammengefasst. Sie finden sie wie gewohnt unter Dokumenten & Listen / Zuwendungsbestätigungen. Die Erstellung der Bescheinigungen für 2022 ist erfolgt und Sie können sie ab sofort herunterladen!"
            }
          ]
        },
        {
          date: moment("2023-01-19", "YYYY-MM-DD"),
          news: [
            {
              id: 29,
              title: "Dashboard",
              targets: allTargets,
              message: "Direkt auf der Startseite, dem Dashboard finden Sie ab sofort noch mehr grafische Auswertungen. Der Zeitraum der Auswertung kann von Ihnen gewählt werden, so dass Sie Vergleiche anstellen können. Ihnen fehlt eine Auswertung? Geben Sie uns einen Hinweis per Mail an <a href= \"mailto:support@digitalwolff.de\">support@digitalwolff.de</a>"
            }
          ]
        },
        {
          date: moment("2023-01-03", "YYYY-MM-DD"),
          news: [
            {
              id: 28,
              title: "Jahresübergang",
              targets: allTargets,
              message: "Alle Auswertungen zeigen ab sofort standardmäßig die letzten 12 Monate an. Den Wechsel vom Anzeige-Zeitpunkt, mehr Statistik und Grafiken und vieles mehr steht für 2023 auf dem Plan. Seien Sie gespannt!"
            }
          ]
        },
        {
          date: moment("2022-12-16", "YYYY-MM-DD"),
          news: [
            {
              id: 27,
              title: "Liturgischer Kalender",
              targets: allTargets,
              message: "Die liturgischen Kalender für 2023 sind online, somit können Sie Kollekten weiterhin für z. B. \"Ostersamstag\" planen ohne sich über die Dauer Gedanken machen zu müssen. Nicht kirchliche Einrichtungen können diese Funktion nutzen, wenn es wiederkehrende Spendenanlässe jedes Jahr gibt - sprechen Sie uns an."
            }
          ]
        },
        {
          date: moment("2022-12-01", "YYYY-MM-DD"),
          news: [
            {
              id: 26,
              title: "Button Kassenschnitt auslösen",
              targets: ['SP', 'KB', 'SD'],
              message: "Unter Geräte finden Sie jetzt bei jedem Ihrer Geräte einen neuen Button \"Kassenschnitt\". Damit wird das Ende eines Tages / einer Sammlung beschrieben und ausgelöst, dass die Zahlungen an den Provider übermittelt werden. Wenn Geräte dauerhaft mit dem Strom verbunden sind, wird dieser Vorgang automatisch ausgelöst. Bei Geräten die nur zeitweise genutzt werden, sollte der Kassenschnitt manuell drei Werktage vor Monatsende ausgelöst werden."
            }
          ]
        },
        {
          date: moment("2022-06-21", "YYYY-MM-DD"),
          news: [
            {
              id: 25,
              title: "Neues Design",
              targets: allTargets,
              message: "Wie Sie vielleicht schon bemerkt haben, modernisieren wir das Design ein wenig und arbeiten an der Übersichtlichkeit der Menüs. Nutzer mit mehreren Organisationen haben links oben ein Suchfeld bekommen, der Link zur Adminansicht ist in die Fußzeile gerutscht. Auch das rechte Menü wird einige Veränderungen erfahren."
            }
          ]
        },
        {
          date: moment("2022-06-10", "YYYY-MM-DD"),
          news: [
            {
              id: 24,
              title: "Optionen",
              targets: ['WA', 'WS'],
              message: "Im rechten Menü unter Vertragsdaten finden Sie ab sofort eine Übersicht über die gebuchten Einstellungen und Optionen."
            }
          ]
        },
        {
          date: moment("2022-05-27", "YYYY-MM-DD"),
          news: [
            {
              id: 23,
              title: "Dauerspendenmodul verfügbar",
              targets: ['WS'],
              message: "Ab sofort ist das Dauerspendenmodul für Spendenzwecke buchbar. Bei der Anlage eines neuen Zwecks mit ausreichender Dauer, können Sie eine Checkbox ankreuzen und der Spendende kann eine monatliche / vierteljährliche / halbjährliche oder jährliche Spende vereinbaren. Im rechten Menü finden Sie eine Übersicht."
            }
          ]
        },
        {
          date: moment("2022-05-10", "YYYY-MM-DD"),
          news: [
            {
              id: 22,
              title: "Abgleichlisten und Rechnungen",
              targets: allTargets,
              message: "Wenn für Sie zutreffend, können Sie Ihre Abgleichlisten und Rechnungen ab sofort jederzeit nach Bereitstellung im rechten Menü herunterladen. Auf Wunsch versenden wir sie weiterhin gerne per Mail."
            }
          ]
        },
        {
          date: moment("2022-04-12", "YYYY-MM-DD"),
          news: [
            {
              id: 21,
              title: "Abgleichlisten zum Herunterladen verfügbar",
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Wenn für Sie zutreffend, finden Sie ab sofort Ihre Abgleichlisten unter "Mein Menü (oben rechts) > Auswertungen > Abrechnungen" zum Herunterladen. Sie werden wie gewohnt monatlich per Mail über neue Listen informiert.'
            }
          ]
        },
        {
          date: moment("2022-02-28", "YYYY-MM-DD"),
          news: [
            {
              id: 20,
              title: 'Neue Felder im Dialog Sammlung anlegen',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Sie können ab sofort weitere Felder, wie einen Link zu einer Sammlung hinzufügen. Auch weitere Felder zur Abrechnung von Weiterleitungskollekten können jetzt direkt hier angelegt werden.'
            }
          ]
        },
        {
          date: moment("2022-02-23", "YYYY-MM-DD"),
          news: [
            {
              id: 19,
              title: 'Neue Auswertung verfügbar',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Wenn Sie unter Einnahmen auf einen Sammlungszweck klicken, bekommen Sie nun den Tag mit den meisten Spendeneinnahmen dieses Zwecks angezeigt. Dies ist insbesondere nützlich um zu erkennen, wann Ihre Spender am aktivsten sind und darauf verstärkend zu antworten.'
            }
          ]
        },
        {
          date: moment("2022-02-18", "YYYY-MM-DD"),
          news: [
            {
              id: 18,
              title: 'Anzeige LTE - Status unter Geräte',
              targets: ['SP', 'SD'],
              message: 'Wenn Ihr Spendengerät mit eingebautem LTE funktioniert, können Sie jetzt den Status der Verbindung unter Geräte einsehen. Achten Sie bei der Standortwahl darauf, dass die Verbindung immer grün / ausreichend gut ist, damit es nicht zu abgebrochenen Zahlungen kommt.'
            }
          ]
        },
        {
          date: moment("2022-02-11", "YYYY-MM-DD"),
          news: [
            {
              id: 17,
              title: 'Mehr Informationen in Kollektenplänen',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Kollekten verbleiben häufig nicht in der Gemeinde, sondern werden weitergeleitet. Die Daten der Zielorganisation können jetzt beim Hochladen eines Kollektenplanes mit erfasst werden und werden an die Buchhaltung weiter gegeben. Unter i / Details in der Planung sehen Sie welche Daten hinterlegt sind.'
            }
          ]
        },
        {
          date: moment("2022-01-01", "YYYY-MM-DD"),
          news: [
            {
              id: 16,
              title: 'Kollekte verschieben',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Einzelne Kollekten aus Kollektenplänen, die auf übergeordneter Ebene (z. B. Landeskirche oder Bistum) veröffentlicht werden, können jetzt verschoben oder abgewählt werden. Eine Anleitung finden Sie hier: <a href="https://digitalespende.de/support">https://digitalespende.de/support</a>'
            }
          ]
        },
        {
          date: moment("2021-08-30", "YYYY-MM-DD"),
          news: [
            {
              id: 16,
              title: 'Logo-Upload',
              targets: ['WS'],
              message: 'Ab sofort können Sie Ihre Logos und Ihr Hintergrundbild nun selbst hochladen und anpassen. Wählen Sie einfach unter Geräte Ihre App aus und klicken auf das Zahnrad. Dort können Sie alle Einstellungen vornehmen.'
            }
          ]
        },
        {
          date: moment("2021-08-05", "YYYY-MM-DD"),
          news: [
            {
              id: 15,
              title: 'Tabellarische Darstellung der Einnahmen',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'In der Einnahmenübersicht finden Sie nun die Einzelspenden auch tabellarisch dargestellt.'
            }
          ]
        },
        {
          date: moment("2021-07-19", "YYYY-MM-DD"),
          news: [
            {
              id: 14,
              title: 'Spendeneinnahmen nach Geräten',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Unter "Auswertungen" finden Sie jetzt eine jährliche Auswertung der Einnahmen, gruppiert nach Geräten.'
            }
          ]
        },
        {
          date: moment("2021-07-08", "YYYY-MM-DD"),
          news: [
            {
              id: 11,
              title: 'Spendenzwecke wiederverwenden',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'In der Planungsansicht können Sie Sammlungen per Klick auf den "Duplizieren"-Button kopieren. Alle Einstellungen der alten Sammlung werden als Voreinstellung übernommen und Sie können bei Bedarf Änderungen vornehmen.'
            },
            {
              id: 12,
              title: 'Verbesserung der Einnahmenübersicht',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Beim Klick auf den Sammlungszweck in der Detailansicht der Einnahmen haben wir die Darstellung verbessert und Diagramme zu den Zahlarten und Geräten hinzugefügt.'
            },
            {
              id: 13,
              title: 'Beleuchtung des Drehknopfes ändern',
              targets: ['SP'],
              message: 'Ändern Sie die Leucht-Farbe des Drehknopfes der Spendensäule! In der Geräteübersicht hat Ihre Spendensäule ein kleines Zahnrad zum Öffnen der Einstellungen.'
            }
          ]
        },
        {
          date: moment("2021-07-01", "YYYY-MM-DD"),
          news: [
            {
              id: 10,
              title: 'Mehrere Spendenzwecke je Spendensäule',
              targets: ['SP'],
              message: 'Lange angekündigt, endlich Realität: Die Spendensäulen können ab sofort bis zu drei Spendenzwecke parallel anzeigen. Die anzuzeigende Anzahl der Spendenzwecke wird unter Geräte eingestellt. Die Spendenzwecke werden unter Planung angelegt und wenn sie aktiv sind, nach Spendensäule gefiltert. Dort kann dann die anzuzeigende Reihenfolge eingestellt werden. Eine ausführliche bebilderte Anleitung finden Sie demnächst unter https://digitalespende.de'
            },
            {
              id: 9,
              title: 'Verbesserte Einnahmenübersicht',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Unter Einnahmen können Sie nun zwischen einer gruppierten Ansicht und einer Detailansicht wechseln. In der gruppierten Ansicht erhalten Sie einen Überblick über die Einnahmen je Sammlungszweck. In der Detailansicht sehen Sie jede einzelne Zahlung mit Angabe zum Gerät und zur Zahlungsmethode. In dieser Ansicht können Sie außerdem auf den Sammlungszweck klicken und erhalten eine Übersicht der Einnahmen des Zwecks über Zeit.'
            }
          ]
        },
        {
          date: moment("2021-06-24", "YYYY-MM-DD"),
          news: [
            {
              id: 8,
              title: 'Beschreibungstexte beim Anlegen von Sammlung',
              targets: ['WA', 'WS'],
              message: 'In kollekte.digital können Sie beim Anlegen einer neuen Sammlung den Beschreibungstext nun direkt mit angeben. Außerdem haben wir das Aussehen des Sammlung-Anlegen-Dialoges überarbeitet.'
            }
          ]
        },
        {
          date: moment("2021-06-18", "YYYY-MM-DD"),
          news: [
            {
              id: 7,
              title: 'Spendenbescheinigungen',
              targets: ['WA', 'WS'],
              message: 'Wenn für Sie zutreffend, können Sie ab sofort im Hauptmenü unter Auswertungen / Einzelspenden Zuwendungsbestätigungen für bestätigte Zahlungseingänge herunterladen.'
            },
            {
              id: 6,
              title: 'Farbänderung in kollekte.app',
              targets: ['WA'],
              message: 'In kollekte.app können Sie endlich die Beutelfarbe ändern! So passt sich das Spendentool noch besser den unterschiedlichen evangelischen und katholischen Gegebenheiten an. Probieren Sie es unter Geräte / kollekte.app / Einstellungen aus und posten Sie gerne ein Foto Ihrer Konfiguration auf SocialMedia mit dem Hashtag #kollekteapp'
            }
          ]
        },
        {
          date: moment("2021-06-10", "YYYY-MM-DD"),
          news: [
            {
              id: 5,
              title: 'Beschreibungstexte per Excel-Upload hinzufügen',
              targets: ['WA', 'WS'],
              message: 'Die Beschreibungstexte, die Sie seit kurzem Sammlungszwecken hinzufügen können, lassen sich nun auch per Exceltabelle hinzufügen. So können Sie das gesamte kommende Jahr planen.'
            }
          ]
        },
        {
          date: moment("2021-06-03", "YYYY-MM-DD"),
          news: [
            {
              id: 3,
              title: 'Anzeige Tag im Kirchenjahr',
              targets: ['WA'],
              message: 'Seit einiger Zeit wird in spende.app der Tag des Kirchenjahres, zu dem eine Kollekte gehört, angezeigt. Dieses Feature ist nun auch in kollekte.app verfügbar!<br><br>In der Liste der Sammlungszwecke wird jetzt deutlich zwischen Kollekten- und Spendenzweck unterschieden.'
            },
            {
              id: 4,
              title: 'Vereinheitlichung von Kollektendauer',
              targets: ['WA', 'WS', 'SP', 'KB'],
              message: 'Bisher hat der liturgische Kalender zwischen Sonntagskollekten und sonstigen Feiertagskollekten unterschieden. Diese Unterscheidung ist nun aufgehoben und alle zukünftigen Einträge im Kirchenjahr sind nun 7 Tage gültig: vom Vortag 18h bis sechs Tage später 17:59h. In 2022 wird dieser Zeitraum dann anpassbar sein.'
            }
          ]
        },
        {
          date: moment("2021-05-27", "YYYY-MM-DD"),
          news: [
            {
              id: 1,
              title: 'Sammlungsdetails',
              targets: ['WA', 'WS'],
              message: 'Unter dem Reiter Planung können Sie jetzt zukünftigen Sammlungszwecken kurze (bis zu 500 Zeichen) Beschreibungstexte hinzufügen (z. B. Abkündigungstexte), die dann in kollekte.app / spende.app vom Spender angezeigt werden können. Die Bearbeitung von bereits aktiven Zwecken wird demnächst möglich sein.'
            }
          ]
        },
        {
          date: moment("2021-05-13", "YYYY-MM-DD"),
          news: [
            {
              id: 0,
              title: 'Reihenfolge von Sammlungen frei sortieren',
              targets: ['WA', 'WS', 'SP'],
              message: 'Ab sofort können Sie für Ihre Sammlungen in kollekte.app und spende.app (aktive und zukünftige) eine Reihenfolge festlegen. Sie können neun Prioritätsstufen festlegen, innerhalb einer Stufe wird nach Sammlungstyp (Hauptkollekte, Nebenkollekte, Freie Kollekte, Spendenzweck) sortiert. Änderungen werden binnen Sekunden in der entsprechenden App angezeigt. Auf diese Weise können Sie nach eigenem Ermessen thematische Schwerpunkte setzen und Spendenströme lenken.<br><br>Um die Anzeige-Reihenfolge von Sammlungen zu ändern, gehen Sie bitte im Reiter PLANUNG wie folgt vor:<ol><li>Sammlungen nach Gerät, z. B. kollekte.app filtern. Bei jeder Sammlung, die sortiert werden soll:</li><li>eine Zahl von 1 – 9 auswählen</li><li>auf „speichern“ klicken Innerhalb weniger Augenblicke ist die neue Sortierung auf kollekte.app / spende.app sichtbar. Unter Umständen müssen Sie aber mehrfach Neuladen um die Änderungen sichtbar zu machen.'
            }
          ]
        }
      ]
    }
  },
  getters: {
    latestNews: (state) => {
      return state.newsData
    }
  }
}
