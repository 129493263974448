import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import de from 'vuetify/lib/locale/de'
import config from '@/config'
import CashCollectionIcon from '@/components/icons/CashCollectionIcon.vue'

Vue.use(Vuetify)

export const colorPalette = [
  '#554193',
  '#D7C649',
  '#9DC3B7',
  '#078F6F',
  '#BBB1DD'
]

export default new Vuetify({
  lang: {
    locales: { de },
    current: 'de',
  },
  icons: {
    iconfont: 'mdiSvg', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
    values: {
      cashCollection: {
        component: CashCollectionIcon,
      },
    },
  },
  theme: {
    themes: {
      light: {
        primary: (config.deployment === 'development')
          ? '#ae364b'
          : (
              (config.deployment === 'demo')
                ? '#008566'
                : (
                    (config.variant === 'geben.digital') ? '#03867b' : colorPalette[0]
                  )
            ),
        secondary: '#37AAE6',
        accent: colorPalette[0],
        error: (config.deployment === 'development') ? '#3281a1' : '#ae364b',
      },
    },
  },
})
