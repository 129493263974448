<template>
  <v-app class="overflow-hidden" id="app-container">
    <router-view />
    <v-snackbar :value="isErrorSnackbarVisible" :timeout="-1" color="primary" elevation="4">
      {{ $store.state.error }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="dismissError"
        >
          Meldung schliessen
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      :value="isInfoSnackbarVisible"
      :timeout="-1"
      color="success"
      elevation="4"
    >
      {{ $store.state.snackbarMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="dismissSnackbar"
        >
          Meldung schliessen
        </v-btn>
      </template>
    </v-snackbar>
    <v-footer class="d-print-none" id="footer-body" fixed app>
      <v-row no-gutter>
        <v-col cols="1" class="shrink" v-if="Wormhole.hasContentFor('app-footer-bottom-left')">
          <portal-target name="app-footer-bottom-left"/>
        </v-col>
        <v-spacer/>
        <v-col align="center" cols="10">
          <span class="mr-3">2024 &copy; Digital.Wolff, Plötz &amp; Co GmbH</span><a href="#" @click.stop="imprintDialog = true">Impressum</a>
          <v-dialog
            v-model="imprintDialog"
            scrollable
            max-width="550px"
          >
            <v-card>
              <v-card-title>Impressum</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p v-if="config.variant === 'kirchgeld.digital'">
                  kirchgeld.digital ist eine Marke der <a href="https://digitalwolff.de" target="_blanc">Digital.Wolff, Plötz &amp; Co GmbH</a>.
                </p>
                <p v-else>geben.digital und kollekte.digital sind Marken der <a href="https://digitalwolff.de" target="_blanc">Digital.Wolff, Plötz &amp; Co GmbH</a>.</p>
                <p>Angaben gemäß § 5 TMG:</p>
                <p>Digital.Wolff, Plötz &amp; Co GmbH<br>Luckenwalder Straße 4-6<br>10963 Berlin</p>
                <p>Geschäftsführer: Dr. Martin C. Wolff, Henryk Plötz, Hannah Jo Wolff</p>
                <p>Amtsgericht Charlottenburg: HRB 192569 B<br>
                USt-ID: DE315450913</p>
                <p>Kontakt: <a href="mailto:info@digitalwolff.de">info@digitalwolff.de</a></p>

              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="imprintDialog = false"
                >
                  Ok
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
        <v-spacer/>
        <v-col cols="1" class="shrink text-right grey--text">
          <version-info/>
        </v-col>
      </v-row>
    </v-footer>
  </v-app>
</template>
<script>
import { FETCH_STATIC_CONFIGURATION, FETCH_USER_DATA } from './store/action-types'
import { START_TIMER } from '@/modules/common/store/action-types'
import { SET_ERROR_MESSAGE, SET_SNACKBAR_MESSAGE } from '@/modules/common/store/mutation-types'
import { mdiHome } from '@mdi/js'
import config from '@/config'
import { Wormhole } from 'portal-vue'
import VersionInfo from '@/modules/common/VersionInfo.vue'

export default {
  components: { VersionInfo },
  data () {
    return {
      imprintDialog: false,
      Wormhole,
      mdiHome,
      config,
    }
  },
  async created () {
    if (["geben.digital", "kollekte.digital"].includes(config.variant)) {
      await this.$store.dispatch('staticConfiguration/' + FETCH_STATIC_CONFIGURATION)
      if (this.$store.getters['user/isLoggedIn']) {
        // Re-validate login token
        await this.$store.dispatch('user/' + FETCH_USER_DATA)
      }
    }
    await this.$store.dispatch(START_TIMER)
    await this.$nextTick()
    const scope = document.documentElement.style
    if (config.deployment === 'development') {
      scope.setProperty('--clr-environment', '#ae364b')
    } else if (config.deployment === 'demo') {
      scope.setProperty('--clr-environment', '#008566')
    } else if (config.variant === 'geben.digital') {
      scope.setProperty('--clr-environment', '#03867b')
    } else if (config.variant === 'spendenbescheinigung.digital') {
      scope.setProperty('--clr-environment', '#03867b')
    } else if (config.variant === 'kirchgeld.digital') {
      scope.setProperty('--clr-environment', '#554193')
    } else {
      scope.setProperty('--clr-environment', '#554193')
    }
  },
  methods: {
    dismissError: function () {
      this.$store.commit(SET_ERROR_MESSAGE, { message: null })
    },
    dismissSnackbar () {
      this.$store.commit(SET_SNACKBAR_MESSAGE, { message: null })
    }
  },
  computed: {
    isErrorSnackbarVisible () {
      return !!this.$store.state.error
    },
    isInfoSnackbarVisible () {
      return !!this.$store.state.snackbarMessage
    },
  }
}
</script>
<style lang="stylus">
#footer-body
  font-size: 0.8em

::selection
  color: white !important
  background-color: var(--clr-environment) !important

#app-container
  --link-color: $clr-primary

  a
    color: var(--clr-environment)
    text-decoration: none

#crumbs
  margin-bottom: auto
  margin-top: 0.2em
  height: 2.2em
  padding-left: 0em
  font-size: 87.5%;

  h1
    font-size: 28px;
    margin: 0
    line-height: 1
  h3
    padding-top: 7px;
    font-size: 18px;
    margin: 0
    line-height: 1
</style>
