<template>
  <v-card class="my-1">
    <v-toolbar flat dense>
      <v-toolbar-title>{{ settingsInformation.title }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <div style="position: absolute; right: 0">
        <template v-if="setting.nextFrame.source !== undefined">
          <div v-show="selectedTab === 1" class="text-body-2 pr-2 text-center">Einstellung übernommen von <br><span class="text-body-1">{{inheritedFromOrgName}}</span></div>
        </template>
        <v-slide-x-reverse-transition>
          <v-btn v-show="selectedTab === 0" text @click="$emit('clear')"><v-icon>{{ mdiUndo }}</v-icon>Zurücksetzen</v-btn>
        </v-slide-x-reverse-transition>
      </div>
    </v-toolbar>
    <v-card-text>
      <v-container>
        <v-row v-if="setting.type === 'initialInputValue'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Sie können den Betrag einstellen, der auf dem Spendengerät im Ausgangszustand voreingestellt ist:</label>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :id="'id-'+setting.key"
              type="number"
              :value="setting.currentValue"
              @input="emitInput"
              :min="setting.minValue"
              :max="(allSettings && allSettings[setting.key.replace('input/initial', 'input/max')]) ? allSettings[setting.key.replace('input/initial', 'input/max')].currentValue : setting.maxValue"
              :suffix="currencySymbol"
              class="short-input"
              dense
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row v-if="setting.type === 'maxInputValue'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Zur Information: Der maximale Betrag der auf diesem Spendengerät eingestellt werden kann ist:</label>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :id="'id-'+setting.key"
              type="number"
              :value="setting.currentValue"
              @input="emitInput"
              :min="setting.minValue"
              :max="setting.maxValue"
              :suffix="currencySymbol"
              class="short-input"
              dense
              disabled
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" v-if="setting.currentValue < setting.maxValue">Wenn Sie diesen Betrag erhöhen möchten, setzen Sie sich bitte mit uns in Verbindung.</v-col>
        </v-row>
        <v-row v-else-if="setting.type === 'collectionCount'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Sie können festlegen, ob und wieviele Spendenzwecke auf dem Display des Spendengeräts im Ausgangszustand angeboten werden:</label>
          </v-col>
          <v-col cols="4">
            <v-slider
              :id="'id-'+setting.key"
              :value="setting.currentValue"
              @input="emitInput"
              :min="setting.minValue"
              :max="setting.maxValue"
              dense
              thumb-label="always"
              class="mt-8 collection-count-slider"
            ></v-slider>
          </v-col>
        </v-row>
        <template v-else-if="setting.type === 'ledringColor'">
          <v-row>
            <v-col cols="8">
              <label
                v-if="allSettings[deviceClass + '/ledring/mode'] && allSettings[deviceClass + '/ledring/mode'].currentValue === 'off'"
                class="text-body-1"
              >
                 Der Eco-Modus ist aktiviert. Der Leuchtring ist ausgeschaltet. Deaktivieren Sie den Eco-Modus um die Farbe des Leuchtrings zu ändern.
              </label>
              <label
                v-else
                :for="'id-'+setting.key"
                class="text-body-1"
              >
                Sie können die Grundfarbe der Beleuchtung des Drehschalters ändern:
               </label>

            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-slider
                v-if="allSettings[deviceClass + '/ledring/mode'] && allSettings[deviceClass + '/ledring/mode'].currentValue !== 'off'"
                :id="'id-'+setting.key"
                :value="convertedValue"
                @input="emitInput"
                :min="0"
                :max="360"
                dense
                thumb-label="always"
                class="mt-2 color-gradient-slider"
                :thumb-color="currentValue"
              >
                <template v-slot:thumb-label></template>
              </v-slider>
            </v-col>
          </v-row>
        </template>
        <v-row v-else-if="setting.type === 'ledringMode'">
          <v-col cols="12">
            <v-checkbox
              label="Eco-Mode aktivieren und LED Ring ausschalten. Damit kann insbesondere bei Akku-betriebenen Geräten die Laufzeit verlängert werden."
              :value="setting.currentValue"
              :true-value="'off'"
              :false-value="'static'"
              @change="emitInput"
            ></v-checkbox>
          </v-col>
        </v-row>
        <v-row v-if="setting.type === 'confirmationMinAmount'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Sie können den Betrag einstellen, ab dem Bestätigungscodes für die Anfrage nach einer Zuwendungsbestätigung angezeigt werden, oder ob diese Codes gar nicht angezeigt werden sollen:</label>
          </v-col>
          <v-col cols="4">
            <v-radio-group v-model="valueSet" class="mt-0">
              <v-radio value="set">
                <template v-slot:label>
                  <span class="pr-2">Anzeigen ab</span>
                  <v-text-field
                    :id="'id-'+setting.key"
                    type="number"
                    v-model="convertedValue"
                    :suffix="currencySymbol"
                    class = "short-input"
                    dense
                  >
                  </v-text-field>
                </template>
              </v-radio>
              <v-radio value="unset">
                <template v-slot:label>
                  Niemals einen Bestätigungscode anzeigen
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-if="setting.type === 'confirmationContactEmail'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Sie können die Kontakt-E-Mailadresse einstellen die auf dem Bildschirm zusammen mit dem Bestätigungscode angezeigt werden soll:</label>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :id="'id-'+setting.key"
              type="email"
              :value="setting.currentValue"
              @input="emitInput"
              dense
              :rules="eMailRules"
            >
              <template v-slot:label>E-Mail-Adresse</template>
            </v-text-field> <!-- FIXME Disable save if e-mail invalid -->
          </v-col>
        </v-row>
        <template v-else-if="setting.type === 'freeColor'">
          <v-row>
            <v-col cols="8">
              <label :for="'id-'+setting.key" class="text-body-1">Sie können die Grundfarbe der App ändern:</label>
            </v-col>
            <v-col cols="4">
              <v-color-picker
                :id="'id-'+setting.key"
                :value="convertedValue"
                @input="emitInput"
                dot-size="15"
                mode="hexa"
                show-swatches
                :swatches="freeColorSwatches"
                swatches-max-height="113"
              ></v-color-picker>
            </v-col>
          </v-row>
        </template>
        <template v-else-if="setting.type === 'liturgicalColor'">
          <v-row>
            <v-col cols="8">
              <label :for="'id-'+setting.key" class="text-body-1">Sie können zwischen vier liturgischen Farben für die Grundfarbe der App wählen:</label>
            </v-col>
            <v-col cols="4">
              <v-color-picker
                :id="'id-'+setting.key"
                :value="convertedValue"
                @input="emitInput"
                dot-size="15"
                mode="hexa"
                hide-canvas
                hide-sliders
                hide-inputs
                show-swatches
                :swatches="liturgicalColorSwatches"
                swatches-max-height="200"
                elevation="0"
              ></v-color-picker>
            </v-col>
          </v-row>
        </template>
        <v-row v-if="setting.type === 'privacyUrl'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Sie können die Webadresse einstellen auf die Besucher ihrer App gelangen sollen, wenn diese auf "Datenschutz" klicken:</label>
          </v-col>
          <v-col cols="4">
            <v-text-field
              :id="'id-'+setting.key"
              type="url"
              :value="setting.currentValue"
              @input="emitInput"
              dense
              :rules="webUrlRules"
              hint="URL zur Datenschutzerklärung Ihrer Organisation"
            >
            </v-text-field> <!-- FIXME Disable save if url invalid -->
          </v-col>
        </v-row>
        <v-row v-else-if="setting.type === 'logoIsText'">
          <v-col cols="8">
            <label :for="'id-'+setting.key" class="text-body-1">Wenn Ihr Logo bereits Ihren Organisationsnamen enthält (kombinierte Wort/Bildmarke) können Sie hier einstellen, dass Ihr Organisationsname nicht mehr neben dem Logo angezeigt werden soll:</label>
          </v-col>
          <v-col cols="4">
            <v-radio-group v-model="convertedValue" class="mt-0">
              <v-radio value="n">
                <template v-slot:label>
                  <span>Organisationsnamen neben dem Logo anzeigen</span>
                </template>
              </v-radio>
              <v-radio value="y">
                <template v-slot:label>
                  <span>Organisationsnamen <i>nicht</i> neben dem Logo anzeigen</span>
                </template>
              </v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row v-else-if="setting.type === 'backgroundImage'">
          <v-col cols="6"><span class="text-body-1">Sie können das Hintergrundbild Ihrer spende.app festlegen, oder ein eigenes Bild hochladen:</span></v-col>
          <v-col cols="6">
            <image-chooser
              :value="setting.currentValue"
              subject="Hintergrundbild"
              @input="emitInput"
              image-type="bg_spendeapp"
              :upload-field-rules="backgroundUploadRules"
              :initial-value="setting.currentFrame.value"
              :featured-values="['0wg85erb0lS,Eqr+6+2Wuqub88QrZ9Ppy;DTYuDkh8w']"
              @upload-file="emitUpload(setting, $event, ['bg_spendeapp'])"
            >
              <template v-slot:uploadText>
                <v-col cols="5">Sie können auch Ihr eigenes Bild in hoher Auflösung (min. 1600x1200 Pixel, max. 10MB) hochladen. Bitte verletzen Sie dabei keine Urheberrechte.</v-col>
              </template>
            </image-chooser>
          </v-col>
        </v-row>
        <v-row v-else-if="['webappLogo', 'mainLogo', 'cornerLogo'].includes(setting.type)">
          <v-col cols="6" class="pt-0">
            <span class="text-body-1" v-if="setting.type === 'webappLogo'">Ihre spende.app kann an zwei Stellen ein eigenes Logo anzeigen (Ecklogo oben rechts, Hauptlogo mitte links). Hier können Sie ihr generelles Organisationslogo einstellen bzw. hochladen, welches für <b>beide</b> Stellen verwendet wird:</span>
            <span class="text-body-1" v-if="setting.type === 'mainLogo'">Hier können Sie das Hauptlogo einstellen bzw. hochladen welches für den mittleren Bereich verwendet wird:</span>
            <span class="text-body-1" v-if="setting.type === 'cornerLogo'">Hier können Sie das Ecklogo einstellen bzw. hochladen welches für obere rechte Ecke verwendet wird:</span>
          </v-col>
          <v-col cols="6">
            <image-chooser
              :value="setting.currentValue"
              subject="Logo"
              @input="emitInput"
              :image-type="['logo_square', 'logo_wide']"
              :upload-field-rules="logoUploadRules"
              :initial-value="setting.currentFrame.value"
              :null-component="HandSquare"
              null-text="An dieser Position kein Logo eingestellt"
              @upload-file="emitUpload(setting, $event, ['logo_square', 'logo_wide'])"
            >
              <template v-slot:uploadText>
                <v-col cols="5">Sie können hier selbst Ihr Logo hochladen. Ihre Datei darf nicht größer als 2MB sein und muss einen transparenten Hintergrund haben. Das ideale Upload-Format ist .png, auch .svg ist möglich. Das Seitenverhältnis ist maximal 4,5:12; auch 1:1 ist möglich.</v-col>
              </template>
            </image-chooser>
          </v-col>
        </v-row>
        <v-row v-else-if="setting.type.startsWith('templateText')">
          <v-col cols="6">
            <div class="text-body-1">
              <template v-if="setting.type === 'templateTextThankYou'">
                Sie können den Text einstellen, der spende.app nach einer Spende angezeigt wird. Folgende Platzhalter können verwendet werden:
              </template>
              <template v-else-if="setting.type === 'templateTextThankYouShare'">
                Sie können den Text einstellen, der automatisch in die SocialMedia-Posts eingefügt wird, die spende.app nach einer Spende anbietet. Folgende Platzhalter können verwendet werden:
              </template>
            </div>
            <div class="text-body-2">
              <ul>
                <li
                  v-for="templateVariable of availableTemplateVariables"
                  :key="templateVariable.key"
                >
                  <pre class="placeholder-key">%{{ templateVariable.key }}%</pre>: {{ templateVariable.description }}
                </li>
              </ul>
            </div>
            <div
              v-if="setting.type === 'templateTextThankYou'"
              class="text-body-1 mt-2"
            >
              Um den Text der versandten Dankesmail anzupassen, schicken Sie bitte eine Mail an <a href="mailto:support@digitalwolff.de">support@digitalwolff.de
            </a>.
            </div>

          </v-col>
          <v-col cols="6">
            <v-textarea
              filled
              :value="setting.currentValue"
              @input="emitInput"
              dense
              rows="6"
              hint="Text der nach einer Spende angezeigt wird"
            >
            </v-textarea>
          </v-col>
        </v-row>
        <v-row v-else-if="setting.type === 'socialMedia'">
          <v-col cols="6">
            <div class="text-body-1">
              Nach erfolgter Spende kann die Erfahrung auf SocialMedia geteilt werden.<br/> Welche SocialMedia-Dienste sollen in den Dankestext, den spende.app nach erfolgreicher Spende anzeigt, eingefügt werden?
            </div>
          </v-col>
          <v-col cols="6">
            <v-checkbox
              v-for="socialMediaService in settingsInformation.services"
              :key="socialMediaService.key"
              :value="setting.currentValue[socialMediaService.key]"
              :input-value="setting.currentValue[socialMediaService.key]"
              @change="emitSocialMediaChange($event, socialMediaService.key)"
              dense
            >
              <template #label>
                <v-icon class="mr-2">
                  {{ socialMediaService.icon }}
                </v-icon>
                {{ socialMediaService.title }}
              </template>
            </v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <!-- <v-card-actions>{{ setting }}</v-card-actions> -->
  </v-card>
</template>

<script>
import {
  mdiUndo,
  mdiCurrencyEur,
  mdiFacebook,
  mdiInstagram,
  mdiTwitter,
  mdiEmail,
  mdiWhatsapp,
  mdiPinterest,
  mdiSnapchat,
  mdiLinkedin
} from '@mdi/js'
import { hexToHue, hsl2Hex } from '@/lib/color-tools'
import ImageChooser from '@/components/device/ImageChooser'
import { mapGetters } from 'vuex'
import { FETCH_ORGANIZATIONS } from '@/store/action-types'
import HandSquare from '@/components/icons/HandSquare'
import { templateVariables } from "@/lib/template-db"

const SETTINGS_INFORMATION = {
  initialInputValue: {
    title: "Voreingestellte Spendenhöhe",
  },
  maxInputValue: {
    title: "Maximale Spendenhöhe",
  },
  collectionCount: {
    title: "Anzahl der Spendenzwecke",
  },
  ledringColor: {
    title: "Farbe des Leuchtrings",
  },
  ledringMode: {
    title: "Eco-Modus"
  },
  confirmationMinAmount: {
    title: "Mindestwert für Zuwendungsbestätigungen",
  },
  confirmationContactEmail: {
    title: "Kontaktadresse für Zuwendungsbestätigungen",
  },
  freeColor: {
    title: "Farbeinstellung",
  },
  liturgicalColor: {
    title: "Farbeinstellung",
  },
  privacyUrl: {
    title: "Datenschutz-URL",
  },
  logoIsText: {
    title: "Anzeige des Organisationsnamens",
  },
  backgroundImage: {
    title: "Hintergrundbild",
  },
  webappLogo: {
    title: "Generelles Logo",
  },
  mainLogo: {
    title: "Hauptlogo",
  },
  cornerLogo: {
    title: "Ecklogo",
  },
  templateTextThankYou: {
    title: "Dankestext nach einer Spende",
  },
  templateTextThankYouShare: {
    title: "Vorausgefüllter Text für SocialMedia-Posts",
  },
  socialMedia: {
    title: 'Links zu SocialMedia',
    services: [
      {
        key: 'email',
        title: 'E-Mail',
        icon: mdiEmail
      },
      {
        key: 'whatsapp',
        title: 'WhatsApp',
        icon: mdiWhatsapp
      },
      {
        key: 'facebook',
        title: 'Facebook',
        icon: mdiFacebook
      },
      {
        key: 'instagram',
        title: 'Instagram',
        icon: mdiInstagram
      },
      {
        key: 'twitterx',
        title: 'X (Twitter)',
        icon: mdiTwitter
      },
      {
        key: 'pinterest',
        title: 'Pinterest',
        icon: mdiPinterest
      },
      {
        key: 'snapchat',
        title: 'Snapchat',
        icon: mdiSnapchat
      },
      {
        key: 'linkedin',
        title: 'LinkedIn',
        icon: mdiLinkedin
      }
    ]
  },
}

export default {
  name: 'SingleSetting',
  components: { ImageChooser },
  props: {
    value: {},
    setting: Object,
    allSettings: {
      type: Object,
      default: null,
      required: false,
    },
    deviceClass: {
      type: String,
      default: null,
      required: false,
    },
  },
  data () {
    return {
      mdiUndo,
      mdiCurrencyEur,
      convertedValue: 0,
      valueSet: "set",
      HandSquare,
      templateVariables,
      eMailRules: [
        (v) => (!v || (/^[^@]+@[^@]{2,}$/.test(v) || 'Gültige E-Mail-Adresse eingeben'))
      ],
      webUrlRules: [
        (v) => (!v || (/^https?:\/\/[^/]{3,}/.test(v) || 'Gültige URL eingeben (https://...)'))
      ],
      freeColorSwatches: [
        ['#554193'], ['#ae364b'], ['#d7c548'], ['#03867b'], ['#005F8C']
      ],
      liturgicalColorSwatches: [
        ['#723288'], ['#327359'], ['#89313D'], ['#3E3E3E']
      ],
      backgroundUploadRules: [
        file => !file || !(file.size > 10485760) || 'Maximal 10 MB!'
      ],
      logoUploadRules: [
        file => !file || !(file.size > 2097152) || 'Maximal 2 MB!'
      ]
    }
  },
  computed: {
    ...mapGetters('organization', ['allOrganizations', 'currentOrganization']),
    currencySymbol () {
      return this.$options.filters.currencySymbol(this.currentOrganization.currency)
    },
    selectedTab () {
      return this.setting?.locallySet ? 0 : 1
    },
    settingsInformation () {
      return SETTINGS_INFORMATION[this.setting?.type] ?? {}
    },
    currentValue () {
      return this.setting?.currentValue
    },
    inheritedFromOrgName () {
      let name = null
      const url = this.setting.nextFrame.source
      if (url && this.allOrganizations[url]) {
        name = this.allOrganizations[url]?.name
      }
      return name || "übergeordneter Organisation"
    },
    availableTemplateVariables () {
      return Object.entries(this.templateVariables).filter(([key, value]) => value.isActive).map(([_key, value]) => value)
    },
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
    ])
  },
  watch: {
    currentValue: {
      immediate: true,
      handler (newVal) {
        if (this?.setting?.type === 'ledringColor') {
          this.convertedValue = this.hexToHue(newVal)
        } else if (['liturgicalColor', 'freeColor'].includes(this?.setting?.type)) {
          this.convertedValue = newVal.toUpperCase()
        } else if (this?.setting?.type === 'logoIsText') {
          // FIXME Same problem as below
          this.convertedValue = newVal ? 'y' : 'n'
        } else if (this?.setting?.type === 'confirmationMinAmount') {
          // FIXME This emits input events via watchers on convertedValue and valueSet, which makes this setting locallySet again
          if (newVal >= 0) {
            this.valueSet = "set"
            this.convertedValue = newVal
          } else {
            this.valueSet = "unset"
          }
        }
      }
    },
    async convertedValue () {
      if (['logoIsText', 'confirmationMinAmount'].includes(this?.setting?.type)) {
        await this.$nextTick()
        this.emitInput()
      }
    },
    async valueSet () {
      if (this?.setting?.type === 'confirmationMinAmount') {
        await this.$nextTick()
        this.emitInput()
      }
    },
  },
  methods: {
    emitInput (val) {
      if (['collectionCount', 'initialInputValue', 'maxInputValue'].includes(this.setting.type)) {
        val = parseInt(val)
      } else if (this.setting.type === 'confirmationMinAmount') {
        if (this.valueSet === 'set') {
          val = parseInt(this.convertedValue)
        } else {
          val = -1
        }
      } else if (this.setting.type === 'logoIsText') {
        val = this.convertedValue === 'y'
      } else if (this.setting.type === 'ledringColor') {
        val = this.hueToHex(val)
      } else if (['privacyUrl', 'confirmationContactEmail'].includes(this.setting.type)) {
        // FIXME A lower layer in the inheritance tree may effectively be unable to delete these
        if (!val || val.trim() === "") {
          val = null
        }
      }
      this.$emit("input", val)
    },
    emitSocialMediaChange (val, service) {
      this.$emit("input", {
        ...this.setting.currentValue,
        [service]: val
      })
    },
    emitUpload (setting, fileElement, types = []) {
      this.$emit("upload", { setting, fileElement, types })
    },
    hueToHex (val) {
      if (val !== undefined && val !== null) {
        return hsl2Hex(val, 100, 50)
      }
      return null
    },
    hexToHue (val) {
      if (val !== undefined && val !== null) {
        return hexToHue(val)
      }
      return null
    }
  }
}
</script>

<style scoped lang="stylus">
.placeholder-key
  display: inline
  margin: 0
  font-family: monospace
  background-color: #eee
  color: #666

/deep/ .v-text-field.short-input .v-input__control {
  max-width: 5em;
}
/deep/ .v-text-field.short-input .v-input__control input {
  text-align: right;
}
/deep/ .collection-count-slider .v-input__control {
  max-width: 10em;
}
/deep/ .color-gradient-slider .v-slider__track-container {
  background: linear-gradient(
    to right,
    #ff0000 0%,
    #ffff00 17%,
    #00ff00 33%,
    #00ffff 50%,
    #0000ff 67%,
    #ff00ff 83%,
    #ff0000 100%
  );
  height: 5px;
}
/deep/ .color-gradient-slider .v-slider__track-container > div {
  background-color: transparent !important;
}
</style>
