import { colorPalette } from "@/plugins/vuetify.js"

export const paymentTypes = {
  creditcard: {
    slug: 'creditcard',
    label: 'Kreditkarte',
    color: colorPalette[0], // lila
  },
  giropay: {
    slug: 'giropay',
    label: 'Giropay',
    color: colorPalette[2], // light green
  },
  sepadd: {
    slug: 'sepadd',
    label: 'SEPA Lastschrift',
    color: colorPalette[3], // green
  },
  googlepay: {
    slug: 'googlepay',
    label: 'Google Pay',
    color: colorPalette[4], // light lila
  },
  applepay: {
    slug: 'applepay',
    label: 'Apple Pay',
    color: colorPalette[4], // light lila
  },
  paypal: {
    slug: 'paypal',
    label: 'PayPal',
    color: colorPalette[1], // yellow
  },
  girocard: {
    slug: 'girocard',
    label: 'Girocard',
    color: colorPalette[3], // green
  },
  cash: {
    slug: 'cash',
    label: 'Bargeld',
    color: colorPalette[2], // light green
  },
}

export default {
  paymentTypes,
  methods: {
    deviceTypeToHuman (dt) {
      if (!dt) {
        return 'Unbekannt'
      }
      switch (dt) {
        case 'bart':
          return 'spende.app'
        case 'john':
          return 'kollekte.app'
        case 'peter':
          return 'Klingelbeutel'
        case 'philip':
          return 'Klingelsäule'
        case 'james':
          return 'Spendensäule'
        case 'matt':
          return 'Spendendose'
        case 'cash':
          return 'Barzahlung'
        default:
          return 'Unbekannt'
      }
    },
    paymentTypeToHuman (pt) {
      return paymentTypes[pt]?.label ?? 'unbekannter Typ'
    },
    paymentTypeToColor (pt) {
      return paymentTypes[pt]?.color ?? '#7F7F7F' // grau
    },
    collectionTypeToColor (collectionType) {
      // TODO: Differentiate between collection types. Remove comments as soon backend delivers collection types
      // { name: 'Hauptkollekte', type: 'collection', variant: 'inner', color: '' },
      // { name: 'Nebenkollekte', type: 'collection', variant: 'outer', color: 'rgba(187,177,221, 1)' },
      // { name: 'Freie Kollekte', type: 'collection', variant: 'x', color: 'rgba(215,198,73, 1)' },
      // { name: 'Spende', type: 'donation', variant: null, color:  },

      switch (collectionType) {
        case 'collection':
          return '#554193'
        case 'donation':
          return '#078f6f'
        default:
          return '#7F7F7F'
      }
    },
    getColorFromPalette (dt, usedColorsIndex) {
      if (!dt) return '#7F7F7F' // grau
      return colorPalette[usedColorsIndex % colorPalette.length]
    },
  }
}
