export const CLEAR_DATA = 'clearData'

export const SET_AUTHENTICATION_DATA = 'setAuthenticationData'
export const SET_OAUTH_STATE = 'setOauthState'
export const SET_USER_DATA = 'setUserData'
export const SET_USER_LIST = 'setUserList'
export const SET_CREDENTIALS = 'setCredentials'
export const CLEAR_CREDENTIALS = 'clearCredentials'
export const SET_ORGANIZATIONS = 'setOrganizations'
export const SET_ORGANIZATION = 'setOrganization'
export const SET_COLLECTION_PLANS = 'setCollectionPlans'
export const SET_DATES_GLOBAL = 'setGlobalDates'
export const SET_DATES_FOR_ORGANIZATION = 'setDatesForOrganization'
export const SET_ORGANIZATION_DEVICES = 'setDevices'
export const SET_GLOBAL_DEVICES = 'setGlobalDevices'
export const SET_DEVICE_PROVISIONING_REQUESTS = 'setDeviceProvisioningRequests'
export const SET_LOCATIONS = 'setLocations'
export const SET_QR_CODE = 'setQrCode'
export const SET_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION = 'setStatisticsForOrganization'
export const SET_DASHBOARD_STATISTICS_FOR_ORGANIZATION = 'setDashboardStatisticsForOrganization'
export const SET_COLLECTION_SUMS_FOR_ORGANIZATION = 'setCollectionSumsForOrganization'
export const SET_RECURSIVE = 'setRecursive'
export const SET_CONTENTIMAGE = 'setContentImage'

export const APPEND_LOCATION = 'appendLocation'
export const APPEND_ORGANIZATION = 'appendOrganization'

export const SET_ORGA_PANEL_STATE = 'setOrgaPanelState'
export const CLEAR_ORGA_PANEL_STATE = 'clearOrgaPanelState'
export const SET_ALL_ORGA_PANEL_STATE = 'setAllOrgaPanelState'

export const SET_ORGA_PANEL_FILTER = 'setOrgaPanelFilter'

export const SET_STATIC_CONFIGURATION = 'setStaticConfiguration'

export const SET_FIRMWARE_INFORMATION = 'setFirmwareInformation'

export const SET_CONTACTS = 'setContacts'
export const SET_BILLING_PLANS = 'setBillingPlans'
export const SET_CONTRACTS = 'setContracts'
export const SET_DIRECT_PAYMENT_PAIN_FILES = 'setDirectPaymentPainFiles'
