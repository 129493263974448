import { SET_COLLECTION_PLANS } from './mutation-types'
import {
  INC_LOADING,
  DEC_LOADING,
  SET_ERROR_MESSAGE,
  SET_SNACKBAR_MESSAGE
} from '@/modules/common/store/mutation-types'
import {
  FETCH_ORGANIZATION_COLLECTION_PLANS,
  ADD_COLLECTION_PLAN,
  UPDATE_COLLECTION_PLAN,
  PATCH_COLLECTION_PLAN,
  DELETE_COLLECTION_PLAN,
  FETCH_FILLABLE_FIELDS_FOR_COLLECTION,
  PATCH_FILLABLE_FIELDS_FOR_COLLECTION,
  ADD_COLLECTION_PLAN_GROUP,
  DUPLICATE_COLLECTION_PLAN_GROUP,
  DELETE_COLLECTION_PLAN_GROUP, PRE_ANNOUNCE_COLLECTIONS,
  // PRE_ANNOUNCE_COLLECTIONS
} from './action-types'

export default {
  namespaced: true,
  state: function () {
    return {
      rawCollectionPlansByOrganization: {}
    }
  },
  getters: {
    collectionPlansByOrganization: (state) => {
      /* This is currently a no-op, but could be used to filter/modify CollectionPlan objects as they come from the API */
      return Object.fromEntries(Object.entries(state.rawCollectionPlansByOrganization).map(([orgUrl, plans]) => [
        orgUrl, plans.map(plan => {
          return { ...plan }
        })
      ]))
    },
    currentCollectionPlans: (state, getters, rootState, rootGetters) => {
      const org = rootGetters['organization/currentOrganization']
      if (!org) {
        return null
      }
      return getters.collectionPlansByOrganization[org.url]
    },
    collectionPlansLookup: (state, getters, rootState, rootGetters) => {
      const org = rootGetters['organization/currentOrganization']
      if (!org || !getters.collectionPlansByOrganization[org.url]) {
        return null
      }
      const result = {}
      getters.collectionPlansByOrganization[org.url].forEach(plan => {
        result[plan.url] = plan
      })
      return result
    },
    collectionPlanById: (state, getters, rootState, rootGetters) => {
      const org = rootGetters['organization/currentOrganization']
      if (!org || !getters.collectionPlansByOrganization[org.url]) {
        return null
      }
      const result = {}
      getters.collectionPlansByOrganization[org.url].forEach(plan => {
        result[plan.id] = plan
      })
      return result
    },
    collectionPlanGroupsByUrl: (state, getters, rootState, rootGetters) => {
      const org = rootGetters['organization/currentOrganization']
      if (!org || !getters.collectionPlansByOrganization[org.url]) {
        return null
      }
      const result = {}
      getters.collectionPlansByOrganization[org.url].forEach(plan => {
        if (!plan.parent) {
          return
        }
        if (!result[plan.parent]) {
          result[plan.parent] = []
        }
        result[plan.parent].push(plan)
      })
      return result
    }
  },
  mutations: {
    [SET_COLLECTION_PLANS]: (state, {
      organizationUrl,
      data
    }) => {
      state.rawCollectionPlansByOrganization = {
        ...state.rawCollectionPlansByOrganization,
        [organizationUrl]: data
      }
    },
  },
  actions: {
    [FETCH_FILLABLE_FIELDS_FOR_COLLECTION]: async ({
      commit,
      rootGetters
    }, { token }) => {
      await commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.get('/collection_plan/fill_accounting_fields/' + token)
        return response.data
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        return null
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [PATCH_FILLABLE_FIELDS_FOR_COLLECTION]: async ({
      commit,
      rootGetters
    }, {
      token,
      data
    }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.patch('/collection_plan/fill_accounting_fields/' + token + '/', data)
        if (response.status === 200) {
          return true
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
      return false
    },
    [FETCH_ORGANIZATION_COLLECTION_PLANS]: async ({
      commit,
      rootGetters
    }, organizationUrl) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.get(organizationUrl + 'collection_plans/')
        await commit(SET_COLLECTION_PLANS, {
          organizationUrl,
          data: response.data
        })
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
        await commit(SET_COLLECTION_PLANS, {
          organizationUrl,
          data: null
        })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [ADD_COLLECTION_PLAN]: async ({
      commit,
      rootGetters
    }, collectionPlan) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.post('collection_plan/', collectionPlan)
        if (response.status === 201) {
          return true
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
      return false
    },
    [UPDATE_COLLECTION_PLAN]: async ({
      commit,
      rootGetters
    }, collectionPlan) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.put(collectionPlan.url, collectionPlan)
        if (response.status === 200) {
          return true
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
      return false
    },
    [PATCH_COLLECTION_PLAN]: async ({
      commit,
      rootGetters
    }, {
      url,
      data
    }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.patch(url, data)
        if (response.status === 200) {
          return response.data
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
      return false
    },
    [DELETE_COLLECTION_PLAN]: async ({
      commit,
      rootGetters
    }, collectionPlan) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const response = await rootGetters.restApi.delete(collectionPlan.url)
        if (response.status === 204) {
          return true
        }
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
      return false
    },
    [ADD_COLLECTION_PLAN_GROUP]: async ({
      commit,
      dispatch,
      rootGetters
    }, {
      collectionPlanGroup,
      collectionPlanMembers
    }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        collectionPlanGroup.parent = null
        const groupResponse = await rootGetters.restApi.post('collection_plan/', collectionPlanGroup)
        const groupUrl = groupResponse.data.url

        const memberUpdateCalls = collectionPlanMembers.map((collectionPlan) => {
          collectionPlan.parent = groupUrl
          return rootGetters.restApi.put(collectionPlan.url, collectionPlan)
        })

        await Promise.allSettled(memberUpdateCalls)
        return true
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }

      return false
    },
    [DUPLICATE_COLLECTION_PLAN_GROUP]: async ({
      commit,
      dispatch,
      rootGetters
    }, {
      collectionPlanGroup,
      collectionPlanMembers
    }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        const memberCreateCalls = collectionPlanMembers.map((collectionPlan) => {
          const duplicateCollectionPlan = {
            ...collectionPlan,
            parent: null,
            id: null,
            url: null
          }
          return rootGetters.restApi.post('collection_plan/', duplicateCollectionPlan)
        })

        const duplicatedCollectionPlansResponses = await Promise.allSettled(memberCreateCalls)
        const duplicatedCollectionPlans = duplicatedCollectionPlansResponses.map((response) => response.value.data)

        const duplicateCollectionPlanGroup = {
          ...collectionPlanGroup,
          id: null,
          url: null
        }

        await dispatch(ADD_COLLECTION_PLAN_GROUP, {
          collectionPlanGroup: duplicateCollectionPlanGroup,
          collectionPlanMembers: duplicatedCollectionPlans
        })

        return true
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [DELETE_COLLECTION_PLAN_GROUP]: async ({
      commit,
      dispatch,
      getters,
      rootGetters
    }, { collectionPlanGroup }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        await Promise.allSettled(getters.collectionPlanGroupsByUrl[collectionPlanGroup.url].map((collectionPlan) => dispatch(DELETE_COLLECTION_PLAN, collectionPlan)))
        await dispatch(DELETE_COLLECTION_PLAN, collectionPlanGroup)
        return true
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    },
    [PRE_ANNOUNCE_COLLECTIONS]: async ({
      commit,
      rootGetters
    }, { collectionId, organizationUrl }) => {
      commit(INC_LOADING, null, { root: true })
      try {
        await rootGetters.restApi.post(`collection_plan/${collectionId}/pre_announce_collections/`, {
          organization: organizationUrl
        })
        commit(SET_SNACKBAR_MESSAGE, { message: 'Vorankündigungen werden verschickt' }, { root: true })
        return true
      } catch (error) {
        await commit(SET_ERROR_MESSAGE, { message: `Fehler in der Übertragung: ${error}` }, { root: true })
      } finally {
        commit(DEC_LOADING, null, { root: true })
      }
    }
  }
}
