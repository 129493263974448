<template>
  <!--<v-card
    class="d-flex align-content-center justify-center flex-wrap"
    style="height: calc(100vh - 31px); background-color: unset; overflow: auto"
    elevation="0"
  >-->
  <random-background>
    <v-dialog
      id="fillable-fields"
      v-model="dialog"
      max-width="800"
      min-width="350"
      scrollable
    >
      <v-overlay opacity="0.6" z-index="0" color="white"></v-overlay>
      <v-card>
        <v-card-text align="center">
          <logo
            dark
            class="ma-5 mb-2"
            id="app-logo"
          />
            <template v-if="this.collectionPlan === null">
              <p>Sammlung nicht gefunden. Der Link ist entweder abgelaufen oder falsch.</p>
            </template>
            <template>
              <v-alert
                v-if="error"
                dense
                outlined
                type="error"
                :icon="mdiAlertCircle"
                color="red"
              >
                <span v-if="error.length">{{ error }}</span>
                <span v-else>Die eingegebenen Daten sind fehlerhaft.</span>
              </v-alert>
              <h4>{{ collectionPlan ? collectionPlan.organization.name : '' }}</h4>
              <p>
                <v-icon>{{ mdiClockStart }}</v-icon> {{ colStartDate ? colStartDate : '' }} Uhr -
                <span v-if="colEndDate === null">unbegrenzt</span>
                <span v-else><v-icon>{{ mdiClockEnd }}</v-icon> {{ colEndDate ? colEndDate : '' }}</span>
              </p>
              <p>"{{ collectionPlan ? collectionPlan.name : '' }}"</p>

              <template v-if="showSuccessMessage">
                <div>
                  <v-icon x-large>{{ mdiCheckCircleOutline }}</v-icon>
                </div>
                <div>
                  Vielen Dank, dass Sie die Angaben bestätigt haben.
                </div>
              </template>
              <template v-else>
                <v-row
                  v-if="collectionPlan && fieldsToDisplay.length"
                >
                  <v-col
                    v-for="field in fieldsToDisplay"
                    v-bind:key="field.key"
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="field.value"
                      :label="field.name"
                      :value="field.value"
                      outlined
                      dense
                      hide-details="true"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </template>
            </template>

        </v-card-text>
        <v-card-actions v-if="showSuccessMessage">
          <v-spacer></v-spacer>
          <v-btn
            text
            elevation="2"
            class="mb-4 mr-2 mt-2"
            @click="showSuccessMessage = false"
            color="primary"
            >
            Angaben bearbeiten
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn
            id="btn-save"
            text
            elevation="2"
            class="mb-4 mr-2 mt-2"
            @click="saveFields"
            :loading="waiting"
            color="primary"
          >
            Änderungen Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </random-background>
</template>
<script>
import { FETCH_FILLABLE_FIELDS_FOR_COLLECTION, PATCH_FILLABLE_FIELDS_FOR_COLLECTION } from "../store/action-types"
import { mapState } from "vuex"
import config from "@/config"
import { SET_ERROR_MESSAGE } from '@/modules/common/store/mutation-types'
import moment from "moment"
import RandomBackground from '@/components/RandomBackground'
import { mdiAlertCircle, mdiCheckCircleOutline, mdiClockEnd, mdiClockStart } from '@mdi/js'
import Logo from '@/components/icons/Logo'

export default {
  name: "fillablefields",
  components: { RandomBackground, Logo },
  data () {
    return {
      collectionPlan: null,
      fields: [],
      dialog: true,
      showSuccessMessage: false,
      mdiCheckCircleOutline,
      mdiClockStart,
      mdiClockEnd,
      mdiAlertCircle,
    }
  },
  computed: {
    ...mapState(["loading"]),
    error () {
      // FIXME Why user auth error?
      return this.$store.state.user.auth.error
    },
    waiting () {
      return this.loading > 0
    },
    schriftzug: function () {
      return require("@/assets/schriftzug_" + config.deployment + ".svg")
    },
    colStartDate: function () {
      return !this.collectionPlan || this.collectionPlan.start === null
        ? null
        : moment(this.collectionPlan.start).format("llll")
    },
    colEndDate: function () {
      return this.collectionPlan === null || this.collectionPlan.end === null
        ? null
        : moment(this.collectionPlan.end).format("llll")
    },
    fieldsToDisplay () {
      return this.fields.filter(field => field?.type !== 'confirmed')
    },
    token () {
      return this.$route.params?.token ?? null
    },
  },
  methods: {
    async patchFields () {
      if (this.token === null) {
        return
      }
      // speichern der Felder nach : { meta: { accounting: { Sachkonto: 'asd' } } }
      const res = await this.$store.dispatch('collectionPlan/' + PATCH_FILLABLE_FIELDS_FOR_COLLECTION, {
        token: this.token,
        data: {
          meta: { accounting: this.preparePatchData() }
        }
      })
      if (res) {
        this.showSuccessMessage = true
      }
    },
    preparePatchData () {
      const resVal = {}
      for (const i in this.fields) {
        if (this.fields[i]?.type === 'confirmed') {
          resVal[this.fields[i].name] = 'true'
          continue
        }
        resVal[this.fields[i].name] = this.fields[i].value === '' ? null : this.fields[i].value
      }
      return resVal
    },
    async loadCollectionPlan () {
      if (this.token === null || this.token === "") {
        this.snack("Keine Sammlungs-ID gesetzt")
        return
      }
      this.collectionPlan = await this.$store.dispatch(
        "collectionPlan/" + FETCH_FILLABLE_FIELDS_FOR_COLLECTION,
        { token: this.token }
      )
      if (this.collectionPlan === null) {
        this.snack('Die Sammlung konnte nicht geladen werden')
      } else {
        // TODO: check for null
        let hasConfirmedFields = false
        const fields = []
        const handledFields = new Set()
        // eslint-disable-next-line camelcase
        for (const element of this.collectionPlan?.settings?.accounting?.extra_fields ?? []) {
          if (handledFields.has(element?.name)) {
            continue
          }
          fields.push({
            ...element,
            value: (this.collectionPlan?.meta?.accounting ?? {})[element.name] ? this.collectionPlan?.meta?.accounting[element.name] : ''
          })
          if (element?.type === 'confirmed') {
            hasConfirmedFields = true
          }
          handledFields.add(element?.name)
        }
        this.fields = fields

        if ('confirmed' in this.$route.query && hasConfirmedFields) {
          await this.patchFields()
        }
      }
    },
    saveFields () {
      this.patchFields()
    },
    snack (message) {
      this.$store.commit(SET_ERROR_MESSAGE, { message })
    },
  },
  watch: {
    token: {
      immediate: true,
      async handler (newVal) {
        if (newVal) {
          await this.loadCollectionPlan()
        }
      }
    }
  },
}
</script>

<style lang="stylus" scoped>
#app-logo
  max-width: 300px
  max-height: 120px
</style>
