<template>
  <v-container>
    <v-row no-gutters>
      <v-col cols="12" style="text-align: center">
        <v-switch class="mt-0 pt-0" style="display: inline-block;" v-model="recursive" label="Unterorganisationen einschließen"></v-switch>
      </v-col>
      <v-col cols="12">
        <div class="chart-row">
          <stats-daily
            class="stats stats-daily"
            :stats="currentOrganizationPaymentHistory"
            :currency="organization.currency"
          ></stats-daily>
          <p
            class="d-print-none"
            style="text-align: center;">Wenn Sie mit der Maus über die einzelnen Punkte fahren, werden Ihnen die Einnahmen an einem konkreten Tag angezeigt.</p>
        </div>
      </v-col>
    </v-row>
    <template v-if="currentOrganizationStatistics">
      <v-divider class="mb-8"></v-divider>
      <v-card class="d-print-none">
        <v-card-text>
          <v-row class="d-print-none">
            <v-col cols="12" md="6">
              <time-range-select
                v-if="earliestPaymentDate"
                :earliest-date="earliestPaymentDate"
                @change="updateDashboardStatisticsTimeRange"
              />
              <div v-else>
                Es sind noch keine Einnahmen vorhanden.
              </div>
            </v-col>
            <v-col cols="12" md="6">
              <div class="text-right">
                <v-btn
                  color="primary"
                  text
                  @click="requestPrintJob"
                >
                  <v-icon>{{ mdiPrinter }}</v-icon> Drucken
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-row
        justify="center"
        class="mt-4"
      >
        <v-col cols="12">
          <div class="text-center text-h4">Zeitraum: {{ momentize(currentOrganizationStatistics.timerange.from) }} -
            {{ momentize(currentOrganizationStatistics.timerange.to) }}
          </div>
        </v-col>
      </v-row>
      <v-row class="d-print-block">
        <v-col
          v-for="aggregate in aggregateStats"
          :key="aggregate.key"
          cols="12"
          md="3"
        >
          <v-card outlined>
            <v-card-text>
              <div class="text-h4 text-center">
                {{ aggregate.amount|amount }} {{ organization.currency|currencySymbol }}
              </div>
              <div class="font-weight-bold text-center">
                {{ aggregate.label }}
              </div>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="d-print-none">
        <v-col cols="12">
          <div class="text-right">
            <router-link :to="{ name: 'organization.collections', params: { id: organization.id } }">Zu den Einnahmen</router-link>
          </div>
        </v-col>
      </v-row>

      <v-row class="page-break">
        <v-col
          cols="12" md="6"
        >
          <aggregate-stats-card
            title="Bezahlarten"
            :aggregate-data="currentOrganizationStatistics.byPaymentType"
            aggregate-type="paymentType"
          ></aggregate-stats-card>
        </v-col>
        <v-col
          cols="12" md="6"
        >
          <aggregate-stats-card
            title="Geräte"
            :aggregate-data="currentOrganizationStatistics.byDeviceType"
            aggregate-type="deviceType"
          ></aggregate-stats-card>
        </v-col>
      </v-row>
      <v-row class="page-break">
        <v-col
          cols="12" md="6"
        >
          <aggregate-stats-card
            title="Sammlungen"
            :aggregate-data="currentOrganizationStatistics.byCollectionPlan"
            aggregate-type="collectionPlan"
            display-type="list"
            @collectionPlanSelected="showCollectionPlanStats"
          ></aggregate-stats-card>
        </v-col>
        <v-col
          cols="12" md="6"
        >
          <aggregate-stats-card
            title="Sammlungstyp"
            :aggregate-data="currentOrganizationStatistics.byCollectionType"
            aggregate-type="collectionType"
          ></aggregate-stats-card>
        </v-col>
      </v-row>
    </template>

    <v-dialog
      v-model="isEvaluationDialogOpen"
      transition="dialog-top-transition"
      scrollable
      width="44em"
    >
      <v-card elevation="0">
        <v-card-text>
          <evaluation-stats
            :evalCollectionPlan="selectedCollectionPlan"
            :key="selectedCollectionPlan"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click.stop.prevent="isEvaluationDialogOpen = false"
            color="primary"
          >
            Schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import {
  UPDATE_RECURSIVE,
  FETCH_DEVICES,
  FETCH_LOCATIONS,
  FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION,
  FETCH_STATIC_CONFIGURATION,
  FETCH_ORGANIZATIONS,
  FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION, FETCH_ORGANIZATION_COLLECTION_PLANS, FETCH_DIRECT_PAYMENT_PAIN_FILES
} from '@/store/action-types'
import { mapGetters, mapState } from 'vuex'
import moment from 'moment'
import StatsDaily from '@/components/revenue/StatsDaily'
import { PERM_TYPE_READ_OTHER } from '@/lib/permission-tools'
import AggregateStatsCard from "@/components/dashboard/AggregateStatsCard.vue"
import TimeRangeSelect from "@/components/UI/TimeRangeSelect.vue"
import EvaluationStats from "@/components/revenue/EvaluationStats.vue"
import { mdiPrinter } from "@mdi/js"

export default {
  name: 'organization-dashboard',
  components: {
    TimeRangeSelect,
    StatsDaily,
    AggregateStatsCard,
    EvaluationStats
  },
  data () {
    return {
      collectionMembers: null,
      devices: null,
      currentOrganizationStatistics: null,
      statisticsTimeRange: null,
      isEvaluationDialogOpen: false,
      selectedCollectionPlan: null,
      mdiPrinter
    }
  },
  computed: {
    ...mapGetters('organization', { organization: 'currentOrganization' }),
    ...mapState('location', ['locations']),
    ...mapGetters('location', ['locationsLookup']),
    ...mapGetters('user', ['hasPermission']),
    ...mapGetters('dashboard', ['statisticsByOrganizationUrl']),
    currentLocations () {
      return this?.locations?.filter(l => l.organization === (this?.organization?.url ?? false)) ?? []
    },
    currentOrganizationPaymentHistory () {
      return this.$store.state.dashboard.paymentHistoryAggregateByOrganizationUrl[this.organization.url]?.byDay ?? []
    },
    earliestPaymentDate () {
      if (!this.currentOrganizationPaymentHistory?.length) {
        return null
      }
      return moment(this.currentOrganizationPaymentHistory[0].date)
    },
    recursive: {
      get () {
        return this.$store.state.userPrefs.recursive
      },
      async set (value) {
        await this.$store.dispatch("userPrefs/" + UPDATE_RECURSIVE, { recursive: value })
      }
    },
    aggregateStats () {
      if (!this.currentOrganizationStatistics.aggregate.length) {
        return []
      }

      return Object.entries(this.currentOrganizationStatistics.aggregate[0]).map(([key, amount]) => {
        return {
          key,
          amount,
          label: this.$tc(`dashboard.aggregate.${key}`)
        }
      }).filter(entry => entry.key !== 'currency')
    },
  },
  created () {
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('staticConfiguration/' + FETCH_STATIC_CONFIGURATION),
      this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
    ])
    if (this.hasPermission(this.organization?.url, PERM_TYPE_READ_OTHER)) {
      await Promise.allSettled([
        this.$store.dispatch('location/' + FETCH_LOCATIONS)
      ])
    }
  },
  methods: {
    momentize (dateValue) {
      return moment(dateValue).format('DD.MM.YYYY')
    },
    async updateDashboardStatisticsTimeRange ({ since, before }) {
      await this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION, {
        organizationUrl: this.organization.url,
        since,
        before
      })
      this.currentOrganizationStatistics = this.statisticsByOrganizationUrl[this.organization.url]
    },
    requestPrintJob () {
      window.print()
    },
    showCollectionPlanStats (collectionPlan) {
      this.selectedCollectionPlan = collectionPlan
      this.isEvaluationDialogOpen = true
    }
  },
  watch: {
    recursive: async function () {
      // Need to re-fetch with new recursive value
      if (this.organization?.url ?? null) {
        if (this.hasPermission(this.organization?.url, PERM_TYPE_READ_OTHER)) {
          await Promise.allSettled([
            this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, { organizationUrl: this.organization.url }),
            this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url)
          ])
          await this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION, {
            organizationUrl: this.organization.url,
            since: this.earliestPaymentDate?.startOf('day').toISOString(),
            before: moment().toISOString()
          })
        }
      }
    },
    organization: {
      immediate: true,
      async handler (newVal) {
        if (newVal?.url) {
          if (this.hasPermission(newVal.url, PERM_TYPE_READ_OTHER)) {
            await Promise.allSettled([
              this.$store.dispatch('device/' + FETCH_DEVICES, newVal.url).then(() => {
                this.devices = this.$store.state.device.devicesByOrganization[newVal.url] ?? []
              }),
              this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, { organizationUrl: newVal.url }),
              this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url),
              this.$store.dispatch('payment/' + FETCH_DIRECT_PAYMENT_PAIN_FILES)
            ])

            await this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_STATISTICS_FOR_ORGANIZATION, {
              organizationUrl: this.organization.url,
              since: this.earliestPaymentDate?.toISOString(),
              before: moment().toISOString()
            })

            this.currentOrganizationStatistics = this.statisticsByOrganizationUrl[this.organization.url]
          }
        }
      }
    }
  }
}
</script>

<style lang="stylus">
.stats
  width: 100%
  height: 35em

@media print
  .chartjs-size-monitor
    display: none
</style>
