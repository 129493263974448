<template>
  <div>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col
            v-if="collectionPlanGroupEntries.length"
            cols="12"
          >
            <v-card
              class="pa-3"
              outlined>
              <v-card-title>
                <v-icon>{{ mdiFormatListGroup }}</v-icon>
                <h5>{{ $t('collectionPlan.labels.collectionGroup') }}</h5>
              </v-card-title>
              <v-card-text>
                <div
                  v-for="collectionPlanGroupEntry in collectionPlanGroupEntries"
                  :key="collectionPlanGroupEntry.url"
                  class="mb-1"
                >
                  {{ collectionPlanGroupEntry.weight }}
                  {{ $tc('collectionPlan.labels.collectionGroupWeight', collectionPlanGroupEntry.weight) }}
                  {{ collectionPlanGroupEntry.name }}
                  ({{ convertWeightToRatio(collectionPlanGroupEntry) }})
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card class="pa-3" outlined>
              <h5>{{ $t('collectionPlan.description') }}</h5>
              <v-textarea
                v-if="!currentCollectionStarted"
                :label="$t('collectionPlan.description')"
                hint="max. 1000 Zeichen"
                :rules="detailDescriptionRules"
                v-model="description"
                counter="1000"
                outlined
              ></v-textarea>
              <span v-else>
                <template v-if="!description">{{ $t('general.notAvailable') }}</template>
                <template v-else>{{ description }}</template>
              </span>
              <v-divider class="my-3" v-if="getWebsite(collection) || collectionPlanGroupEntryWebsites.length"></v-divider>
              <template
                v-if="collectionPlanGroupEntries.length"
              >
                <div class="d-flex align-center">
                  <v-icon small>mdi-web</v-icon>
                  <span
                    v-for="(website, index) in collectionPlanGroupEntryWebsites"
                    :key="index"
                  >
                    <a
                      class="ml-1"
                      :href="website"
                      target="_blank"
                    >{{ website }}</a>
                    <span v-if="(index + 1) !== collectionPlanGroupEntryWebsites.length"> &amp; </span>
                  </span>
                </div>
              </template>

              <div
                v-else-if="getWebsite(collection)"
                class="d-flex align-center"
              >
                <v-icon small>mdi-web</v-icon>
                <a
                  class="ml-1"
                  :href="getWebsite(collection)"
                  target="_blank"
                >{{ getWebsite(collection) }}</a>
              </div>
            </v-card>
          </v-col>

          <v-col cols="12">
            <v-card outlined class="pa-3">
              <h5>{{ $t('collectionPlan.displayCollectionPurpose') }}</h5>
              <div
                v-if="collection.targets.includes('WS') && relevantDevices.has('bart')"
                class="mb-1"
              >
                <div class="text-caption font-weight-bold">spende.app</div>
                <div>{{ collection.display.bart ? collection.display.bart : collection.name }}</div>
              </div>
              <div
                v-if="collection.targets.includes('WA') && relevantDevices.has('john')"
                class="mb-1">
                <div class="text-caption font-weight-bold">kollekte.app</div>
                <div>{{ collection.display.john ? collection.display.john : collection.name }}</div>
              </div>
              <div
                v-if="collection.targets.includes('SP') && relevantDevices.has('james')"
                class="mb-1">
                <div class="text-caption font-weight-bold">Spendensäule</div>
                <div>{{ collection.display.james ? collection.display.james : collection.name }}</div>
              </div>
              <div
                v-if="collection.targets.includes('KB') && relevantDevices.has('peter')"
                class="mb-1">
                <div class="text-caption font-weight-bold">Klingelbeutel</div>
                <div>{{ collection.display.peter ? collection.display.peter : collection.name }}</div>
              </div>
            </v-card>
          </v-col>
        </v-row>

        <collection-plan-details-accounting-data
          v-if="!collectionPlanGroupEntries.length"
          :collection="collection"
        />

        <v-tabs
          v-else
          color="primary"
          v-model="groupEntryTab"
        >
          <v-tab
            v-for="groupEntry in collectionPlanGroupEntries"
            :key="groupEntry.url"
          >
            {{ groupEntry.name }}
          </v-tab>

          <v-tabs-items
            v-model="groupEntryTab"
          >
            <v-tab-item
              v-for="groupEntry in collectionPlanGroupEntries"
              :key="groupEntry.url"
            >
              <collection-plan-details-accounting-data
                :collection="groupEntry"
              />
            </v-tab-item>
          </v-tabs-items>

        </v-tabs>

        <v-row>
          <v-col
            v-if="recurringPaymentsEnabled"
            cols="12"
          >
            <v-checkbox
              :disabled="collection.allow_recurring"
              v-model="allowRecurringDonation"
              @change="handleRecurringDonationChanged"
              :label="$t('collectionPlan.labels.allowRecurring')"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn @click="confirm">
        {{ (collection.currentlyActive || collection.hasPassed) && !allowEditing ? 'OK' : 'Abbrechen' }}
      </v-btn>
      <v-btn
        v-if="allowEditing"
        color="primary"
        :loading="isSavingInProgress"
        :disabled="description > 1000"
        @click="save"
      >
        {{ $t('action.save') }}
      </v-btn>
    </v-card-actions>
    <base-dialog
      :is-open="showRecurringDonationConfirmationDialog"
      @close="denyRecurringDonation"
    >
      <template #dialog-title>
        {{ $t('collectionPlan.labels.allowRecurringShort') }}
      </template>

      <template #dialog-content>
        <v-card-text>
          <div class="pt-4">
            {{ $t('collectionPlan.hints.activateRecurringDonation') }}
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="denyRecurringDonation">Nein</v-btn>
          <v-btn
            @click="confirmRecurringDonation"
            color="primary"
          >Ja</v-btn>
        </v-card-actions>
        <div>
        </div>
      </template>
    </base-dialog>
  </div>
</template>

<script>
import IbanFilter from "@/mixins/group-character-filter"
import BaseDialog from "@/components/UI/BaseDialog.vue"
import CollectionPlanDetailsAccountingData from "@/components/collection/CollectionPlanDetailsAccountingData.vue"
import { mdiCheck, mdiFormatListGroup } from '@mdi/js'
import { mapGetters } from 'vuex'

export default {
  name: "CollectionPlanDetails",
  mixins: [
    IbanFilter
  ],
  components: {
    BaseDialog,
    CollectionPlanDetailsAccountingData
  },
  props: {
    collection: {
      type: Object,
      required: true
    },
    organization: {
      type: Object,
      required: true
    },
    relevantDevices: {
      type: Set,
      required: true
    },
    isSavingInProgress: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['cancel', 'confirm', 'save'],
  data () {
    return {
      savingDetailDescription: false,
      description: '',
      detailDescriptionRules: [v => v.length <= 1000 || 'max. 1000 Zeichen'],
      allowRecurringDonation: false,
      showRecurringDonationConfirmationDialog: false,
      groupEntryTab: null,
      mdiCheck,
      mdiFormatListGroup
    }
  },
  watch: {
    'collection.meta.description.text': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.description = newVal || ''
      }
    },
    'collection.id': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        // eslint-disable-next-line camelcase
        this.allowRecurringDonation = this.collection?.allow_recurring || false
      }
    }
  },
  mounted () {
    this.description = this.collection.meta?.description?.text || ''
    // eslint-disable-next-line camelcase
    this.allowRecurringDonation = this.collection?.allow_recurring || false
  },
  computed: {
    ...mapGetters('collectionPlan', ['collectionPlanGroupsByUrl']),
    collectionPlanGroupEntries () {
      return this.collectionPlanGroupsByUrl[this.collection.url] ?? []
    },
    collectionPlanGroupEntryWebsites () {
      return this.collectionPlanGroupEntries
        .map(entry => this.getWebsite(entry))
        .filter(website => website.length)
    },
    currentCollectionStarted () {
      if (this.collection) {
        return this.collection.currentlyActive || this.collection.hasPassed
      }
      return false
    },
    organizationSettings () {
      return this.$store.getters['organization/organizationSettingsByUrl'][this.organization?.url ?? null] ?? {}
    },
    recurringPaymentsEnabled () {
      if (this.collection.organization !== this.organization.url) {
        return false
      }

      // eslint-disable-next-line camelcase
      return this.organizationSettings?.features?.allow_recurring_payments ?? false
    },
    allowEditing () {
      // return !this.currentCollectionStarted

      // 248: Uncomment as soon it is possible to save the recurring_donation flag
      if (!this.currentCollectionStarted) {
        return true
      }
      return (
        this.recurringPaymentsEnabled &&
        !this.collection.allow_recurring &&
        this.allowRecurringDonation
      )
    }
  },
  methods: {
    cancel () {
      this.$emit('cancel')
    },
    confirm () {
      this.$emit('confirm')
    },
    save () {
      this.$emit('save', {
        detailDescription: !this.currentCollectionStarted ? this.description : null,
        allowRecurringDonation: this.allowRecurringDonation
      })
    },
    handleRecurringDonationChanged () {
      if (!this.allowRecurringDonation) {
        return
      }

      this.showRecurringDonationConfirmationDialog = true
    },
    confirmRecurringDonation () {
      this.allowRecurringDonation = true
      this.showRecurringDonationConfirmationDialog = false
    },
    denyRecurringDonation () {
      this.allowRecurringDonation = false
      this.showRecurringDonationConfirmationDialog = false
    },
    convertWeightToRatio (collection) {
      const totalWeight = this.collectionPlanGroupEntries.reduce((total, entry) => total + entry.weight, 0)
      const ratio = Number(collection.weight / totalWeight).toLocaleString('de-De', {
        style: 'percent',
        maximumFractionDigits: 2
      })

      return ratio.replace(/\s/g, "")
    },
    getWebsite (collection) {
      // eslint-disable-next-line camelcase
      const website = collection.meta?.external_fields?.www || null

      if (!website) {
        return ''
      }

      if (website.startsWith('http')) {
        return website
      }

      return `https://${website}`
    }
  }
}
</script>
