<template>
  <v-dialog
    :value="value"
    @input="v => $emit('input', v)"
    transition="dialog-top-transition"
    scrollable
    width="44em"
    :persistent="!!loading || isAnonymous"
  >
    <v-card elevation="0">
      <v-card-title class="headline font-weight-bold mb-0 pb-0">
        Barsammlung eintragen
      </v-card-title>
      <v-card-subtitle class="pb-0">
        <v-card
          flat
          class="mt-8 px-0"
        >
          <v-card-title class="px-0">{{ anonymousCollectionName }}</v-card-title>
          <v-card-text class="px-0">{{ anonymousOrganizationName }}</v-card-text>
        </v-card>

        <collection-card
          v-if="!isAnonymous"
          class="mt-8"
          :collection="collection"
          :current-organization="organization"
          :show-buttons="false"
        />
        <h5>Zeitpunkt der Barsammlung</h5>
        <v-datetime-picker
          v-model="timestamp"
          :okText="$tc('action.confirm')"
          :clearText="$tc('action.cancel')"
          :disabled="!!loading"
          label="Zeitpunkt"
          :time-picker-props="{format: '24hr'}"
          dateFormat="dd.MM.yyyy,"
          timeFormat="HH:mm"
          :text-field-props="{dense: true, outlined: true, errorMessages: timestamp ? null : 'Dieses Feld ist erforderlich.'}"
        >
          <template v-slot:dateIcon>
            <v-icon>{{ mdiCalendarMonth }}</v-icon>
          </template>
          <template v-slot:timeIcon>
            <v-icon>{{ mdiClockTimeFourOutline }}</v-icon>
          </template>
        </v-datetime-picker>
      </v-card-subtitle>
      <v-card-text>
        <h5 class="mt-0">Bareinnahmen</h5>
        <v-tabs v-model="inputTypeTab">
          <v-tab><v-icon class="mr-2">{{ mdiFormTextbox }}</v-icon> Summe</v-tab>
          <v-tab><v-icon class="mr-2">{{ mdiTableLarge }}</v-icon> Rechner</v-tab>
        </v-tabs>
        <v-tabs-items v-model="inputTypeTab">
          <v-tab-item>
            <CurrencyInput
              class="mt-2"
              v-model="amountCents"
              :disabled="!!loading"
              outlined
              autofocus
            />
          </v-tab-item>
          <v-tab-item>
            <CashPaymentTableInput
              v-model="amountCents"
              :disabled="!!loading"
            />
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="!isAnonymous"
          @click.stop.prevent="$emit('input', false)"
          color="neutral"
          :disabled="!!loading"
        >
          Abbrechen
        </v-btn>
        <v-btn
          @click.stop.prevent="delegateSaveCashCollection()"
          color="primary"
          :disabled="amountCents <= 0 || !timestamp"
          :loading="!!loading"
        >
          {{ amountCents | currency }}
          eintragen
        </v-btn>
      </v-card-actions>

    </v-card>
  </v-dialog>
</template>

<script>
import CashPaymentTableInput from '@/components/collection/CashPaymentTableInput.vue'
import CurrencyInput from '@/components/CurrencyInput.vue'
import { SET_SNACKBAR_MESSAGE } from '@/modules/common/store/mutation-types'
import { mdiCalendarMonth, mdiClockTimeFourOutline, mdiFormTextbox, mdiTableLarge } from '@mdi/js'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { mapState } from 'vuex'

export default {
  name: "AddCashCollectionDialog",
  emits: ['save-cash-collection'],
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    collection: {
      type: Object,
      required: false,
    },
    organization: {
      type: Object,
      required: false,
    },
    anonymousCollectionName: {
      type: String,
      required: false,
    },
    anonymousOrganizationName: {
      type: String,
      required: false,
    }
  },
  data: () => ({
    inputTypeTab: null,
    amountCents: 0,
    timestamp: new Date(),
    mdiCalendarMonth,
    mdiClockTimeFourOutline,
    mdiFormTextbox,
    mdiTableLarge,
  }),
  components: {
    CashPaymentTableInput,
    CurrencyInput,
  },
  beforeCreate () {
    // ref: https://v2.vuejs.org/v2/guide/components-edge-cases.html?redirect=true#Circular-References-Between-Components
    this.$options.components.CollectionCard = require('@/components/collection/CollectionCard.vue').default
  },
  computed: {
    ...mapState(['loading']),
    isAnonymous () {
      return !this.organization && !this.collection
    }
  },
  methods: {
    async delegateSaveCashCollection () {
      if (this.amountCents <= 0 || !this.timestamp) {
        return
      }

      if (this.isAnonymous) {
        this.saveCashCollectionAnonymous()
      } else {
        this.saveCashCollection()
      }

      this.$emit('input', false)
      this.amountCents = 0
      this.timestamp = new Date()
    },
    async saveCashCollection () {
      const amount = this.$options.filters.currency(this.amountCents)
      const message = `Sie haben erfolgreich eine Barsammlung in der Höhe von ${amount} am ${this.timestamp.toLocaleDateString()} eingetragen. Vielen Dank`

      await loadingStateWrapper(this.$store, async () => {
        await this.$store.getters.restApi.post('cash/cash_payment/', {
          amount: '' + (this.amountCents / 100).toFixed(2),
          collection_plan: this.collection.url,
          organization: this.organization.url,
          collection_datetime: this.timestamp,
        })
      })

      // Sie haben erfolgreich eine Barsammlung in der Höhe von 23,71€ am 30.08.2024 eingetragen. Vielen Dank
      // const amount = this.$options.filters.currency(this.amountCents)

      await this.$store.commit(
        SET_SNACKBAR_MESSAGE,
        { message },
        { root: true }
      )
    },
    async saveCashCollectionAnonymous () {
      await loadingStateWrapper(this.$store, async () => {
        const amount = '' + (this.amountCents / 100).toFixed(2)
        this.$emit('save-cash-collection', {
          amount
        })
      })
    },
  },
}
</script>

<style scoped>

</style>
