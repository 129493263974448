<template>
  <div>
    <v-list-item :to="{name: 'management.org.details', id: currentOrganization.id}" v-if="!isOrgDataRoute">
      <v-list-item-icon><menu-icon icon="organization" scale="0.6"/></v-list-item-icon>
      <v-list-item-title>Organisation</v-list-item-title>
    </v-list-item>
    <template v-else>
      <organization-nav-list-group :links="managementSubLinks">
        <template #activator>
          <v-list-item-icon><menu-icon icon="organization" scale="0.6"/></v-list-item-icon>
          <v-list-item-title>Organisation</v-list-item-title>
        </template>
      </organization-nav-list-group>
    </template>

    <v-list-item
      @click="expandOrgAccountList = !expandOrgAccountList"
      v-if="!isOrgAccountsRoute && !expandOrgAccountList"
    >
      <v-list-item-icon><menu-icon icon="evaluation" scale="0.6"/></v-list-item-icon>
      <v-list-item-title>Dokumente &amp; Listen</v-list-item-title>
    </v-list-item>
    <organization-nav-list-group
      v-else
      :links="documentsAndListsSublinks"
    >
      <template #activator>
        <v-list-item-icon><menu-icon icon="evaluation" scale="0.6"/></v-list-item-icon>
        <v-list-item-title>Dokumente &amp; Listen</v-list-item-title>
      </template>
    </organization-nav-list-group>

    <v-list-item
      v-if="!isEvaluationRoute"
      :to="{name: 'organization.dashboard', id: currentOrganization.id}"
    >
      <v-list-item-icon><v-icon color="primary">{{ mdiFinance }}</v-icon></v-list-item-icon>
      <v-list-item-title>Auswertungen</v-list-item-title>
    </v-list-item>
    <organization-nav-list-group
      v-else
      :links="evaluationSublinks"
    >
      <template #activator>
        <v-list-item-icon><v-icon color="primary">{{ mdiFinance }}</v-icon></v-list-item-icon>
        <v-list-item-title>Auswertungen</v-list-item-title>
      </template>
    </organization-nav-list-group>
    <v-list-item v-if="availableOrgFeatureTypes.has('veranstaltung_app') && config.veranstaltungApp.url">
      <v-list-item-title>
        <a :href="config.veranstaltungApp.url + '/control/'" target="_blank">
          veranstaltung.app
        </a>
      </v-list-item-title>
    </v-list-item>
    <v-list-item v-if="availableOrgFeatureTypes.has('kirchgeld_digital') && config.kirchgeldDigital.url">
      <v-list-item-title>
        <a :href="config.kirchgeldDigital.url" target="_blank">
          kirchgeld.digital
        </a>
      </v-list-item-title>
    </v-list-item>

    <v-divider/>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import MenuIcon from '@/components/icons/MenuIcon.vue'
import { MANAGE_USERS, MEMBER, READ, READ_NONSENSITIVE, READ_ORG_DATA } from '@/lib/permission-tools'
import { ALLOW_RECURRING_PAYMENTS, ALLOW_EMAIL_CONTACT } from '@/lib/settings-tools'
import OrganizationNavListGroup from '@/components/OrganizationNavListGroup.vue'
import { DEVICE_CLASSES, DEVICE_TYPES } from '@/lib/device-db'
import { mdiFinance } from "@mdi/js"
import config from '@/config'
import { FETCH_DIRECT_PAYMENT_PAIN_FILES } from "@/store/action-types"

export default {
  name: 'OrganizationNavFragment',
  data () {
    return {
      mdiFinance,
      config,
      expandOrgAccountList: false
    }
  },
  components: {
    OrganizationNavListGroup,
    MenuIcon
  },
  computed: {
    ...mapState(['now']),
    ...mapGetters('organization', ['currentOrganization', 'organizationRelevantDeviceTypesForUrl']),
    ...mapGetters('user', ['effectivePermissionsByUrl', 'isSuperuser']),
    ...mapGetters('payment', ['directPaymentPainFilesByOrganization']),
    isOrgDataRoute () {
      return (this.$route?.name ?? "").startsWith("management.org")
    },
    isOrgAccountsRoute () {
      const validLinks = [
        "evaluation.org.donationslist",
        "evaluation.org.subscriberlist",
        "evaluation.org.recurringDonationslist",
        "evaluation.org.invoicing",
        "evaluation.org.directdebits"
      ]

      for (const validLink of validLinks) {
        if ((this.$route?.name ?? "").startsWith(validLink)) {
          return true
        }
      }

      return false
    },
    isEvaluationRoute () {
      const route = this.$route?.name ?? ""

      return route.startsWith("organization.dashboard") || route.startsWith("evaluation.org.device")
    },
    effectivePermissions () {
      return this.effectivePermissionsByUrl[this.currentOrganization?.url] ?? new Set()
    },
    managementSubLinks () {
      const retval = []
      if (this.effectivePermissions.has(READ_NONSENSITIVE) || this.effectivePermissions.has(READ_ORG_DATA)) {
        retval.push(["management.org.details", null, "Vertragsdaten"])
      }
      if (this.effectivePermissions.has(MANAGE_USERS)) {
        retval.push(["management.org.users", null, "Benutzer"])
      }
      if (this.effectivePermissions.has(READ_ORG_DATA)) {
        retval.push(["management.org.notifications", null, "Benachrichtigungen"])
      }
      if (this.effectivePermissions.has(READ)) {
        retval.push(["management.org.locations", null, "Orte"])
      }
      return retval
    },
    documentsAndListsSublinks () {
      const retval = []
      if (!this.effectivePermissions.has(READ)) {
        return retval
      }

      retval.push(["evaluation.org.donationslist", null, "Zuwendungsbestätigungen"])

      if (this.features[ALLOW_EMAIL_CONTACT]) {
        retval.push(["evaluation.org.subscriberlist", null, "Informationsanforderungen"])
      }

      if (this.directPaymentPainFilesByOrganization[this.currentOrganization.url]?.length > 0) {
        retval.push(["evaluation.org.directdebits", null, "Lastschriftdateien"])
      }

      if (this.features[ALLOW_RECURRING_PAYMENTS]) {
        retval.push(["evaluation.org.recurringDonationslist", null, "Dauerspenden"])
      }

      retval.push(["evaluation.org.invoicing", null, "Abrechnungen"])
      return retval
    },
    evaluationSublinks () {
      const retval = []
      retval.push(["organization.dashboard", null, "Übersicht"])
      if (this.effectivePermissions.has(READ)) {
        const relevantClasses = Array.from(
          new Set( // Create set, that is, unique entries, from relevant device classes
            Array.from(
              this.organizationRelevantDeviceTypesForUrl(this.currentOrganization.url)
            ).map(item => DEVICE_TYPES[item]?.deviceClass).filter(item => !!DEVICE_CLASSES[item])
          )
        ) // Turn this into an array again to be able to sort it
        relevantClasses.sort(
          (a, b) => DEVICE_CLASSES[a].localeCompare(DEVICE_CLASSES[b])
        )
        for (const deviceClass of relevantClasses) {
          retval.push(["evaluation.org.device", null, DEVICE_CLASSES[deviceClass], { deviceClass }])
        }
      }
      return retval
    },
    availableOrgFeatureTypes () {
      if (this.effectivePermissions.has(MEMBER)) {
        // FIXME Create computed list of currently valid feature by organization URL
        return new Set(
          this.currentOrganization.features.filter(
            feature => (feature.valid_from === null || this.now.isSameOrAfter(feature.valid_from)) &&
              (feature.valid_before === null || this.now.isSameOrBefore(feature.valid_before))
          ).map(feature => feature.type)
        )
      }
      return new Set()
    },
    effectiveOrganizationSettings () {
      return this.$store.getters["settings/effectiveSettingsForUrl"](this.currentOrganization.url)
    },
    features () {
      return this.effectiveOrganizationSettings?.features ?? {}
    },
  },
  async mounted () {
    await this.$store.dispatch('payment/' + FETCH_DIRECT_PAYMENT_PAIN_FILES)
  }
}
</script>

<style scoped>

</style>
