<template>
  <v-container id="organization-plans">
    <v-snackbar :value="showSavingDetailDescriptionToast" :timeout="4000">
      {{ $t('actionResponse.savingSuccessful') }}
      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="showSavingDetailDescriptionToast= false"
        >
          Meldung schliessen
        </v-btn>
      </template>
    </v-snackbar>

    <v-tooltip
      v-model="tooltipActive"
      activator="dateTooltip"
      :position-x="positionx"
      :position-y="positiony"
    >
      <span>Export&nbsp;to&nbsp;Excel</span>
    </v-tooltip>
    <template>
      <v-row v-if="isPlanDialogOpen" justify="center">
        <v-dialog
          v-model="isPlanDialogOpen"
          transition="dialog-top-transition"
          scrollable
          persistent
          width="1200"
        >
          <collection-plan-editor
            @closeEditor="closePlanEditor"
            @collection-plan-group-created="collectionPlanGroupCandidates = []"
           :locations="locations"
           :dates="datesForPlanEditor"
           :organization="organization"
           ref="planEditor"
          ></collection-plan-editor>

        </v-dialog>
      </v-row>
    </template>

    <sweet-modal
      ref="confirmClose"
       :title="((confirmCloseCollection && confirmCloseCollection.end) ? 'Vorzeitig ' : 'Sammlung ') + 'beenden?'"
       @close="confirmCloseCollection=null">
      <div v-if="confirmCloseCollection">
        <collection-card
          :collection="confirmCloseCollection"
          :current-organization="organization"
          :show-buttons="false"
          :relevant-devices="relevantDevices"
          :dialog="openDetailsDialog"
        ></collection-card>
      </div>
      <div slot="button"> Soll diese Sammlung jetzt beendet werden?
        <v-btn
          text
          color="primary"
          @click="$refs.confirmClose.close(); confirmCloseCollection = null"
        >
          Nein
        </v-btn>
        <v-btn
          color="primary"
          @click="doClose(confirmCloseCollection)"
        >
          <v-icon>{{ mdiStop }}</v-icon>
          Ja, jetzt beenden
        </v-btn>
      </div>
    </sweet-modal>

    <v-dialog
      v-model="confirmExclude"
      transition="dialog-bottom-transition"
      max-width="800"
    >
      <v-card>
        <v-card-title><h2>Sammlung abwählen?</h2></v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pb-0">
          <p>Soll diese Sammlung auf der aktuellen Ebene ({{ organization.name }})
            und allen Unterebenen abgewählt werden?<br>
            Bitte beachten Sie ggbf. die Regelungen des Kollektenrechts.</p>
        </v-card-text>
        <v-card-text>
          <collection-card
            v-if="confirmExcludeCollection"
            :current-organization="organization"
            :collection="confirmExcludeCollection"
            :more-infos="true"
            :relevant-devices="relevantDevices"
            :device-filter="devicefilter"
            :showButtons="false"
            class="pb-3"
          ></collection-card>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            @click="confirmExclude=false; confirmExcludeCollection=null"
          >
            Nein, nichts tun
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="doExcludeCollection(confirmExcludeCollection)"
            :loading="$store.state.loading > 0"
          >
            Ja, Sammlung abwählen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Info Dialog -->
    <template>
      <v-row justify="center">
        <v-dialog
          v-model="infoDialog"
          transition="dialog-bottom-transition"
          scrollable
          width="800"
        >
          <v-card>
            <v-card-title>Planung von Spenden- und Kollektenzwecken</v-card-title>
            <v-divider></v-divider>
            <v-card-text style="">
              <p>Auf dieser Seite finden Sie Ihre geplanten Kollekten und Spendenzwecke. Wenn Sie einer Landeskirche
                oder einem Bistum angehören, ist unter Umständen ein Kollektenplan bereits hinterlegt. Diese Kollekten
                bekommen das Kennzeichen Hauptkollekte.</p>
              <p>Sie können neue Spenden- und Kollektenzwecke eintragen indem Sie auf <b>“+ Neue Sammlung eintragen”</b>
                klicken. Dort können Sie zunächst die Art der Sammlung auswählen:</p>
              <ul>
                <li>
                  Nebenkollekte (meist gemeindliche Zwecke, die in manchen Landeskirchen zusätzlich zur Hauptkollekte am
                  Ausgang gesammelt wird)
                </li>
                <li>
                  Freie Kollekte (sonstige Kollektenzwecke)
                </li>
                <li>
                  Spendenzwecke
                </li>
              </ul>
              <p>Sie können Sammlungen an einen Tag des Kirchenjahrs anlegen, dann ist der Gültigkeitszeitraum bereits
                vorbelegt oder sie wählen die Option "freie Zeiteintragung". Dann muss auch kein Enddatum gewählt
                werden. Der vorbelegte Gültigkeitszeitraum sind immer sieben Tage: vom Vortag 18h bis sechs Tage später
                17:59h.
              </p>
              <p>
                Sie können uns Ihre Kollektenpläne auch als Excel-Tabelle zur Verfügung stellen, dort stehen
                Zusatzfelder für die Weiterleitung von Kollekten zur Verfügung. Sie werden nicht veröffentlicht, aber
                dienen der Buchhaltung. Dazu nutzen Sie bitte diese <a @click="download">Vorlage</a> und schicken sie an
                <a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a>
              </p>
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                text
                @click="infoDialog = false"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <!-- Info Dialog END -->
    <v-row justify="center">
      <v-col
        xs="12"
        lg="4"
        xl="2"
        id="left-column"
      >
        <div>
          <v-card>
            <v-card-title class="text-uppercase background-primary white--text text-center">
              <template v-if="displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS">
                Planung {{ year }}
              </template>
              <template v-else>
                Sammlungsarchiv
              </template>
            </v-card-title>
            <v-card-text>
              <div
                v-if="displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS"
                class="d-flex justify-space-between mt-3"
              >
                <v-btn
                  class="px-0"
                  color="primary"
                  text
                  @click="changeYear(previousYear, true)"
                >
                  <v-icon>
                    {{ mdiChevronLeft }}
                  </v-icon>
                  Vorjahr
                </v-btn>
                <v-btn
                  class="px-0"
                  color="primary"
                  text
                  @click="changeYear(nextYear, true)"
                >
                  Folgejahr
                  <v-icon>
                    {{ mdiChevronRight }}
                  </v-icon>
                </v-btn>
              </div>

              <v-text-field
                v-model="collectionDataTableFilterInput"
                append-icon="mdi-magnify"
                label="Sammlungen durchsuchen"
                hide-details
                single-line
                clearable
                class="mb-4"
              />
              <v-list-item-group
                :value="displayMode"
                @change="displayMode = $event"
              >
                <v-list dense>
                  <v-list-item
                    v-if="displayMode === DISPLAY_MODES.ARCHIVED_COLLECTIONS"
                    :value="DISPLAY_MODES.ACTIVE_COLLECTIONS"
                    class="pa-0"
                  >
                    <v-btn
                      color="primary"
                      text
                      class="w-100"
                    >
                      <menu-icon
                        icon="planning"
                        class="mr-2"
                        scale="0.6"></menu-icon>
                      Aktive Sammlungen
                    </v-btn>
                  </v-list-item>
                  <v-list-item
                    v-if="displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS"
                    :value="DISPLAY_MODES.ARCHIVED_COLLECTIONS"
                    class="pa-0"
                  >
                    <v-btn
                      color="primary"
                      text
                      class="w-100"
                    >
                      <v-icon class="mr-2">{{ mdiArchiveSearchOutline }}</v-icon>
                      Sammlungsarchiv
                    </v-btn>
                  </v-list-item>
                </v-list>
              </v-list-item-group>
              <v-list-item-group
                @change="collectionDataTablePage = $event + 1"
                :value="collectionDataTablePage - 1"
                class="overflow-y-auto max-height-250"
              >
                <v-list dense>
                  <v-list-item
                    v-for="(dateRange, pageIndex) in collectionDataDateRangesForPagination"
                    :key="pageIndex"
                    color="primary"
                  >
                    <v-list-item-content>
                      {{ dateRange }}
                    </v-list-item-content>
                  </v-list-item>
                </v-list>
              </v-list-item-group>

              <div class="d-flex justify-end mt-3">
                <v-btn
                  small
                  elevation="2"
                  fab
                  color="primary"
                  class="excelButton ml-2"
                  @click.stop="download"
                >
                  <v-icon>mdi-microsoft-excel</v-icon>
                </v-btn>
                <v-btn
                  small
                  elevation="2"
                  fab
                  color="primary"
                  class="settingsButton ml-2"
                  @click.stop="infoDialog = true"
                >
                  <v-icon>mdi-help</v-icon>
                </v-btn>
              </div>
            </v-card-text>
          </v-card>
        </div>

        <template v-if="false">
          <v-date-picker
            v-if="false"
            class="calendar"
            v-model="picker"
            :first-day-of-week="1"
            locale="de-DE"
            :year="year"
            min="2021"
            @click:date="selectedDay"
            @click:year="changeYear(true)"
            color="primary"
          />
        </template>
      </v-col>
      <v-col cols="12" md="12" lg="8" xl="10" class="right-column">

        <div id="plans" v-if="!loadDone" align="center">
          <v-progress-circular
            class="progress-circle"
            indeterminate
            size="128"
            :width="7"
            color="primary"
          ></v-progress-circular>
        </div>

        <div id="plans" v-else>

          <template>
            <v-sheet
              elevation="0"
              outlined
              max-width="100%"
            >

              <v-slide-group
                v-model="devicefilter"
                mandatory
                center-active
                show-arrows
              >
                <v-spacer></v-spacer>
                <v-slide-item
                  v-for="(value, device_class) in deviceChooser"
                  :key="device_class"
                  v-slot="{ active, toggle }"
                  :value="device_class"
                  class="ma-3"
                >
                  <v-btn
                    :color="active ? 'primary' : ''"
                    elevation="0"
                    text
                    @click="toggle"
                    class=""
                  >{{ value }}
                  </v-btn>
                </v-slide-item>
                <v-spacer></v-spacer>
              </v-slide-group>
            </v-sheet>
          </template>
          <section v-if="displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS">
            <div
              class="d-flex justify-lg-space-between align-center">
              <h3>Aktive Sammlungen</h3>
              <div>
                <v-btn text color="primary" class="expandButton"  @click="toggleActiveCollections" v-if="showActiveCollection">Einklappen</v-btn>
                <v-btn text color="primary" class="expandButton"  @click="toggleActiveCollections" v-else>Ausklappen</v-btn>
              </div>
            </div>
            <div v-if="displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS">
              <v-btn
                @click="openPlanEditor('new')"
                color="primary"
                outlined
                block
              >
                <v-icon>{{ mdiPlus }}</v-icon>
                Neue Sammlung eintragen
              </v-btn>
            </div>
            <div class="active-collections" v-if="showActiveCollection">
              <collection-card
                v-for="collection in activeCollections"
                :key="collection.id + '_active'"
                :collection="collection"
                :current-organization="organization"
                :displayed-date="null"
                :more-infos="true"
                :relevant-devices="relevantDevices"
                :device-filter="devicefilter"
                :show-prio-chooser="showPrioChooser"
                :dialog="openDetailsDialog"
                :is-eligible-for-collection-plan-group="collection.isEligibleForCollectionPlanGroup"
                :is-collection-plan-group-candidate="isCollectionPlanGroupCandidate(collection)"
                :notification-contacts="notificationContacts"
                @close-collection="askClose(collection)"
                @edit-collection="openPlanEditor('edit', collection)"
                @duplicate-collection="openPlanEditor('duplicate', collection)"
                @duplicate-collection-plan-group="duplicateCollectionPlanGroup(collection)"
                @exclude-collection="askExclude(collection)"
                @mark-as-collection-group-candidate="onMarkAsCollectionGroupCandidate"
                @send-qr-notification="openSendQrDialog(collection)"
              ></collection-card>
              <v-btn
                v-if="relevantCollectionsByDate && this.DISPLAY_MODES.ACTIVE_COLLECTIONS"
                @click="openPlanEditor('new')"
                color="primary"
                block
                outlined
                class="my-7"
              >
                <v-icon>{{ mdiPlus }}</v-icon>
                Neue Sammlung eintragen
              </v-btn><!-- TODO: only display this second button when there are active collections -->
            </div>
          </section>

        </div>

        <h3 v-text="displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS ? 'Alle Sammlungen' : 'Archivierte Sammlungen'" />

        <v-data-table
          ref="collectionDataTable"
          :headers="collectionsByDateHeaders"
          :items="collectionsByDate"
          hide-default-header
          :page="collectionDataTablePage"
          @update:page="collectionDataTablePage = $event"
          :items-per-page="collectionDataTablePageSize"
          @update:items-per-page="collectionDataTablePageSize = $event"
        >
          <template #item.collections="{ item }">
            <div class="mt-3 mb-6">
              <div class="d-flex align-center">
                <v-tooltip
                  v-if="canAddCollectionForDate(item.date)"
                  bottom
                >
                  <template #activator="{ on, attrs }">
                    <v-btn
                      @click="openPlanEditor('new', null, item.date.url )"
                      class="mr-2"
                      color="primary"
                      icon
                      outlined
                      small
                      v-on="on"
                      v-bind="attrs"
                    >
                      <v-icon>{{ mdiPlus }}</v-icon>
                    </v-btn>
                  </template>
                  <span>Neue Sammlung an {{ item.date.name }}</span>
                </v-tooltip>
                <h3
                  class="day-name"
                  :ref="item.date.dateFmt"
                >
                  <span v-if="item.date.name" :id="item.date.day">
                    {{ item.date.dateFmt }}, {{ item.date.name }}
                  </span>
                  <span v-else>
                    {{ item.date.dateFmt }}
                  </span>
                </h3>
              </div>
              <collection-card
                v-for="collection in item.collections"
                :key="collection.id"
                class="my-2"
                :collection="collection"
                :current-organization="organization"
                :device-filter="devicefilter"
                :more-infos="true"
                :relevant-devices="relevantDevices"
                :displayed-date="item.date"
                :show-prio-chooser="showPrioChooser"
                :dialog="openDetailsDialog"
                :is-eligible-for-collection-plan-group="collection.isEligibleForCollectionPlanGroup"
                :is-collection-plan-group-candidate="isCollectionPlanGroupCandidate(collection)"
                :notification-contacts="notificationContacts"
                :is-archived="displayMode === DISPLAY_MODES.ARCHIVED_COLLECTIONS"
                @close-collection="askClose(collection)"
                @duplicate-collection="openPlanEditor('duplicate', collection)"
                @duplicate-collection-plan-group="duplicateCollectionPlanGroup(collection)"
                @edit-collection="openPlanEditor('edit', collection)"
                @exclude-collection="askExclude(collection)"
                @mark-as-collection-group-candidate="onMarkAsCollectionGroupCandidate"
                @unmark-as-collection-group-candidate="onUnmarkAsCollectionGroupCandidate"
                @send-qr-notification="openSendQrDialog(collection)"
              ></collection-card>
            </div>
          </template>
          <template #footer.page-text>
            {{ collectionDataTableFooter }}
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <base-dialog
      v-if="editCollection"
      :persistent="!currentCollectionStarted"
      :is-open="isDetailsDialogOpen"
      :max-width="800"
      @close="isDetailsDialogOpen = false"
    >
      <template #dialog-title>
        <v-icon color="primary" class="mr-2">mdi-information</v-icon>
        <span>
          {{ editCollection.name }}
        </span>
      </template>

      <template #dialog-content>
        <collection-plan-details
          :collection="editCollection"
          :relevant-devices="relevantDevices"
          :organization="organization"
          :is-saving-in-progress="savingDetailDescription"
          @cancel="isDetailsDialogOpen = false"
          @confirm="isDetailsDialogOpen = false"
          @save="saveDetails"
        ></collection-plan-details>
      </template>
    </base-dialog>
    <base-dialog
      :is-open="!!sendQrCollection"
      :max-width="800"
      @close="sendQrCollection = null"
    >
      <template #dialog-title>
        Benachrichtigungsmail wirklich versenden?
      </template>
      <template #dialog-text>
        <div>
          {{ $tc('organization.notificationContactsMessage', notificationContacts.length, { count: notificationContacts.length }) }}
        </div>
        <ul class="mt-2">
          <li
            v-for="contact in notificationContacts"
            :key="contact.id"
          >
            {{ contact.name }} &lt;{{ contact.email }}&gt;
          </li>
        </ul>
      </template>
      <template #dialog-actions>
        <v-btn
          text
          color="primary"
          @click="sendQrCollection = null"
        >
          Nein, abbrechen
        </v-btn>
        <v-btn
          color="primary"
          @click="sendQrNotification"
        >
          Ja, versenden
        </v-btn>
      </template>
    </base-dialog>
  </v-container>
</template>
<script>
import moment from 'moment'
import 'moment/locale/de'
import Vue from 'vue'
import CollectionPlanEditor from '@/components/collection/CollectionPlanEditor.vue'
import CollectionCard from '@/components/collection/CollectionCard'
import CollectionPlanDetails from "@/components/collection/CollectionPlanDetails"
import BaseDialog from "@/components/UI/BaseDialog"
import { SweetModal } from 'sweet-modal-vue'
import { mapGetters, mapState } from 'vuex'
import {
  FETCH_DATES_FOR_ORGANIZATION,
  FETCH_DATES_GLOBAL, FETCH_DEVICES, FETCH_GLOBAL_DEVICES,
  FETCH_LOCATIONS,
  FETCH_ORGANIZATION_COLLECTION_PLANS,
  PATCH_COLLECTION_PLAN, PRE_ANNOUNCE_COLLECTIONS,
  UPDATE_COLLECTION_PLAN
} from '@/store/action-types'
import { UPDATE_TIME } from '@/modules/common/store/action-types'
import { loadingStateWrapper } from '@/modules/common/store/tools'
import { DEVICE_CLASSES, entryForDeviceType } from '@/lib/device-db'
import {
  mdiChevronLeft,
  mdiChevronRight,
  mdiPlus,
  mdiStop,
  mdiArchiveSearchOutline
} from "@mdi/js"
import { FEATURE_FLAG_KODI_392_SHOW_COLLECTION_PLAN_GROUP_EDITOR } from "@/feature-flags"
import { debounce } from "@/lib/debounce"
import MenuIcon from "@/components/icons/MenuIcon.vue"

function sleep (ms) {
  return new Promise(resolve => setTimeout(resolve, ms))
}

const initialDate = new Date().toISOString().slice(0, 10)

const DISPLAY_MODES = {
  ACTIVE_COLLECTIONS: 'activeCollections',
  ARCHIVED_COLLECTIONS: 'archivedCollections'
}

export default {
  name: 'organization-plans',
  components: {
    MenuIcon,
    BaseDialog,
    CollectionPlanEditor,
    SweetModal,
    CollectionCard,
    CollectionPlanDetails
  },
  data () {
    return {
      FEATURE_FLAG_KODI_392_SHOW_COLLECTION_PLAN_GROUP_EDITOR,
      hiddenDays: {},
      confirmCloseCollection: null,
      collectionPlanEditorIsOpen: false,
      collectionPlanGroupCandidates: [],
      confirmExclude: false,
      confirmExcludeCollection: null,
      loadDone: false,
      iconMap: {},
      infoDialog: false,
      isPlanDialogOpen: false,
      dateTooltip: true,
      attachto: 'body',
      tooltipActive: false,
      positionx: 0,
      positiony: 0,
      devicefilter: [],
      contextMenuItems: [
        {
          text: 'Gehe zu...',
          click: evt => {
            this.currentId = evt.id
            this.currentStartDate = evt.startDate
            this.currentEndDate = evt.endDate
            this.currentName = evt.name
            this.currentLocation = evt.location
            this.show = true
            // console.log("Jump to " + evt.day)
          }
        }
      ],
      isDetailsDialogOpen: false,
      sendQrCollection: null,
      editCollection: null,
      detailDescription: '',
      detailDescriptionRules: [v => v.length <= 1000 || 'max. 1000 Zeichen'],
      savingDetailDescription: false,
      showSavingDetailDescriptionToast: false,
      showActiveCollection: true,
      picker: initialDate,
      hasPickerBeenInitialized: false,
      collectionDataTablePage: 1,
      collectionDataTablePageSize: 10,
      collectionDataTableCurrentItems: [],
      collectionDataTableFilterInput: '',
      collectionDataTableFilterSearch: '',
      mdiChevronLeft,
      mdiChevronRight,
      mdiPlus,
      mdiStop,
      mdiArchiveSearchOutline,
      DISPLAY_MODES,
      displayMode: DISPLAY_MODES.ACTIVE_COLLECTIONS,
    }
  },
  beforeRouteEnter (to, from, next) {
    if (to.name === 'organization.plans' && !to.params.year) {
      next({ ...to, params: { ...to.params, year: moment().format('YYYY') } })
    } else {
      next()
    }
  },
  computed: {
    ...mapGetters('organization', {
      organization: 'currentOrganization',
      organizationSettingsByUrl: 'organizationSettingsByUrl',
      allOrganizations: 'allOrganizations',
      organizationParents: 'parents',
    }),
    ...mapState(['loading']),
    ...mapGetters('location', { locations: 'locationsLookup' }),
    ...mapGetters('collectionPlan', {
      collectionPlans: 'currentCollectionPlans',
      collectionPlanGroupsByUrl: 'collectionPlanGroupsByUrl'
    }),
    ...mapGetters('date', ['globalDatesByUrl', 'datesListForUrl']),
    year () {
      return this.$store.state.route.params.year ?? moment().format('YYYY')
    },
    previousYear () {
      return parseInt(this.year) - 1
    },
    nextYear () {
      return parseInt(this.year) + 1
    },
    deviceChooser () {
      const resVar = { all: 'Alle Geräte' }
      this.relevantDevices.forEach((deviceType) => {
        const deviceCLass = entryForDeviceType(deviceType)?.deviceClass ?? 'UNK'
        resVar[deviceCLass] = DEVICE_CLASSES[deviceCLass] ?? 'Unbekannte Geräte'
      })
      return resVar
    },
    yearRightColumn: {
      get: function () {
        const index = this.yearsList.indexOf(this.$store.state.route.params.year ?? moment().format('YYYY'))
        return index > -1 ? index : 0
      },
      set: function (newValue) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          params: { ...this.$router.currentRoute.params, year: this.yearsList[newValue] }
        })
      }
    },
    relevantDevices () {
      return this.$store.getters['organization/organizationRelevantDeviceTypesForUrl'](this?.organization?.url ?? null)
    },
    relevantCollectionPlans () {
      if (!this.collectionPlans || !this.loadDone) {
        return []
      }
      const now = moment().subtract(30, 'days')

      if (this.displayMode === DISPLAY_MODES.ARCHIVED_COLLECTIONS) {
        return this.collectionPlans
          .filter(plan => plan.end && moment(plan.end) <= now)
          .map(plan => {
            return {
              ...plan,
              sortedTargets: plan.targets.sort().join('|')
            }
          })
      }

      return this.collectionPlans
        .filter(plan => (!plan.end || moment(plan.end) > now))
        .map(plan => {
          return {
            ...plan,
            sortedTargets: plan.targets.sort().join('|')
          }
        })
    },
    showPrioChooser () {
      if (this.devicefilter === 'all') {
        return false
      }
      // FIXME: Add information on whether the device understands priorities to device-db
      //  (also change usage in CollectionCard (savePrioExtension() etc.)
      return this.devicefilter === 'WS' || this.devicefilter === 'WA' || this.devicefilter === 'SP'
    },
    relevantCollectionsByDate () {
      if (!this.collectionPlans || !this.loadDone) {
        return null
      }
      moment.locale('de')
      const now_ = this.$store.state.now
      const result = {}
      for (const cplanOrig of this.relevantCollectionPlans) {
        if (!(this.devicefilter === 'all' || cplanOrig.targets.includes(this.devicefilter))) {
          continue
        }
        const cplan = { ...cplanOrig }

        let validPlanByItsLocation = false
        if (cplan.organization === null) {
          // This plan is probablyconnected to a location. Check if the location belongs to this organization
          if (cplan.location === null) {
            // Its not :(
            continue
          } else {
            // It is :)
            if (this.organization.url === this.locations[cplan.location].organization) {
              validPlanByItsLocation = true
            }
          }
        }

        if (!(this.organization.url === cplan.organization || cplan.inherit || validPlanByItsLocation)) {
          continue
        }

        const dateObj = this.globalDatesByUrl[cplan.date] ?? null
        const dateMoment = dateObj ? moment(dateObj.day) : moment(cplan.start)

        if (
          this.displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS &&
          !this.matchYear(cplan) &&
          dateMoment.year().toString() !== this.year.toString()
        ) {
          continue
        }

        cplan.inactive = !cplan.active
        cplan.currentlyActive = cplan.active && (!cplan.end || moment(cplan.end) > now_) && (now_ >= moment(cplan.start))
        cplan.hasPassed = cplan.active && (cplan.end && moment(cplan.end) <= now_)
        cplan.hasStarted = cplan.active && (now_ >= moment(cplan.start))
        cplan.excludedFor = {
          [this.organization.url]: Array.from(new Set(((cplan?.exclusions ?? []).filter(
            excl => excl.organization === this.organization.url || (excl.inherit && this.organizationParents[this.organization.url].includes(excl.organization))
          ).map(excl => excl.targets) ?? []).flat()))
        }

        const dateFmt = dateMoment.format('DD. MMMM YYYY')
        let locationKey = cplan.location ?? null
        if (locationKey) {
          cplan.locationObj = this.locations[cplan.location] ?? null
          if (!cplan.locationObj) {
            locationKey = null
          }
        }
        if (result[dateFmt]) {
          if (result[dateFmt].locations[locationKey]) {
            result[dateFmt].locations[locationKey].push(cplan)
          } else {
            result[dateFmt].locations[locationKey] = [cplan]
          }
        } else {
          result[dateFmt] = {
            locations: {},
            date: {
              name: dateObj ? dateObj.name : null,
              day: dateMoment,
              url: dateObj?.url ?? null,
              dateFmt
            }
          }
          result[dateFmt].locations[locationKey] = [cplan]
        }
      }

      for (const dateObj of Object.values(this.datesListForUrl(this.organization?.url))) {
        if (
          this.displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS &&
          (!this.matchYear(dateObj) || moment(dateObj.day) < now_)
        ) {
          continue
        }
        const dateMoment = moment(dateObj.day)
        const dateFmt = dateMoment.format('DD. MMMM YYYY')
        if (dateMoment.format('YYYY') === this.year) {
          if (!result[dateFmt]) {
            result[dateFmt] = {
              locations: {},
              date: {
                name: dateObj.name,
                url: dateObj.url,
                day: dateMoment,
                dateFmt
              }
            }
          }
        }
      }

      const retval = Object.values(result).sort((a, b) => {
        if (!b.date || !b.date.day || !a.date || !a.date.day) {
          return 10000
        }
        return a.date.day.diff(b.date.day)
      })

      for (const entry of retval) {
        for (const collections of Object.values(entry?.locations ?? {})) {
          collections.sort(
            (a, b) => (
              a?.start !== b?.start
                ? (a?.start ?? '').localeCompare(b?.start ?? '')
                : (
                    a?.name !== b?.name
                      ? (a?.name ?? '').localeCompare(b?.name ?? '')
                      : (a?.id ?? '').localeCompare(b?.id ?? '')
                  )
            )
          )
        }
      }
      return retval
    },
    collectionsByDateHeaders () {
      return [
        {
          text: 'Sammlungen',
          value: 'collections',
        }
      ]
    },
    effectivePageSize () {
      return this.collectionDataTablePageSize <= 0 ? this.collectionsByDate.length : this.collectionDataTablePageSize
    },
    collectionDataDateRangesForPagination () {
      if (!this.relevantCollectionsByDate || !this.loadDone) {
        return []
      }

      const ranges = []
      for (let start = 0; start < this.collectionsByDate.length; start += this.effectivePageSize) {
        const end = start + this.effectivePageSize
        const currentItems = this.collectionsByDate.slice(
          start,
          end
        )
        const firstItem = currentItems[0]
        const lastItem = currentItems[currentItems.length - 1]
        const from = moment(firstItem?.date?.day).format('DD.MM.YYYY')
        const to = moment(lastItem?.date?.day).format('DD.MM.YYYY')
        const dateRangeHuman = `${from} - ${to}`
        ranges.push(dateRangeHuman)
      }

      return ranges
    },
    collectionDataTableFooter () {
      return this.collectionDataDateRangesForPagination[this.collectionDataTablePage - 1]
    },
    collectionsByDate () {
      if (!this.relevantCollectionsByDate || !this.loadDone) {
        return []
      }

      const entryMap = {}

      this.relevantDates.forEach(relevantDate => {
        relevantDate.dateFmt = moment(relevantDate.day).format('DD. MMMM YYYY')
        const timestamp = moment(relevantDate.day).unix()
        entryMap[timestamp] = {
          date: relevantDate,
          collections: [],
          isHidden: false
        }
      })

      this.relevantCollectionsByDate.forEach(collectionByDateEntry => {
        if (
          this.displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS &&
          collectionByDateEntry.date.day.year() !== parseInt(this.year)
        ) {
          return
        }
        const timestamp = collectionByDateEntry.date.day.unix()

        if (!entryMap[timestamp]) {
          entryMap[timestamp] = {
            date: collectionByDateEntry.date,
            collections: [],
            isHidden: false
          }
        }
        const entry = entryMap[timestamp]
        entry.date.dateFmt = moment(entry.date.day).format('DD. MMMM YYYY')

        if (this.hiddenDays[collectionByDateEntry.date.dateFmt]) {
          entry.isHidden = true
          return
        }

        entry.isEligibleForCollectionPlanGroupCreation = this.checkCollectionPlanGroupCreationEligibility(collectionByDateEntry)

        const collections = []
        Object.values(collectionByDateEntry.locations).forEach((collectionsForDate) => {
          collectionsForDate.forEach((collection) => {
            if (collection.parent) {
              return
            }

            if (this.isCollectionExcluded(collection)) {
              return
            }

            if (this.collectionDataTableFilterSearch && !collection.name.toLowerCase().includes(this.collectionDataTableFilterSearch.toLowerCase())) {
              return
            }

            collection.isEligibleForCollectionPlanGroup = this.checkCollectionPlanGroupEligibility(collection, collectionsForDate)

            collections.push(collection)
          })
        })

        if (collections.length) {
          entry.collections = collections
        }
      })

      return Object.values(entryMap)
        .filter((entry) => {
          if (this.displayMode === DISPLAY_MODES.ARCHIVED_COLLECTIONS) {
            return entry.collections.length > 0
          }
          return true
        })
        .sort((entryA, entryB) => {
          return moment(entryA.date.day).diff(moment(entryB.date.day))
        })
    },
    activeCollections () {
      // TODO: rely on collectionsByDate
      if (!this.relevantCollectionsByDate || !this.loadDone) {
        return []
      }

      const activeCollections = []

      this.relevantCollectionsByDate.forEach(collectionByDateEntry => {
        if (this.hiddenDays[collectionByDateEntry.date.dateFmt]) {
          return
        }

        Object.values(collectionByDateEntry.locations).forEach((collectionsForDate) => {
          collectionsForDate.forEach((collection) => {
            if (!collection.currentlyActive) {
              return
            }

            if (collection.parent) {
              return
            }

            if (this.isCollectionExcluded(collection)) {
              return
            }

            collection.isEligibleForCollectionPlanGroup = this.checkCollectionPlanGroupEligibility(collection, collectionsForDate)

            activeCollections.push(collection)
          })
        })
      })
      return activeCollections
    },
    datesForPlanEditor () {
      return Object.fromEntries(this.datesListForUrl(this.organization?.url).map(
        date => [date.url, date]
      ))
    },
    relevantDates () {
      const now_ = this.$store.state.now
      const entries = []
      for (const dateObj of Object.values(this.datesListForUrl(this.organization?.url))) {
        if (
          this.displayMode === DISPLAY_MODES.ACTIVE_COLLECTIONS &&
          (!this.matchYear(dateObj) || moment(dateObj.day) < now_)
        ) {
          continue
        }

        entries.push(dateObj)
      }

      return entries
    },
    yearsList () {
      const retval = new Set()
      if (!this.collectionPlans || !this.loadDone) {
        return []
      }
      this.relevantCollectionPlans.forEach((cplan) => {
        if (cplan.start && cplan.end) {
          retval.add(new Date(cplan.start).getFullYear())
          retval.add(new Date(cplan.end).getFullYear())
        }
      })
      const arrRes = [...retval].sort()
      if (arrRes.length > 0) {
        arrRes.unshift(arrRes[0] - 1)
        arrRes.push(arrRes[arrRes.length - 1] + 1)
      }
      return arrRes
    },
    calendarStart () {
      return new Date(moment().startOf('year').subtract({ years: 1 }).format())
    },
    currentCollectionStarted () {
      if (this.editCollection) {
        return this.editCollection.currentlyActive || this.editCollection.hasPassed
      }
      return false
    },
    organizationSettings () {
      return this.$store.getters['organization/organizationSettingsByUrl'][this.organization?.url ?? null] ?? {}
    },
    isCollectionSplitEnabled () {
      // eslint-disable-next-line camelcase
      return this.organizationSettings?.features?.allow_collection_split ?? false
    },
    notificationContacts () {
      const contacts = this.organization?.contacts ?? []

      return contacts.filter(contact => contact.types.includes('announce_collection'))
    }
  },
  created () {
  },
  async mounted () {
    await Promise.allSettled([
      this.$store.dispatch('location/' + FETCH_LOCATIONS),
      this.$store.dispatch('date/' + FETCH_DATES_GLOBAL),
      this.$store.dispatch('date/' + FETCH_DATES_FOR_ORGANIZATION, this.organization.url),
      this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES)
    ])
    this.loadDone = true
  },
  methods: {
    async download () {
      const response = await this.$store.getters.restApi(
        `${this.organization.url}kollektenplan_template/`, {
          responseType: 'blob'
        }
      )
      const contentType = response.headers['content-type']
      const link = document.createElement('a')
      link.href = window.URL.createObjectURL(new Blob([response.data], { type: contentType }))
      link.download = `${this.organization.id}-${this.organization.name}-kollektenplan.xlsx`
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
    },
    toggleVisibility (s) {
      let current = this.hiddenDays[s]
      if (current === undefined) current = false
      Vue.set(this.hiddenDays, s, !current)
    },
    async openPlanEditor (mode, collection = null, dateUrl = null) {
      this.isPlanDialogOpen = true
      await this.$nextTick()

      await this.$refs.planEditor.init({
        mode,
        collection,
        dateUrl
      })
    },
    async openCollectionPlanGroupEditor () {
      this.isPlanDialogOpen = true
      await this.$nextTick()

      await this.$refs.planEditor.init({
        mode: 'group',
        groupCandidates: this.collectionPlanGroupCandidates,
        dateUrl: this.collectionPlanGroupCandidates[0].date
      })
    },
    async duplicateCollectionPlanGroup (collectionPlanGroup) {
      this.isPlanDialogOpen = true
      await this.$nextTick()

      const groupCandidates = this.collectionPlanGroupsByUrl[collectionPlanGroup.url]

      await this.$refs.planEditor.init({
        mode: 'group-duplicate',
        groupCandidates,
        dateUrl: groupCandidates[0].date
      })
    },
    closePlanEditor (value) {
      this.isPlanDialogOpen = false
    },
    askClose (collection) {
      this.confirmCloseCollection = collection
      this.$refs.confirmClose.open()
    },
    askExclude (collection) {
      this.confirmExcludeCollection = collection
      this.confirmExclude = true
    },
    async doClose (collection) {
      try {
        const endedCollection = await this.$store.dispatch('collectionPlan/' + PATCH_COLLECTION_PLAN, {
          url: this.confirmCloseCollection.url,
          data: {
            end: 'now'
          }
        })

        if (this.isCollectionPlanGroupParent(this.confirmCloseCollection.url)) {
          const childUrlsToCloseCalls = this.collectionPlanGroupsByUrl[this.confirmCloseCollection.url].map(childCollection => {
            return this.$store.dispatch('collectionPlan/' + PATCH_COLLECTION_PLAN, {
              url: childCollection.url,
              data: {
                end: endedCollection.end
              }
            })
          })

          await Promise.allSettled(childUrlsToCloseCalls)
        }

        await sleep(1500)
        await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url)
        await this.$store.dispatch(UPDATE_TIME)
      } finally {
        this.confirmCloseCollection = null
        this.$refs.confirmClose.close()
      }
    },
    async doExcludeCollection (collection) {
      try {
        await loadingStateWrapper(
          this.$store,
          async () => {
            const retval = await this.$store.getters.restApi.post('collection_plan_exclusion/', {
              plan: collection.url,
              location: null,
              organization: this.organization.url,
              inherit: true,
              targets: Array.from(Object.keys(DEVICE_CLASSES))
            })
            await sleep(1500)
            return retval
          }
        )
        await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url)
        await this.$store.dispatch(UPDATE_TIME)
      } finally {
        this.confirmExclude = false
        await this.$nextTick()
        this.confirmExcludeCollection = null
      }
    },
    calendarRenderer (element, date, events) {
      let level = 0
      events.forEach(event => {
        const org = event.plan?.organization ?? null
        if (org && org !== this.organization.url) {
          level = Math.max(level, 1)
        } else {
          level = Math.max(level, 2)
        }
      })
      element.classList.remove('date-taken-sup', 'date-taken-own')
      if (level === 2) {
        element.classList.add('date-taken-own')
      } else if (level === 1) {
        element.classList.add('date-taken-sup')
      }
    },
    changeYear (year, shouldResetPicker = false) {
      if (year.toString() !== this.year.toString()) {
        this.$router.push({
          name: this.$router.currentRoute.name,
          params: { ...this.$router.currentRoute.params, year }
        })

        if (shouldResetPicker) {
          this.picker = `${year}-01-01`
        }
      }
    },
    dayClick (e) {
    },
    dayMouseover (e) {
      // console.log(e)
      // console.log(e.orievent.pageX)
      // console.log(e.orievent.pageY)
      this.tooltipActive = true
      this.positionx = e.orievent.pageX
      this.positiony = e.orievent.pageY
      // this.attachto = e.orievent.target
    },
    matchYear (obj) {
      let accepted = false
      if (!obj.start && !obj.end) accepted = true
      else if (obj.start && obj.end) {
        if (moment(obj.start).year() <= parseInt(this.year) && parseInt(this.year) <= moment(obj.end).year()) {
          accepted = true
        }
      } else if (obj.start && !obj.end) {
        if (moment(obj.start).year() <= parseInt(this.year)) accepted = true
      } else if (!obj.start && obj.end) {
        if (moment(obj.end).year() >= parseInt(this.year)) accepted = true
      }
      return accepted
    },
    canAddCollectionForDate (date) {
      const now_ = this.$store.state.now

      if (this.displayMode === DISPLAY_MODES.ARCHIVED_COLLECTIONS) {
        return false
      }

      if (!date.name) {
        return false
      }

      return moment(date.day).isAfter(now_)
    },
    openDetailsDialog (eC) {
      this.editCollection = eC
      this.detailDescription = eC.meta ? eC.meta.description ? eC.meta.description.text ? eC.meta.description.text : '' : '' : ''
      this.isDetailsDialogOpen = true
    },
    async saveDetails ({ detailDescription, allowRecurringDonation }) {
      if (detailDescription) {
        this.detailDescription = detailDescription
        if (this.editCollection.meta) {
          if (this.editCollection.meta.description) {
            this.editCollection.meta.description.text = this.detailDescription
          } else {
            this.editCollection.meta.description = {
              text: this.detailDescription
            }
          }
        } else {
          this.editCollection.meta = {
            description: {
              text: this.detailDescription
            }
          }
        }
      }

      this.savingDetailDescription = true
      if (detailDescription) {
        await this.$store.dispatch('collectionPlan/' + UPDATE_COLLECTION_PLAN, this.editCollection)
      }
      if (allowRecurringDonation) {
        await this.$store.dispatch('collectionPlan/' + PATCH_COLLECTION_PLAN, {
          url: this.editCollection.url,
          data: {
            allow_recurring: allowRecurringDonation
          }
        })
      }
      this.savingDetailDescription = false
      this.isDetailsDialogOpen = false
      // await sleep(1500)
      await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url)
      await this.$store.dispatch(UPDATE_TIME)
      this.unsetEditCollection()
      this.showSavingDetailDescriptionToast = true
    },
    unsetEditCollection () {
      this.editCollection = null
      this.detailDescription = ''
    },
    toggleActiveCollections () {
      this.showActiveCollection = !this.showActiveCollection
    },
    selectedDay (event) {
      // TODO: KODI-484 - Make sure correct page is being displayed, then scroll into view
      const year = moment(event).format('YYYY')

      if (year.toString() !== this.year.toString()) {
        this.changeYear(year)
        return
      }

      const selectedDate = moment(event).format('YYYYMMDD')
      const availableDates = this.collectionsByDate.map(e => e.date.day.format('YYYYMMDD'))

      const closestDateIndex = availableDates.findIndex(e => e >= selectedDate)
      this.collectionDataTablePage = Math.floor(closestDateIndex / this.$refs.collectionDataTable.itemsPerPage)
      this.$refs.collectionDataTable.$el
        .scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' })
    },
    checkCollectionPlanGroupEligibility (collection, collectionsForDate) {
      if (!this.isCollectionSplitEnabled) {
        return false
      }

      if (this.collectionPlanGroupsByUrl[collection.url] && this.collectionPlanGroupsByUrl[collection.url].length) {
        return false
      }

      if (moment().isAfter(moment(collection.start))) {
        return false
      }

      let isEligible = false

      for (const collectionForDate of collectionsForDate) {
        if (
          collectionForDate.start === collection.start &&
          collectionForDate.end === collection.end &&
          collectionForDate.organization === collection.organization &&
          collectionForDate.location === collection.location &&
          collectionForDate.sortedTargets === collection.sortedTargets &&
          collectionForDate.allow_recurring === collection.allow_recurring
        ) {
          isEligible = true
          break
        }
      }
      return isEligible
    },
    checkCollectionPlanGroupCreationEligibility (collectionDateEntry) {
      let eligibleCount = 0

      for (const collectionsForDate of Object.values(collectionDateEntry.locations)) {
        for (const collection of collectionsForDate) {
          if (
            this.checkCollectionPlanGroupEligibility(collection, collectionsForDate) &&
            this.isCollectionPlanGroupCandidate(collection)
          ) {
            eligibleCount += 1
          }
        }
      }

      return eligibleCount >= 2
    },
    isCollectionPlanGroupCandidate (collection) {
      return !!this.collectionPlanGroupCandidates.find(candidate => candidate.url === collection.url)
    },
    validateCollectionGroupCandidates () {
      if (this.collectionPlanGroupCandidates.length <= 1) {
        return true
      }

      const startDates = []
      const endDates = []

      const organizations = []
      const locations = []
      const targets = []

      this.collectionPlanGroupCandidates.forEach(candidate => {
        startDates.push(candidate.start)
        endDates.push(candidate.end)
        organizations.push(candidate.organization)
        locations.push(candidate.location)
        targets.push(candidate.targets.sort().join('|'))
      })

      if (
        new Set(startDates).size > 1 ||
        new Set(endDates).size > 1 ||
        new Set(organizations).size > 1 ||
        new Set(locations).size > 1 ||
        new Set(targets).size > 1
      ) {
        this.collectionPlanGroupCandidates.shift()
        this.validateCollectionGroupCandidates()
      }
    },
    onMarkAsCollectionGroupCandidate (collection) {
      if (this.isCollectionPlanGroupCandidate(collection)) {
        return
      }

      this.collectionPlanGroupCandidates.push(collection)
      this.validateCollectionGroupCandidates()
    },
    onUnmarkAsCollectionGroupCandidate (collection) {
      this.collectionPlanGroupCandidates = this.collectionPlanGroupCandidates.filter(candidate => candidate.url !== collection.url)
    },
    isCollectionPlanGroupParent (collectionUrl) {
      return !!this.collectionPlanGroupsByUrl[collectionUrl]
    },
    isCollectionExcluded (collection) {
      if (collection.excludedFor[this.organization.url]?.length) {
        return true
      }

      return (
        collection.organization &&
        collection.organization !== this.organization.url &&
        this.organization?.flags.includes('no_inherit_collections')
      )
    },
    openSendQrDialog (collection) {
      this.sendQrCollection = collection
    },
    async sendQrNotification () {
      await this.$store.dispatch('collectionPlan/' + PRE_ANNOUNCE_COLLECTIONS, {
        collectionId: this.sendQrCollection.id,
        organizationUrl: this.organization.url
      })
      this.sendQrCollection = null
    }
  },
  watch: {
    collectionDataTablePage (_newValue) {
      this.showActiveCollection = false
    },
    collectionDataTableFilterInput: debounce(function (newValue) {
      this.collectionDataTableFilterSearch = newValue
    }),
    organization: {
      immediate: true,
      async handler (newVal) {
        if (newVal?.url) {
          await Promise.allSettled([
            this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, newVal.url),
            this.$store.dispatch('date/' + FETCH_DATES_FOR_ORGANIZATION, newVal.url),
            this.$store.dispatch('device/' + FETCH_DEVICES, newVal?.url)
          ])
        }
      }
    },
    year: {
      immediate: true,
      async handler (newValue) {
        if (this.hasPickerBeenInitialized) {
          return
        }

        if (moment(this.picker).format('YYYY') === newValue.toString()) {
          return
        }

        this.picker = `${newValue}-01-01`
        this.hasPickerBeenInitialized = true
      }
    },
  }
}
</script>
<style lang="stylus" scoped>
.progress-circle
  margin-top: 15%

  .st0 {
    display: none;
  }

  .st1 {
    display: inline;
  }

  .st2 {
    fill: $clr-primary;
  }

  .st3 {
    fill: transparent;
  }

  .st4 {
    fill: transparent;
    stroke: $clr-primary;
    stroke-width: 2;
    stroke-miterlimit: 10;
  }

  .st5 {
    fill: transparent;
    stroke: $clr-primary;
    stroke-width: 4;
    stroke-miterlimit: 10;
  }

  .expandButton
    float: right !important;
    margin-top: 1.5em;
    margin-bottom: 1em;

  .helpButton
    margin-top: 5%;
    margin-right: 15%;

  .excelButton
    margin-top: 10%;
    margin-right: 5%;

  .year-neighbor2
    display: none

  .date-taken-own
    background-color: $clr-primary

  .date-taken-sup
    background-color: #888

#left-column
  margin-top: 4em;
  padding: 0;
.v-picker .v-card > .v-picker__body
  width:255px !important;

.max-height-250
  max-height: 250px

.w-100
  width: 100%
</style>
