<template>
  <v-container id="org-details">
    <v-row>
      <v-col
        cols="12"
        xs="12"
        sm="12"
        md="12"
        lg="12"
        class=""
      >
        <h2>Vertragsdaten</h2>
        <v-row>
          <v-col
            cols="12"
          >
            <h3>{{ organization ? organization.name : '' }}</h3>
            <v-simple-table>
              <tbody>
              <tr
                v-for="key in Object.keys(organization.identifiers)"
                :key="key"
              >
                <th>{{
                    enumsByName && enumsByName.OrganizationIdentifierType && enumsByName.OrganizationIdentifierType[key] || key
                  }}
                </th>
                <td>{{ organization.identifiers[key] }}</td>
              </tr>
              </tbody>
            </v-simple-table>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <organization-component
              v-if="isSuperuser || hasReadingPermissions"
              :organization="organization"
            ></organization-component>
          </v-col>
          <v-col
            cols="12"
            lg="6"
          >
            <template>
              <h4>Optionen</h4>
              <div
                class="setting-container"
                v-if="isDeviceInOrganizationHierarchy(organization, 'john', 'enableWA')"
              >
                <v-switch
                  v-model="isKollekteAppEnabled"
                  color="primary"
                  label="kollekte.app"
                  :disabled="!isSuperuser"
                >
                  <template #label>
                    <v-btn
                      icon
                      :color="shouldPassDownSetting('enableWA', 'override') ? 'primary' : ''"
                      @click.stop="changeInheritSetting('enableWA', 'override')"
                      :disabled="!isSuperuser"
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    kollekte.app
                  </template>
                </v-switch>
              </div>
              <div
                class="setting-container"
                v-if="isDeviceInOrganizationHierarchy(organization, 'bart', 'enableWS')"
              >
                <v-switch
                  @click.native.stop
                  v-model="isSpendeAppEnabled"
                  color="primary"
                  label="spende.app"
                  :disabled="!isSuperuser"
                >
                  <template #label>
                    <v-btn
                      icon
                      :color="shouldPassDownSetting('enableWS', 'override')? 'primary' : ''"
                      @click.stop="changeInheritSetting('enableWS', 'override')"
                      :disabled="!isSuperuser"
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    spende.app
                  </template>
                </v-switch>
              </div>
              <div
                class="setting-row setting-container"
                v-for="setting in settings"
                :key="setting.id"
              >
                <v-switch
                  color="primary"
                  :label="setting.label"
                  v-model="setting.value"
                  @change="changeLocalSetting(setting)"
                  :disabled="!isSuperuser"
                >
                  <template #label>
                    <!-- TODO: extract color state to computed property -->
                    <v-btn
                      icon
                      :color="shouldPassDownSetting(setting.id) ? 'primary' : ''"
                      @click.stop="changeInheritSetting(setting.id)"
                      :disabled="!isSuperuser"
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    {{ setting.label }}
                  </template>
                </v-switch>
              </div>
              <div class="setting-container">
                <v-switch
                  color="primary"
                  label="Anonyme Spenden verbieten"
                  v-model="arePersonalDataRequiredForDonation"
                  @change="changeLocalSetting({id: 'require_pdata', value: arePersonalDataRequiredForDonation})"
                  :disabled="!isSuperuser"
                >
                  <template #label>
                    <v-btn
                      icon
                      :color="shouldPassDownSetting('require_pdata') ? 'primary' : ''"
                      @click.stop="changeInheritSetting('require_pdata')"
                      :disabled="!isSuperuser"
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    Anonyme Spenden verbieten
                  </template>
                </v-switch>
              </div>
              <div class="setting-container">
                <v-switch
                  disabled
                  class="disable-events"
                  color="primary"
                  label="Spendenbescheinigung"
                  v-model="isDonationReceiptEnabled"
                >
                  <template #label>
                    <v-btn
                      icon
                      :color="shouldPassDownSetting('request_donation_receipt') ? 'primary' : ''"
                      disabled
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    Spendenbescheinigung
                  </template>
                </v-switch>
                <div
                  v-if="donationReceiptConfigurations.length"
                >
                  <div
                    v-for="(donationReceiptConfiguration, index) in donationReceiptConfigurations"
                    :key="index"
                    class="text-caption"
                  >
                    <div>
                      Mindestbetrag: {{ donationReceiptConfiguration.min_amount_donation|amount }}€
                    </div>
                    <div>
                      Gültigkeitszeitraum:
                      <template v-if="donationReceiptConfiguration.valid_from">
                        ab {{ momentize(donationReceiptConfiguration.valid_from) }}
                      </template>
                      <template v-if="donationReceiptConfiguration.valid_notafter">
                        bis {{ momentize(donationReceiptConfiguration.valid_notafter) }}
                      </template>
                      <template v-if="!donationReceiptConfiguration.valid_from && !donationReceiptConfiguration.valid_notafter">
                        Keine Einschränkungen / Angaben
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="setting-container">
                <v-switch
                  disabled
                  color="primary"
                  label="Spendenbescheinigung für Kollekten"
                  v-model="isDonationReceiptForCollectionEnabled"
                >
                  <template #label>
                    <v-btn
                      icon
                      :color="shouldPassDownSetting('donation_receipt_for_collection') ? 'primary' : ''"
                      disabled
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    Spendenbescheinigung für Kollekten
                  </template>
                </v-switch>
                <div
                  v-if="donationReceiptForCollectionConfigurations.length"
                >
                  <div
                    v-for="(donationReceiptConfiguration, index) in donationReceiptForCollectionConfigurations"
                    :key="index"
                    class="text-caption"
                  >
                    <div>
                      Mindestbetrag: {{ donationReceiptConfiguration.min_amount_collection|amount }}€
                    </div>
                    <div>
                      Gültigkeitszeitraum:
                      <template v-if="donationReceiptConfiguration.valid_from">
                        ab {{ momentize(donationReceiptConfiguration.valid_from) }}
                      </template>
                      <template v-if="donationReceiptConfiguration.valid_notafter">
                        bis {{ momentize(donationReceiptConfiguration.valid_notafter) }}
                      </template>
                      <template v-if="!donationReceiptConfiguration.valid_from && !donationReceiptConfiguration.valid_notafter">
                        Keine Einschränkungen / Angaben
                      </template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="setting-container">
                <v-switch
                  disabled
                  color="primary"
                  label="Kollektenvererbung"
                  v-model="collectionFlag"
                >
                  <template #label>
                    <v-btn
                      icon
                      :color="shouldPassDownSetting('no_inherit_collections') ? 'primary' : ''"
                      disabled
                    >
                      <v-icon>{{ mdiTransferDown }}</v-icon>
                    </v-btn>
                    Kollektenvererbung
                  </template>
                </v-switch>
              </div>
              <div v-if="isSuperuser">
                <v-switch
                  disabled
                  color="primary"
                  label="Liturgischer Kalender"
                  v-model="calenderFlag"
                >
                  <template #label>
                    <v-btn
                      disabled
                      icon
                      :color="shouldPassDownSetting('no_inherit_calendar') ? 'primary' : ''"
                    >
                      <v-icon>mdi-transfer-down</v-icon>
                    </v-btn>
                    Liturgischer Kalender
                  </template>
                </v-switch>
              </div>
            </template>
          </v-col>

          <v-col
            v-if="hasValidContract"
            cols="12"
          >
            <h3>Rechnungen</h3>
          </v-col>
          <v-col
            cols="12"
            md="6"
          >
            <v-text-field
              v-model="accountNumber"
              label="Kundennummer"
              outlined
              dense
              :readonly="!isSuperuser"
              :disabled="!isSuperuser"
              @input="hasAccountNumberChanged = true"
              @keydown.enter="updateAccountNumber"
              :append-icon="hasAccountNumberChanged ? mdiCheck : null"
              @click:append="updateAccountNumber"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            v-for="contract in availableContracts"
            :key="contract.id"
            cols="12"
            md="6"
          >
            <organization-contract-billing-card
              :contract="contract"
              :organization="organization"
              @update="loadBillingPlanAssociations"
            />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { FEATURE_TO_SETTINGS_KEY_MAP } from '@/lib/settings-tools'
import moment from "moment"
import { READ_ORG_DATA, WRITE_ORG_DATA } from "@/lib/permission-tools"
import {
  mdiPlus,
  mdiTransferDown,
  mdiCheck
} from "@mdi/js"
import { mapOrder } from "@/lib/data-order"
import OrganizationComponent from "@/components/OrganizationComponent"
import {
  FETCH_BILLING_PLAN_CONTRACT_ASSOCIATION,
  FETCH_BILLING_PLANS, FETCH_CONTRACTS, FETCH_DIRECT_PAYMENT_PAIN_FILES,
  FETCH_GLOBAL_DEVICES,
  FETCH_STATIC_CONFIGURATION, PATCH_ORGANIZATION,
  UPDATE_INHERIT_ORGANIZATION_SETTING,
  UPDATE_LOCAL_ORGANIZATION_SETTING
} from '@/store/action-types'
import { getLatestVersion } from "@/lib/sort-utils"
import { SET_SNACKBAR_MESSAGE } from "@/modules/common/store/mutation-types"
import OrganizationContractBillingCard from "@/components/OrganizationContractBillingCard.vue"

export default {
  name: 'org-details',
  components: {
    OrganizationContractBillingCard,
    OrganizationComponent
  },
  data () {
    return {
      addressesOrder: ['primary', 'physical', 'billing', 'other'],
      mdiPlus,
      mdiTransferDown,
      mdiCheck,
      arePersonalDataRequiredForDonation: false,
      accountNumber: '',
      hasAccountNumberChanged: false,
      billingPlanContractAssociations: {}
    }
  },
  computed: {
    ...mapState(['loading']),
    ...mapState('user', ['user']),
    ...mapState('staticConfiguration', ['enumsByName']),
    ...mapGetters('organization', {
      organization: 'currentOrganization',
    }),
    ...mapGetters('organization', [
      'allOrganizations',
      'organizationSettingsByUrl',
      'organizationRelevantDevicesRawByUrl',
      'parents'
    ]),
    ...mapGetters('settings', {
      effectiveSettings: 'effectiveSettingsForUrl'
    }),
    ...mapGetters('user', ['isSuperuser', 'hasPermission']),
    ...mapGetters('contracts', ['contractsByOrganizationAndDevices', 'contractsByOrganization']),
    ...mapGetters('billing', ['billingPlanByUrl']),
    addresses () {
      if (!this.organization || !this.organization.addresses) {
        return []
      }
      return mapOrder(this.organization.addresses, this.addressesOrder, 'type')
    },
    additionalFields () {
      // eslint-disable-next-line camelcase
      return (this.$store.getters['organization/organizationSettingsByUrl'][this.orgUrl] ?? {})?.accounting?.extra_fields ?? null
    },
    orgUrl () {
      return this.organization.url ?? null
    },
    flags () {
      return this.organization.flags
    },
    calenderFlag () {
      return !this.flags.includes('no_inherit_calendar')
    },
    collectionFlag () {
      return !this.flags.includes('no_inherit_collections')
    },
    features () {
      return this.effectiveSettings(this.orgUrl)?.features ?? {}
    },
    isSuperuser () {
      // eslint-disable-next-line camelcase
      return this.user?.is_superuser || false
    },
    settings () {
      const settingsArray = []
      Object.keys(FEATURE_TO_SETTINGS_KEY_MAP).forEach(key => {
        const settingKey = FEATURE_TO_SETTINGS_KEY_MAP[key]
        const settingValue = this.features[key] || false
        settingsArray.push({
          id: key,
          label: settingKey,
          value: settingValue
        })
      })
      return settingsArray
    },
    donationReceiptConfigurations () {
      const donationReceiptConfigurations = this.organization.donation_receipt_configurations

      if (!donationReceiptConfigurations?.length) {
        return []
      }

      return donationReceiptConfigurations.filter(config => !!config.min_amount_donation)
    },
    donationReceiptForCollectionConfigurations () {
      const donationReceiptConfigurations = this.organization.donation_receipt_configurations

      if (!donationReceiptConfigurations?.length) {
        return []
      }

      return donationReceiptConfigurations.filter(config => !!config.min_amount_collection)
    },
    isDonationReceiptEnabled () {
      if (!this.donationReceiptConfigurations.length) {
        return false
      }

      for (const donationReceiptConfiguration of this.donationReceiptConfigurations) {
        // eslint-disable-next-line camelcase
        if (donationReceiptConfiguration?.min_amount_donation) {
          return true
        }
      }

      // eslint-disable-next-line camelcase
      return this.features?.request_donation_receipt || false
    },
    isDonationReceiptForCollectionEnabled () {
      if (!this.donationReceiptForCollectionConfigurations?.length) {
        return false
      }

      for (const donationReceiptConfiguration of this.donationReceiptForCollectionConfigurations) {
        // eslint-disable-next-line camelcase
        if (donationReceiptConfiguration?.min_amount_collection) {
          return true
        }
      }

      // eslint-disable-next-line camelcase
      return this.features?.donation_receipt_for_collection || false
    },
    hasReadingPermissions () {
      return this.hasPermission(this.organization.url, READ_ORG_DATA)
    },
    hasEditingPermissions () {
      return this.hasPermission(this.organization.url, WRITE_ORG_DATA)
    },
    effectiveOrganizationSettings () {
      return this.$store.getters["settings/effectiveSettingsForUrl"](this.organization.url)
    },
    isSpendeAppEnabled: {
      get () {
        if (this.organization.device_types.includes('bart')) {
          return true
        }

        if (this.effectiveOrganizationSettings?.override?.enableWS !== null) {
          return this.effectiveOrganizationSettings?.override?.enableWS || false
        }

        if (this.isDeviceInOrganizationHierarchy(this.organization, 'bart', 'enableWS')) {
          return true
        }

        return false
      },
      async set (value) {
        const data = {
          settings_local: {
            override: {
              enableWS: value
            }
          }
        }
        await this.$store.getters.restApi.patch(this.organization.url, data)
      }
    },
    isKollekteAppEnabled: {
      get () {
        if (this.organization.device_types.includes('john')) {
          return true
        }

        if (this.effectiveOrganizationSettings?.override?.enableWA !== null) {
          return this.effectiveOrganizationSettings?.override?.enableWA || false
        }

        if (this.isDeviceInOrganizationHierarchy(this.organization, 'john', 'enableWA')) {
          return true
        }

        return false
      },
      async set (value) {
        const data = {
          settings_local: {
            override: {
              enableWA: value
            }
          }
        }
        await this.$store.getters.restApi.patch(this.organization.url, data)
      }
    },
    contracts () {
      return this.contractsByOrganization[this.organization.url]?.validContracts ?? []
    },
    availableContracts () {
      return this.contracts
        .filter(contract => ['op', 'ps'].includes(contract.type))
        .map(contract => {
          let billingPlan = null
          let billingPlanContractAssociation = null
          if (this.billingPlanContractAssociations[contract.id]) {
            billingPlan = this.billingPlanByUrl[this.billingPlanContractAssociations[contract.id].billing_plan]
            billingPlanContractAssociation = this.billingPlanContractAssociations[contract.id]
          }
          return {
            ...contract,
            billingPlan,
            billingPlanContractAssociation
          }
        })
    },
    hasValidContract () {
      return this.contractsByOrganization[this.organization.url]?.hasValidContract ?? false
    },
    billingAddress () {
      if (!this.organization.addresses) {
        return ''
      }
      const billingAddressCandidates = this.organization.addresses.filter(address => address.type === 'billing')
      if (!billingAddressCandidates.length) {
        return ''
      }

      const billingAddress = getLatestVersion(billingAddressCandidates)

      return `${this.organization.name}, ${billingAddress.address}, ${billingAddress.postcode} ${billingAddress.locality}`
    }
  },
  created () {
  },
  methods: {
    changeLocalSetting (setting) {
      this.$store.dispatch('organization/' + UPDATE_LOCAL_ORGANIZATION_SETTING, {
        organization: this.organization,
        setting
      })
    },
    changeInheritSetting (settingId, subscope = 'features') {
      this.$store.dispatch('organization/' + UPDATE_INHERIT_ORGANIZATION_SETTING, {
        organization: this.organization,
        setting: {
          id: settingId,
          value: !this.shouldPassDownSetting(settingId, subscope),
        },
        subscope
      })
    },
    shouldPassDownSetting (settingId, subscope = 'features') {
      return this.organization?.settings_inherit?.[subscope]?.[settingId] ?? false
    },
    isDeviceInOrganizationHierarchy (organization, deviceType, overrideField) {
      const orgaDevices = this.organization.device_types ?? []
      if (orgaDevices.includes(deviceType)) {
        return true
      }

      for (const parentUrl of this.parents[organization.url]) {
        // eslint-disable-next-line camelcase
        const parentDevices = this.allOrganizations[parentUrl]?.device_types ?? []
        // const doesParentPassDownSetting = parentOrg?.settings_inherit?.override?.[overrideField] ?? false
        if (parentDevices.includes(deviceType)) {
          return true
        }
      }
      return false
    },
    myJoiner (arr, sep, map) {
      const retVal = []
      for (const key in arr) {
        const val = arr[key]
        if (map[val]) {
          retVal.push(map[val])
        } else {
          retVal.push(val)
        }
      }
      return retVal.join(sep)
    },
    momentize (date) {
      return moment(date).format('DD.MM.YYYY')
    },
    async loadBillingPlanAssociations () {
      if (!this.isSuperuser) {
        return
      }
      const requests = this.contracts
        .filter(contract => ['op', 'ps'].includes(contract.type))
        .map(contract => {
          return this.$store.dispatch('contracts/' + FETCH_BILLING_PLAN_CONTRACT_ASSOCIATION, {
            contractId: contract.id
          })
        })

      const responses = await Promise.all(requests)

      responses.forEach(assocations => {
        if (!assocations.length) {
          return
        }

        // TODO: Make this pretty as soon we have URLs in the contract payload
        const latestAssociation = getLatestVersion(assocations)
        const contractId = latestAssociation.contract.split('/').filter(Boolean).pop()

        this.$set(this.billingPlanContractAssociations, contractId, latestAssociation)
      })
      return responses
    },
    async updateAccountNumber () {
      await this.$store.dispatch('organization/' + PATCH_ORGANIZATION, {
        url: this.organization.url,
        data: {
          datev_creditor: this.accountNumber
        }
      })
      this.hasAccountNumberChanged = false
      this.$store.commit(SET_SNACKBAR_MESSAGE, {
        message: 'Kundennummer erfolgreich aktualisiert',
        color: 'success'
      })
    }
  },
  watch: {
    features: {
      deep: true,
      immediate: true,
      handler (newVal) {
        if (this.organization?.settings_local?.features?.require_pdata) {
          this.arePersonalDataRequiredForDonation = this.organization?.settings_local?.features?.require_pdata
          return
        }
        // eslint-disable-next-line camelcase
        this.arePersonalDataRequiredForDonation = newVal?.require_pdata || false
      },
    },
    contracts: {
      deep: true,
      async handler () {
        await this.loadBillingPlanAssociations()
      }
    },
    organization: {
      deep: true,
      immediate: true,
      async handler (newVal) {
        this.accountNumber = newVal.datev_creditor
        if (this.contracts.length) {
          await this.loadBillingPlanAssociations()
        }
      }
    }
  },
  async mounted () {
    const promises = [
      this.$store.dispatch('staticConfiguration/' + FETCH_STATIC_CONFIGURATION),
      this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES),
      this.$store.dispatch('contracts/' + FETCH_CONTRACTS),
      this.$store.dispatch('payment/' + FETCH_DIRECT_PAYMENT_PAIN_FILES)
    ]
    if (this.isSuperuser) {
      promises.push(this.$store.dispatch('billing/' + FETCH_BILLING_PLANS))
    }

    await Promise.all(promises)
  }
}
</script>
<style lang="stylus">
#org-details
  .v-list-item__subtitle
    font-size: 1em
    line-height: 1.6
    color: black

  .v-list-item
    padding: 0

  .disable-events {
    pointer-events: none
  }

  .v-input--selection-controls {
    margin-top: 0 !important
  }

  .v-input__control {
    height: 36px
  }

.setting-row
  display: flex
  flex-direction: row

.setting-container
  max-width: fit-content
</style>
