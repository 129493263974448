<template>
  <v-card>
    <v-card-title>
      {{ title }}
    </v-card-title>
    <v-card-text>
      <template v-if="displayType === 'list'">
        <v-data-table
          :headers="headers"
          :items="listData"
          item-key="collectionPlan"
          sort-by="amount"
          sort-desc
        >
          <template #item.amount="{ item }">
            {{ item.amount|amount }} {{ item.currency|currencySymbol}}
          </template>

          <template #item.label="{ item }">
            <span
              class="cursor-pointer"
              @click="setCollectionPlanSelection(item)"
            >{{ item.label }}</span>
          </template>

        </v-data-table>

      </template>

      <template v-else-if="displayType === 'pie'">
        <pie-chart
          :chart-data="pieChartData"
          :options="pieChartOptions"
        ></pie-chart>
      </template>
    </v-card-text>
  </v-card>
</template>

<script>
import PieChart from "@/components/charts/PieChart.vue"
import { pieChartOptions } from "@/config/chart-options"
import StatsTypeTranslationColorizingMixin from "@/mixins/stats-type-translation-colorizing.mixin"
import { mapGetters } from "vuex"

export default {
  name: 'AggregateStatsCard',
  components: {
    PieChart
  },
  mixins: [
    StatsTypeTranslationColorizingMixin
  ],
  props: {
    displayType: {
      type: String,
      required: false,
      default: 'pie',
      validator: function (value) {
        return ['pie', 'list'].includes(value)
      }
    },
    aggregateData: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    aggregateType: {
      type: String,
      required: true
    }
  },
  emits: ['collection-plan-selected'],
  data () {
    return {
      pieChartOptions,
      headers: [
        {
          text: 'Sammlung',
          align: 'start',
          value: 'label'
        },
        {
          text: 'Betrag',
          align: 'end',
          value: 'amount',
          width: '150px'
        }
      ]
    }
  },
  computed: {
    ...mapGetters('collectionPlan', ['collectionPlansLookup', { collectionPlans: 'currentCollectionPlans' }]),
    listData () {
      if (this.displayType !== 'list') {
        return null
      }

      return this.aggregateData.map(entry => {
        return {
          ...entry,
          label: this.collectionPlansLookup[entry.collectionPlan]?.name
        }
      })
    },
    pieChartData () {
      if (this.displayType !== 'pie') {
        return null
      }

      const chartLabels = []
      const chartColors = []
      const chartData = []

      for (const [index, entry] of this.aggregateData.entries()) {
        const key = this.getAggregateKey(entry)
        const label = this.toHuman(entry[key])
        const amount = parseFloat(entry.amount).toFixed(2)
        const currencySymbol = this.$options.filters.currencySymbol(entry.currency)

        const labelLines = `${label} - ${amount} ${currencySymbol} (${entry.payments} Zahlungen)`

        chartLabels.push(labelLines)
        chartColors.push(this.toColor(entry[key], index))
        chartData.push(amount)
      }

      return {
        labels: chartLabels,
        datasets: [
          {
            backgroundColor: chartColors,
            data: chartData
          }
        ]
      }
    },
  },
  methods: {
    setCollectionPlanSelection (collectionPlan) {
      this.$emit('collectionPlanSelected', collectionPlan.collectionPlan)
    },
    getAggregateKey (entry) {
      switch (this.aggregateType) {
        case 'deviceType':
        case 'paymentType':
          return 'id'
        default:
          return this.aggregateType
      }
    },
    toHuman (value) {
      switch (this.aggregateType) {
        case 'deviceType':
          return this.deviceTypeToHuman(value)
        case 'paymentType':
          return this.paymentTypeToHuman(value)
        case 'collectionType':
          return this.$t(`dashboard.collectionType.${value}`) ?? value
        default:
          return value
      }
    },
    toColor (value, index) {
      switch (this.aggregateType) {
        case 'paymentType':
          return this.paymentTypeToColor(value)
        case 'collectionType':
          return this.collectionTypeToColor(value)
        default:
          return this.getColorFromPalette(value, index)
      }
    }
  }
}
</script>
