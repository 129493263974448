<template>

  <v-container id="organization-collections" fluid>
    <div v-if="evalFullscreen">
      <div v-if="printLoad" id="print-load" style="top:0em; bottom: 0em; left:0em; right:0em background: green; height: 15em;">
        <v-progress-circular
          indeterminate
          color="primary"
          style="margin: auto; width: 100%; height: 15em"
        ></v-progress-circular>
      </div>
      <EvaluationStats
        :evalCollectionPlan="evalCollectionPlan"
        :key="evaluationDialog"
      />
    </div>
    <div v-else>
      <v-row v-if="organization && statsByMonth" id="organization-container" justify="center"><!-- class="d-flex flex-row" fluid -->
        <v-col cols="12" md="10" lg="10">
          <v-row justify="center">
            <v-col cols="3" lg="3" xl="2" id="left-column" class="hidden-md-and-down d-print-none" v-if="activeStatsList.length > 0">
              <div v-for="month in monthList" v-bind:key="month.key" :class="activeMonth === month.key ? ['active-month-card', 'month-card'] : ['month-card']" @click="activeMonth = month.key">
                <div class="month-title">{{ month.human }}</div>
                <div class="month-total">{{ month.amount|amount }} {{ organization.currency|currencySymbol }}</div>
              </div>
            </v-col>
            <v-col cols="12" md="10" lg="9" xl="8" id="right-column">
              <v-row justify="center">
                <v-col cols="12" md="12" lg="11">

                  <v-card elevation="0" id="no-entries" class="pa-5" v-if="activeStatsList.length < 1">
                    <p>Hier gibt es noch keine Informationen zum anzeigen. Vermutlich sind noch keine Spenden eingegangen.</p>
                    <p v-if="!recursive">Sie können Ihre Unterorganisationen einschließen.</p>
                  </v-card>

                  <v-card elevation="0" class="pl-5 pb-0">
                    <v-switch v-model="recursive">
                      <template #label>
                        <v-icon
                          dense
                          class="mr-1"
                        >
                          {{ mdiFileTree }}
                        </v-icon>
                        Unterorganisationen einschließen
                      </template>
                    </v-switch>
                    <v-select
                      class="hidden-lg-and-up d-print-none"
                      :menu-props="{ offsetY: true }"
                      label="Monat auswählen"
                      dense
                      outlined
                      hide-details="auto"
                      v-model="activeMonth"
                      :items="monthitems"
                      item-text="name"
                      item-value="id"
                    ></v-select>
                  </v-card>

                  <v-card elevation="0" id="member-list" class="" v-if="activeStatsList.length > 0">

                    <h2>{{ currentMonth }}</h2>
                    <v-card elevation="4" v-if="sumTabGroups.length">
                      <v-tabs fixed-tabs>
                        <v-tab v-for="{name} of sumTabGroups" :key="'t-'+name" style="white-space: nowrap">
                          {{ name }}
                        </v-tab>
                        <v-tab-item v-for="{name, dativ, data} of sumTabGroups" :key="'i-'+name">
                          <div v-for="entry in data" v-bind:key="entry.id">
                            <div class="member-card member-card-overview" style="margin-bottom: 2px;">
                              <div class="member-information">
                                <div class="" style="margin-right: 45px;">
                                  <span class="variant">{{ entry.name }}</span>
                                </div>
                                <div class="member-amount">
                                  <span>{{ entry.sum|amount }} {{ organization.currency|currencySymbol }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div class="member-card member-card-sum">
                              <div class="member-information">
                                <div class="member-sum" style="text-align: right;">
                                  <span class="variant">Summe der {{ dativ }}: {{ activeStatsListFilterSum(data)|amount }} {{ organization.currency|currencySymbol }}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </v-tab-item>
                      </v-tabs>
                    </v-card>
                  </v-card>

                  <div id="member-list" v-if="activeStatsList.length > 0">
                    <h3>Monatsdetails</h3>
                    <v-radio-group
                      v-model="listMode"
                      row
                    >
                      <v-radio
                        label="Übersicht"
                        value="zwecke"
                      ></v-radio>
                      <v-radio
                        label="Detailansicht"
                        value="einzel"
                      ></v-radio>
                      <v-radio
                        label="Tabellarische Ansicht"
                        value="tabelle"
                      ></v-radio>
                    </v-radio-group>

                    <div>
                      <template v-if="listMode === 'zwecke'">
                        Einnahmen werden nach Tag und Sammlung aggregiert dargestellt.
                      </template>
                      <template v-if="listMode === 'einzel'">
                        Jede Einzelzahlung wird mit Zusatzinformationen dargestellt.
                      </template>
                      <template v-if="listMode === 'tabelle'">
                        Tabellarische Übersicht ohne Summierungen.
                      </template>
                    </div>

                    <template v-if="listMode === 'tabelle'">
                      <v-data-table
                        id="detailstable"
                        :headers="detailsTableHeader"
                        :items="singleDonationsTable"
                        sort-by="date"
                        :items-per-page="-1"
                        :sort-desc="true"
                        item-key="id"
                        class="member-table"
                      >
                        <template v-slot:item.icon="{ item }">
                          <v-icon class="px-2" color="primary" v-if="item.collection_plan_object && item.collection_plan_object.type === 'donation'">{{ Spende }}</v-icon>
                          <v-icon class="px-2" color="primary" v-else>{{ Kollekte }}</v-icon>
                        </template>
                        <template v-slot:item.date="{ item }">
                          <div style="white-space: nowrap; text-align: left;">{{ item.dayHuman }} Uhr<!--<br>{{ item.timeHuman }}--></div>
                        </template>
                        <template v-slot:item.collection="{ item }">
                          <v-tooltip v-if="item.collection_plan_object" bottom>
                            <template
                              v-slot:activator="{ on, attrs }"
                            >
                              <a
                                v-bind="attrs"
                                v-on="on"
                                color="primary"
                                class="eval-link"
                                @click.stop.prevent="openEvaluationDialog(item)"
                              >
                                {{ item.collection_plan_object.display.default.length > 40 ? item.collection_plan_object.display.default.substring(0, 40) + '...' : item.collection_plan_object.display.default}}
                              </a>
                            </template>
                            <span>{{ item.collection_plan_object ? item.collection_plan_object.display.default : 'Name der Sammlung nicht gefunden'}}</span>
                          </v-tooltip>
                          <template v-else>
                            Name der Sammlung nicht gefunden
                          </template>
                        </template>

                        <template v-slot:item.device="{ item }">
                            <div v-if="item.collection_plan_object">
                              <span>
                                {{ getDeviceByUrl(item.device.url) | deviceToClassName }}
                                <template v-if="item.device_object !== null"> "{{ item.device_object.name }}"</template>
                              </span>
                            </div>
                        </template>
                        <template v-slot:item.paymentmethod="{ item }">
                          <div v-if="item.type === 'unset'"><!-- This is an online payment -->
                            {{ item.online_payment_type_human }}
                          </div>
                          <div v-else>
                            {{ item.type_human }}
                          </div>
                        </template>
                        <template v-slot:item.is_reconciled="{ item }">
                          <v-icon v-if="item.is_reconciled">{{ mdiCheck }}</v-icon>
                          <v-icon v-else-if="item.state === PaymentState.REVERSED">{{ mdiClose }}</v-icon>
                          <span v-else>-</span>
                        </template>
                        <template v-slot:item.amount="{ item }">
                          <div
                            style="white-space: nowrap; text-align: right;"
                            :class="{ 'text-decoration-line-through' : item.state === PaymentState.REVERSED}"
                          >{{ item.amount|amount }} {{ organization.currency|currencySymbol }}</div>
                        </template>
                        <template v-slot:header.fee_amount="{ header }">
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <span
                                v-bind="attrs"
                                v-on="on"
                              >
                                {{ header.text }}*
                              </span>
                            </template>
                            <span>*{{ $t('collection.feesHint') }}</span>
                          </v-tooltip>
                        </template>
                        <template v-slot:item.fee_amount="{ item }">
                          <template v-if="item.is_reconciled && item.fee_amount">
                            {{ item.fee_amount|amount }} {{ organization.currency|currencySymbol }}
                          </template>
                          <template v-else-if="item.is_reconciled">?</template>
                          <template v-else>-</template>
                        </template>
                      </v-data-table>
                    </template>

                    <div
                      v-else
                      v-for="(entry, index) in (listMode === 'einzel' ? singleDonations : activeStatsList)"
                      :key="index">
                      <h3 class="day-name">
                        <span>{{ entry.human }}</span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span
                              v-bind="attrs"
                              v-on="on"
                            >
                              <span class="total" v-if="entry.amount">{{ entry.amount|amount }} {{ organization.currency|currencySymbol }}</span>
                              <span class="total" v-else></span>
                            </span>
                          </template>
                          <span>{{ entry.count }} Zahlung<template v-if="entry.count > 1">en</template>, im Schnitt {{ entry.amount / entry.count|amount}}</span>
                        </v-tooltip>
                      </h3>

                      <template v-if="listMode === 'einzel'">
                        <div class="member-card" v-for="member in (entry.collections)" v-bind:key="member.id">
                          <v-icon class="px-2" color="primary" v-if="member.collection_plan_object && member.collection_plan_object.type === 'donation'">{{ Spende }}</v-icon>
                          <v-icon class="px-2" color="primary" v-else>{{ Kollekte }}</v-icon>
                          <div class="member-information">
                            <div class="member-name" v-if="!member.collection_plan_object">
                              <span
                                class="eval-group"
                              >
                                Name der Sammlung nicht gefunden
                              </span>
                            </div>
                            <div class="member-name" v-else>
                              <a class="eval-link" @click.stop.prevent="openEvaluationDialog(member)">{{ member.collection_plan_object.display.default }}</a>
                            </div>
                            <div v-if="member.collection_plan_object">
                              <span class="variant" v-if="member.collection_plan_object.type === 'donation'">Spende</span>
                              <span class="variant" v-else>{{ variants[member.collection_plan_object.collection_variant] }}</span>
                              <span> {{ deviceClassToName(getDeviceByUrl(member.device.url).device_class) }}<template v-if="member.device_object !== null"> "{{ member.device_object.name }}"</template></span>
                            </div>
                            <div>
                              <span :class="{'text-decoration-line-through': member.state === PaymentState.REVERSED}">
                                Zahlung per {{ member.type === 'unset' ? member.online_payment_type_human : member.type_human }}.
                              </span>
                              <span v-if="member.is_reconciled"> Zahlungseingang bestätigt.</span>
                              <span v-else-if="member.state === PaymentState.REVERSED"> Rückabwicklung der Zahlung.</span>
                              <span v-else> Zahlungseingang noch nicht bestätigt.</span>
                            </div>
                            <div
                              v-if="member.amount"
                              class="member-amount"
                              :class="{ 'text-decoration-line-through': member.state === PaymentState.REVERSED }"
                            >
                              <span>{{ member.amount|amount }} {{ organization.currency|currencySymbol }}</span>
                            </div>
                            <div v-if="member.dst_organization && organization.url !== member.dst_organization">
                              <v-icon dense class="mr-1">mdi-file-tree</v-icon>
                              <span>Gesammelt von: "{{ getOrganizationByUrl(member.dst_organization) ? getOrganizationByUrl(member.dst_organization).name : 'unbekannt' }}"</span>
                            </div>
                          </div>
                        </div>

                      </template>
                      <template v-else>
                        <div class="member-card" v-for="member in (entry.collectionSums ? entry.collectionSums : entry.collections)" v-bind:key="member.id">
                          <v-icon class="px-2" color="primary" v-if="member.collection_type === 'donation'">{{ Spende }}</v-icon>
                          <v-icon class="px-2" color="primary" v-else>{{ Kollekte }}</v-icon>
                          <div class="member-information">
                            <div
                              class="member-name eval-group text-primary cursor-pointer"
                              @click="openEvaluationDialog(member)">
                              {{ member.name }}
                            </div>
                            <div>
                              <v-tooltip
                                v-if="organization.url !== member.org && member.org !== null"
                                bottom
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-icon
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                  >
                                    mdi-file-tree
                                  </v-icon>
                                </template>
                                <span>Sammlung von: "{{ allOrganizations[member.org] ? allOrganizations[member.org].name : 'unbekannt' }}"</span>
                              </v-tooltip>
                              <div>
                                <span class="variant" v-if="member.collection_type === 'donation'">Spende</span>
                                <span class="variant" v-else>{{ variants[member.collection_variant] }}</span>
                                <span v-if="member.device"> {{ deviceClassToName(getDeviceByUrl(member.device.hasOwnProperty('url') ? member.device.url : member.device).device_class) }}</span>
                              </div>
                            </div> <!--  @click="collectionDetailsData = member; collectionDetailsDialog = true" -->
                            <template v-if="entry.collectionSums">
                              <div v-for="sum in entry.collectionSums" v-bind:key="sum.key">
                              </div>
                            </template>
                            <div v-if="member.amount" class="member-amount">
                              <v-tooltip :disabled="member.count < 2" bottom>
                                <template v-slot:activator="{ on, attrs }">
                                  <span
                                    v-bind="attrs"
                                    v-on="on"
                                  >
                                    <span>{{ member.count }} Zahlung<template v-if="member.count > 1">en</template>: {{ member.amount|amount }} {{ organization.currency|currencySymbol }}</span>
                                  </span>
                                </template>
                                <span>Im Schnitt {{ member.amount / member.count|amount}} €</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </div>
                      </template>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
        </v-col>
      </v-row>
      <div v-else-if="statsByMonth">
        <p>Bislang haben noch keine Sammlungen stattgefunden. Bitte kontaktieren Sie uns, wenn Sie Fragen oder Probleme haben!</p>
        <p><a href="mailto:support@digitalwolff.de">support@digitalwolff.de</a></p>
      </div>
      <div v-else>
        <v-progress-circular :size="140"/>
      </div>

      <v-row justify="center">
          <v-dialog
            v-model="collectionDetailsDialog"
            transition="dialog-bottom-transition"
            scrollable
            width="800"
          >
            <collection-details
              :collection="collectionDetailsData"
            ></collection-details>
          </v-dialog>
      </v-row>

      <template>
        <v-dialog
          v-model="evaluationDialog"
          transition="dialog-top-transition"
          scrollable
          width="44em"
        >
          <v-card elevation="0">
            <v-card-text>
              <EvaluationStats
                :evalCollectionPlan="evalCollectionPlan"
                :key="evaluationDialog"
              />
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                @click.stop.prevent="evaluationDialog = false"
                color="primary"
              >
                Schließen
              </v-btn>
            </v-card-actions>

          </v-card>
        </v-dialog>
      </template>
    </div>
  </v-container>
</template>
<script>
import moment from 'moment'
import 'moment/locale/de'
import {
  FETCH_DEVICES,
  FETCH_GLOBAL_DEVICES,
  FETCH_LOCATIONS,
  FETCH_ORGANIZATIONS,
  FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION,
  FETCH_COLLECTION_SUMS_FOR_ORGANIZATION, UPDATE_RECURSIVE,
  FETCH_ORGANIZATION_COLLECTION_PLANS, FETCH_DIRECT_PAYMENT_PAIN_FILES
} from '@/store/action-types'
import { Spende, Kollekte } from '@/lib/icons'
import PaymentState from '@/lib/payment-state'
import { mapGetters } from 'vuex'
import CollectionDetails from '@/components/collection/CollectionDetails'
import EvaluationStats from '@/components/revenue/EvaluationStats'
import { mdiCheck, mdiClose, mdiFileTree } from '@mdi/js'
import { DEVICE_CLASSES, deviceToClassName } from '@/lib/device-db'

const SUM_TAB_GROUPS = [
  { name: "Hauptkollekten", filter: ['collection', 'inner'], dativ: "Hauptkollekten" },
  { name: "Nebenkollekten", filter: ['collection', 'outer'], dativ: "Nebenkollekten" },
  { name: "freie Kollekten", filter: ['collection', 'x'], dativ: "freien Kollekten" },
  { name: "Spenden", filter: ['donation', null], dativ: "Spenden" },
]

export default {
  name: 'organization-collections',
  components: {
    CollectionDetails,
    EvaluationStats
  },
  filters: {
    deviceToClassName,
  },
  data () {
    return {
      Spende,
      Kollekte,
      mdiCheck,
      mdiClose,
      mdiFileTree,
      activeMonth: null,
      variants: {
        inner: 'Hauptkollekte',
        outer: 'Nebenkollekte',
        x: 'Freie Kollekte'
      },
      collectionDetailsDialog: false,
      collectionDetailsData: null,
      listMode: 'zwecke',
      singleDonationsData: [],
      evaluationDialog: false,
      evalCollectionPlan: null,
      evalFullscreen: false,
      printLoad: false,
      PaymentState,
    }
  },
  computed: {
    ...mapGetters('organization', { organization: 'currentOrganization' }),
    ...mapGetters('organization', ['allOrganizations']),
    ...mapGetters('location', ['locationsLookup']),
    ...mapGetters('collectionPlan', ['collectionPlansLookup', { collectionPlans: 'currentCollectionPlans' }]),
    ...mapGetters('device', ['devicesLookup']), // FIXME: This leads to an error. I want to show device infos for Einzelspenden.  FIXED?
    detailsTableHeader () {
      const headers = [
        {
          sortable: false,
          text: "",
          value: "icon"
        },
        {
          sortable: false,
          text: "Zeitpunkt",
          value: "date"
        },
        {
          sortable: false,
          text: "Sammlung",
          value: "collection"
        },
        {
          sortable: false,
          text: "Gerät",
          value: "device"
        },
        {
          sortable: false,
          text: "Zahlmethode",
          value: "paymentmethod"
        },
        {
          sortable: false,
          text: "Eingang bestätigt",
          value: "is_reconciled"
        },
        {
          sortable: false,
          text: "Betrag",
          value: "amount",
          class: "member-header-right"
        },
      ]

      if (this.hasSingleDonationsWithFees) {
        headers.push({
          sortable: false,
          text: "Gebühr",
          value: "fee_amount",
        })
      }

      return headers
    },
    sumTabGroups () {
      return SUM_TAB_GROUPS.map(item => ({
        ...item,
        data: this.activeStatsListFilter(...item.filter),
      })).filter(item => !!item.data.length)
    },
    singleDonationsTable () {
      const resVal = []
      this.singleDonations.forEach((day) => {
        day.collections.forEach((col) => {
          const date = moment(col.date)
          resVal.push({
            ...col,
            dayHuman: date.format('DD.MM.YYYY HH'),
          })
        })
      })
      return resVal
    },
    singleDonations () {
      if (!this.singleDonationsData) {
        return []
      }
      const result = {}
      let counter = 0
      this.singleDonationsData.forEach((item) => {
        const date = moment(item.date)
        const entry = {
          ...item,
          date,
          id: counter,
          dayHuman: date.format('DD. MMMM YYYY'),
          dayKey: date.format('YYYY-MM-DD'),
          dateHuman: date.format('D. MMMM YYYY'),
          dateKey: date.format('YYYY-MM-DD'),
          amount: parseFloat(item.amount)
        }
        counter += 1

        let dayEntry = result[entry.dayKey]
        if (!dayEntry) {
          dayEntry = result[entry.dayKey] = {
            key: entry.dayKey,
            human: entry.dayHuman,
            amount: 0,
            count: 0,
            collections: []
          }
        }
        item.online_payment_type_human = this.paymentTypeToHuman(item.online_payment_type)
        item.type_human = this.paymentTypeToHuman(item.type)
        if (item.device.url in this.orgaDevicesByUrl) {
          item.device_object = this.orgaDevicesByUrl[item.device.url] ?? {}
        } else {
          item.device_object = null
        }
        if (this.collectionPlansLookup && item.collection_plan in this.collectionPlansLookup) {
          item.collection_plan_object = this.collectionPlansLookup[item.collection_plan] ?? {}
        } else {
          item.collection_plan_object = null
        }
        dayEntry.collections.push(item)

        if (entry.state !== PaymentState.REVERSED) {
          dayEntry.amount += parseFloat(item.amount)
        }

        dayEntry.count += 1
      })

      return Object.values(result).sort((a, b) => b.key.localeCompare(a.key))
    },
    hasSingleDonationsWithFees () {
      let hasSingleDonationsWithFees = false
      this.singleDonationsData.forEach(donation => {
        if (hasSingleDonationsWithFees) {
          return
        }

        if (donation.fee_amount) {
          hasSingleDonationsWithFees = true
        }
      })
      return hasSingleDonationsWithFees
    },
    orgaDevicesByUrl () {
      const retVal = {}
      for (const value of this.$store.state.device.devicesByOrganization[this.organization?.url] ?? []) {
        retVal[value.url] = value
      }
      return retVal
    },
    monthitems () {
      const resval = []
      if (!this.organization || !this.statsByMonth) {
        return []
      }
      const currency = this.$options.filters.currencySymbol(this.organization.currency)
      this.monthList.forEach((item) => {
        resval.push({ id: item.key, name: item.human + ' - ' + item.amount + ' ' + currency })
      })
      return resval
    },
    collectionData () {
      return this.$store.state.dashboard.paymentHistoryAggregateByOrganizationUrl[this.$store.getters['organization/currentOrganization']?.url] ?? []
    },
    currentMonth () {
      return moment(this.activeMonth, "YYYY-MM").format('MMMM YYYY')
    },
    statsByMonth () {
      moment.locale('de')
      if (!this.collectionData || !this.collectionData.byDay) return null
      const result = {}
      let counter = 0
      this.collectionData.byDay.forEach((item) => {
        const date = moment(item.date)
        const entry = {
          ...item,
          date,
          id: counter,
          monthHuman: date.format('MMMM YYYY'),
          monthKey: date.format('YYYY-MM'),
          dateHuman: date.format('D. MMMM YYYY'),
          dateKey: date.format('YYYY-MM-DD'),
          amount: parseFloat(item.amount),
          currency: item.currency
        }
        counter += 1

        let monthEntry = result[entry.monthKey]
        if (!monthEntry) {
          monthEntry = result[entry.monthKey] = {
            key: entry.monthKey,
            human: entry.monthHuman,
            amount: 0,
            count: 0,
            days: {},
            currency: item.currency
          }
        }
        let dateEntry = monthEntry.days[entry.dateKey]
        if (!dateEntry) {
          dateEntry = monthEntry.days[entry.dateKey] = {
            date: entry.date,
            key: entry.dateKey,
            human: entry.dateHuman,
            collections: [],
            amount: 0,
            count: 0
          }
        }
        dateEntry.collections.push(entry)
        dateEntry.amount += entry.amount
        dateEntry.count += entry.count
        monthEntry.amount += entry.amount
        monthEntry.count += entry.count
      })
      if (this.collectionData.collectionSums) {
        this.collectionData.collectionSums.forEach((item) => {
          const date = moment(item.date)
          const monthKey = date.format('YYYY-MM')
          const dateKey = date.format('YYYY-MM-DD')
          if (result[monthKey]?.days[dateKey]?.collections) {
            result[monthKey] = {
              ...(result[monthKey] ?? {}),
              days: {
                ...((result[monthKey]?.days) ?? {}),
                [dateKey]: {
                  ...(result[monthKey]?.days[dateKey] ?? {}),
                  collectionSums: (result[monthKey]?.days[dateKey]?.collectionSums ?? []).concat([item]),
                }
              }
            }
          }
        })
      }
      return result
    },
    monthList () {
      if (!this.statsByMonth) {
        return []
      }
      return Object.values(this.statsByMonth).sort((a, b) => b.key.localeCompare(a.key))
    },
    activeStatsList () {
      const retval = Object.values(this.statsByMonth[this.activeMonth]?.days ?? {})
      retval.sort((a, b) => {
        return -a.key.localeCompare(b.key)
      })
      return retval
    },
    recursive: {
      get () {
        return this.$store.state.userPrefs.recursive
      },
      async set (value) {
        await this.$store.dispatch("userPrefs/" + UPDATE_RECURSIVE, { recursive: value })
      }
    }
  },
  async mounted () {
    this.$nextTick(() => {
      Promise.allSettled([
        this.$store.dispatch('organization/' + FETCH_ORGANIZATIONS),
        this.$store.dispatch('location/' + FETCH_LOCATIONS),
        this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, { organizationUrl: this.organization.url }),
        this.$store.dispatch('device/' + FETCH_GLOBAL_DEVICES),
        this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.organization.url),
        this.$store.dispatch('payment/' + FETCH_DIRECT_PAYMENT_PAIN_FILES)
      ])
    })
  },
  methods: {
    paymentTypeToHuman (pt) {
      if (!pt) {
        return 'unbekannter Typ'
      }
      if (pt === 'creditcard') {
        return 'Kreditkarte'
      } else if (pt === 'giropay') {
        return 'Giropay'
      } else if (pt === 'sepadd') {
        return 'SEPA Lastschrift'
      } else if (pt === 'googlepay') {
        return 'Google Pay'
      } else if (pt === 'applepay') {
        return 'Apple Pay'
      } else if (pt === 'paypal') {
        return 'PayPal'
      } else if (pt === 'girocard') {
        return 'Girocard'
      } else {
        return pt
      }
    },
    getLocationByUrl (locaUrl) {
      if (!locaUrl && locaUrl !== '') {
        return null
      }
      return this.locationsLookup[locaUrl]
    },
    getOrganizationByLocationUrl (locaUrl) {
      if (!this.getLocationByUrl(locaUrl)) {
        return null
      }
      return this.allOrganizations[this.getLocationByUrl(locaUrl).organization]
    },
    getOrganizationByUrl (orgaUrl) {
      if (!orgaUrl && orgaUrl !== '') {
        return null
      }
      return this.allOrganizations[orgaUrl]
    },
    getDeviceByUrl (deviceUrl) {
      if (!this.devicesLookup[deviceUrl]) {
        return { name: 'kollekte.app/spende.app', device_class: 'WEB', device: null }
      }
      return {
        name: this.devicesLookup[deviceUrl].name,
        device_class: this.devicesLookup[deviceUrl].device_class,
        device: this.devicesLookup[deviceUrl] ?? []
      }
    },
    deviceClassToName (deviceClass) {
      return "per " + (DEVICE_CLASSES[deviceClass] ?? "unbekanntem Gerät")
    },
    activeStatsListFilterSum (entries) {
      return entries.reduce((acc, entry) => acc + entry.sum, 0)
    },
    activeStatsListFilter (type, variant) {
      const result = []
      for (const entry in this.activeStatsList) {
        if (this.activeStatsList[entry].collectionSums) {
          for (const y in (this.activeStatsList[entry].collectionSums)) {
            if (this.activeStatsList[entry].collectionSums[y].collection_type === type && (this.activeStatsList[entry].collectionSums[y].collection_variant === variant || variant === '')) {
              const collectionName = this.activeStatsList[entry].collectionSums[y].name
              const collectionAmount = parseFloat(this.activeStatsList[entry].collectionSums[y].amount)
              const currency = this.activeStatsList[entry].collectionSums[y].currency
              const key = this.activeStatsList[entry].key
              const id = this.activeStatsList[entry].collectionSums[y].id
              let found = false
              for (let index = 0; index < result.length; index++) {
                if (result[index].name === collectionName) {
                  result[index].sum = result[index].sum + collectionAmount
                  found = true
                }
              }
              if (!found) {
                result.push({
                  id,
                  name: collectionName,
                  sum: collectionAmount,
                  currency,
                  count: 1,
                  key
                })
              }
            }
          }
        }
      }
      return result
    },
    openEvaluationDialog (eC) {
      this.evalCollectionPlan = eC.collection_plan
      this.evaluationDialog = true
    }
  },
  watch: {
    activeMonth: async function (newVal) {
      if (newVal) {
        await this.$store.dispatch('dashboard/' + FETCH_COLLECTION_SUMS_FOR_ORGANIZATION, { organizationUrl: this.organization.url, yearMonth: newVal })

        const thismonth = moment(newVal).format('YYYY-MM')
        const startOfMonth = moment(thismonth).clone().startOf('month').format('YYYY-MM-DD')
        const endOfMonth = moment(thismonth).clone().endOf('month').add(1, 'days').format('YYYY-MM-DD')
        const response = await this.$store.getters.restApi.get(`${this.organization.url}payments/?recursive=${this.recursive}&since=${startOfMonth}&before=${endOfMonth}&tz=Europe/Berlin`)
        this.singleDonationsData = response.data
      }
    },
    monthList: function (newVal) {
      if (newVal && newVal.length && !this.activeMonth) {
        this.activeMonth = newVal[0].key
      }
    },
    recursive: async function () {
      await this.$store.dispatch('dashboard/' + FETCH_DASHBOARD_PAYMENT_HISTORY_FOR_ORGANIZATION, { organizationUrl: this.organization.url })
      if (!this.activeMonth) {
        // Implicit fetch because a change in the dashboard will update monthList.
        // The watcher on monthList will set activeMonth.
        // The watcher on activeMonth will fetch.
      } else {
        // Explicitly fetch this month with new recursive setting
        // FIXME There's a bug waiting here: What if activeMonth is set, but not in the new monthList?
        await this.$store.dispatch('dashboard/' + FETCH_COLLECTION_SUMS_FOR_ORGANIZATION, { organizationUrl: this.organization.url, yearMonth: this.yearMonth })

        const thismonth = moment(this.activeMonth).format('YYYY-MM')
        const startOfMonth = moment(thismonth).clone().startOf('month').format('YYYY-MM-DD')
        const endOfMonth = moment(thismonth).clone().endOf('month').add(1, 'days').format('YYYY-MM-DD')
        const response = await this.$store.getters.restApi.get(`${this.organization.url}payments/?recursive=${this.recursive}&since=${startOfMonth}&before=${endOfMonth}&tz=Europe/Berlin`)
        this.singleDonationsData = response.data
      }
    },
    organization: {
      immediate: true,
      async handler (newVal) {
        if (newVal?.url) {
          await Promise.allSettled([
            this.$store.dispatch('device/' + FETCH_DEVICES, newVal?.url),
          ])
        }
      }
    },
  }
}
</script>

<style lang="stylus">
#organization-collections
  #organization-container
    #left-column
      width: 17em
      overflow-y: auto
      border-right: $clr-grey-400 solid 1px

    #right-column
      #member-list
        font-size: 14px;
        p
          font-size: 1.2rem
          strong
            color: var(--clr-environment)

      .tabs-default
        tabs-style(active-color: var(--clr-environment), indicator-color: var(--clr-environment))
        text-transform: none
  h3.day-name
    color: $clr-secondary-text-light
    display: flex
    flex-direction: row
    justify-content: space-between

  .month-card
    card()
    display: flex
    margin: 18px
    max-width: 100%
    flex-direction: column
    padding: 16px
    cursor: pointer
    outline: 2px solid grey
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2), 0 3px 10px 0 rgba(0,0,0,0.19)

    &:hover,
    &.active-month-card
      outline: 2px solid var(--clr-environment)
      transition: outline 0.2s ease-in-out

    .month-title
      font-weight: bold
    .month-total
      margin-left: auto
      font-weight: bold
      text-decoration: underline
    .month-details
      color: $clr-secondary-text-light

  .member-table
    .member-icon
      padding: 0px;
      margin: 0px;
      height: 40px
    .member-header-right
      text-align: right!important
  .member-card
    card()
    display: flex
    margin-bottom: 18px
    position: relative
    align-items: stretch
    outline: 2px solid var(--clr-environment)

    &.member-card-overview
      outline: unset

    &.member-card-sum
      margin-top 2px solid var(--clr-environment)
      margin-bottom: 0

    .member-icon
      padding: 8px;
      margin: 16px;
      height: 40px
      &.in-progress
        color: $clr-orange-400
      &.done
        color: $clr-green
    .member-information
      display: flex
      flex-direction: column
      padding: 8px;
      width: 100%
      justify-content: space-around
      .member-amount
        position: absolute
        top: 8px
        right: 10px
      .member-name
        font-weight: bold
      .member-sum
        font-weight: bold
      .member-created
        font-weight: normal
        color: $clr-secondary-text-light
      .receipt-image
        card()
        max-width: 360px
        display: flex
        justify-content: center
        margin: 16px 8px
        pre
          padding: 16px 24px
          font-size: 1rem
    .right-button
        margin-left: auto

    .eval-link
      cursor: pointer
      margin-right: 4em
      display: block

    .eval-group
      margin-right: 10em
      display: block

@media print
  html, body
    display: inline
    -webkit-print-color-adjust: exact
    height: 100% !important
    margin: 0em !important
    padding: 0em !important
    #head-area
      display: none
  #print-load
    display: none
  #wrapper-menu
    display: none
</style>
