import { mdiStarOutline, mdiWeb } from "@mdi/js"
import { OrgBundeskirche, OrgGemeinde, OrgKirchenkreis, OrgLandeskirche } from "@/lib/icons"

export const levelOptions = [
  {
    label: 'International',
    value: 'international',
    icon: mdiWeb,
  },
  {
    label: 'Land',
    value: 'country',
    icon: OrgBundeskirche,
  },
  {
    label: 'Region',
    value: 'region',
    icon: OrgLandeskirche,
  },
  {
    label: 'Bezirk',
    value: 'district',
    icon: OrgKirchenkreis,
  },
  {
    label: 'Gemeinde',
    value: 'parish',
    icon: OrgGemeinde,
  },
  {
    label: 'Andere',
    value: 'other',
    icon: mdiStarOutline,
  }
]
