import moment from 'moment'
import config from '@/config'
import { CLEAR_WAITING_PROMISES, INVALIDATE_CACHE, START_TIMER, UPDATE_TIME } from './action-types'
import {
  DEC_LOADING,
  INC_LOADING,
  SET_ERROR_MESSAGE,
  SET_SNACKBAR_MESSAGE,
  SET_TIME, SET_VERSION,
  SET_WAITING_PROMISE
} from './mutation-types'
import { merge } from 'lodash'

export default {
  state () {
    return {
      now: moment(),
      version: {
        frontend: JSON.parse(JSON.stringify(config.frontendVersion)),
        backend: null,
      },
      loading: 0,
      error: null,
      snackbarMessage: null,
      waitingPromises: {},
    }
  },

  actions: {
    [START_TIMER] ({ commit }) {
      function doUpdate () {
        commit(SET_TIME, moment())
        // Execute this again at ~1 second past the next full minute
        setTimeout(doUpdate, 60000 - (moment() % 60000) + 1000)
      }
      doUpdate()
    },
    [UPDATE_TIME] ({ commit }) {
      commit(SET_TIME, moment())
    },
    [INVALIDATE_CACHE]: async function ({ commit }, { key }) {
      return commit(SET_WAITING_PROMISE, { key, promise: null })
    },
  },

  mutations: {
    [SET_TIME] (state, now) { state.now = now },
    [INC_LOADING] (state) { state.loading++ },
    [DEC_LOADING] (state) { state.loading-- },
    [SET_ERROR_MESSAGE] (state, { message }) { state.error = message },
    [SET_SNACKBAR_MESSAGE] (state, { message }) { state.snackbarMessage = message },
    [SET_WAITING_PROMISE] (state, { key, promise }) { state.waitingPromises[key] = promise },
    [CLEAR_WAITING_PROMISES] (state) { state.waitingPromises = {} },
    [SET_VERSION] (state, payload) { state.version = merge(state.version, payload) },
  }
}
