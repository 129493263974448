
export function * flattenObj (obj, prefixName = "") {
  if (typeof obj !== 'object') {
    yield [prefixName, obj]
  } else {
    if (prefixName !== "") {
      yield [prefixName, {}]
    }
    if (obj !== null) {
      for (const [key, value] of Object.entries(obj ?? {})) {
        yield * flattenObj(value, prefixName !== "" ? `${prefixName}/${key}` : `${key}`)
      }
    }
  }
}

export function erectObj (keyValueList) {
  const retval = {}
  for (const [key, value] of keyValueList) {
    const keyparts = key.split("/")
    const target = keyparts.slice(0, -1).reduce((prev, curr) => {
      if (!Object.prototype.hasOwnProperty.call(prev, curr)) {
        prev[curr] = {}
      }
      return prev[curr]
    }, retval)
    target[keyparts[keyparts.length - 1]] = value
  }
  return retval
}

export function removeEmptyProperties (obj) {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null && v !== "" && !(v && Object.keys(v).length === 0)))
}

export const ALLOW_RECURRING_PAYMENTS = "allow_recurring_payments"
export const ALLOW_EMAIL_CONTACT = "allow_email_contact"
export const ALLOW_CASH_PAYMENTS = "allow_cash_payments"
export const ALLOW_COLLECTION_SPLIT = "allow_collection_split"
export const DONATION_RECEIPT_FOR_COLLECTION = "donation_receipt_for_collection"
// export const REQUEST_DONATION_RECEIPT = "request_donation_receipt"
// export const REQUIRE_PDATA = "require_pdata"

export const FEATURE_TO_SETTINGS_KEY_MAP = {
  [ALLOW_RECURRING_PAYMENTS]: 'Dauerspendenmodul',
  [ALLOW_EMAIL_CONTACT]: 'Informationsanforderungen',
  [ALLOW_CASH_PAYMENTS]: 'Barspendenmodul',
  [ALLOW_COLLECTION_SPLIT]: 'Sammlungsgruppen'
  // request_donation_receipt: 'Spendenbescheinigungen',
  // donation_receipt_for_collection: 'Spendenbescheinigungen für Kollekten',
}
