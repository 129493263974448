export default {
  addressTypes: {
    primary: 'Hauptadresse',
    billing: 'Rechnungsadresse',
    physical: 'Standort',
    other: 'Sonstige'
  },
  contact: {
    street: 'Straße',
    number: 'Hausnummer',
    zip: 'PLZ',
    city: 'Ort'
  },
  general: {
    address: 'Adresse',
    notAvailable: 'Nicht vorhanden',
    email: 'E-Mail',
    phone: 'Telefon',
    welcome: 'Willkommen'
  },
  action: {
    cancel: 'Abbrechen',
    create: 'Anlegen',
    confirm: 'Bestätigen',
    next: 'Weiter',
    save: 'Speichern',
  },
  actionResponse: {
    savingSuccessful: 'Speichern erfolgreich'
  },
  banking: {
    accountHolder: 'Kontoinhaber',
    bankingDetails: 'Bankverbindung',
    creditInstitution: 'Kreditinstitution',
    reference: 'Verwendungszweck / Stichwort',
  },
  collection: {
    donation: 'Spende | Spenden',
    donationsAndFees: 'Spendeneinnahmen und Gebühren',
    donationsMinusFees: 'Spenden abzgl. Gebühren',
    fees: 'Gebühr | Gebühren',
    feesHint: 'gegebenenfalls zzgl. Transaktionsgebühren',
    feeDonationRatioExplanation: 'Der Anteil an direkten Gebühren* entspricht {ratio}% der Spendensumme von {donations}€.',
  },
  organization: {
    notificationContactsMessage: 'Es wird {count} Empfänger benachrichtigt. | Es werden {count} Empfänger benachrichtigt.',
  },
  collectionPlan: {
    accountingDataVerified: 'Sachlich richtig',
    contact: 'Kontakt',
    contactPerson: 'Ansprechpartner',
    costCenters: 'Sachkonto & Kostenstellen',
    description: 'Beschreibungstext',
    displayCollectionPurpose: 'Anzeige des Sammlungszwecks',
    foreignCollectionHint: 'Dies ist eine Weiterleitungskollekte für',
    organization: 'Organisation',
    collection: 'Sammlung',
    share: 'Anteil',
    steps: {
      chooseDevices: 'Geräteauswahl & Vorschau',
      previewDevices: 'Vorschau',
      collectionPeriod: 'Zeitraum der Sammlung',
      moreInformation: 'Mehr Informationen für kollekte.app / spende.app',
      collectionType: 'Art der Kollekte',
      billingInformation: 'Abrechnungsinformationen',
      collectionScope: 'Geltungsbereich der Sammlung',
      finalize: 'Prüfen und Speichern'
    },
    labels: {
      characters: 'Zeichen',
      collectionPurpose: 'Sammlungszweck',
      collectionType: 'Art der Sammlung',
      collectionGroup: 'Sammlungsgruppe',
      collectionGroupWeight: 'Teil | Teile',
      deleteIrreversibly: 'Unwiderruflich löschen',
      descriptionText: 'Beschreibungstext',
      from: 'Von',
      fromNow: 'Von (ab sofort)',
      indefinite: 'unbegrenzt',
      timingAndPeriod: 'Zeitpunkt / -Raum',
      to: 'Bis',
      toInfinity: 'Bis (unbegrenzt)',
      website: 'Website',
      foreignCollectionTarget: 'Ziel der Weiterleitungskollekte',
      allowRecurring: 'Dauerspenden ermöglichen (mindestens 6 Monate Sammlungsdauer)',
      allowRecurringShort: 'Dauerspenden ermöglichen',
      foreignCollection: 'Weiterleitungskollekte',
      recurringDonation: 'Dauerspende',
    },
    hints: {
      activateRecurringDonation: 'Möchten Sie wirklich die Dauerspendenoption für diese Sammlung aktivieren? Dieser Vorgang kann nicht rückgängig gemacht werden.',
      visibilityOnSimulatedDisplays: '<strong>Hinweis:</strong> Sie können die Sammlungszweckanzeige in den simulierten Bildschirmen direkt editieren. Verwenden Sie ggbf. Zeilenumbrüche und Kürzungen um die Lesbarkeit zu erhöhen.',
      costCenterInfo: 'Wenn Sie kein Sachkonto und Kostenstelle angeben, wird Ihre Finanzverwaltung informiert und wird bei Ihnen nachfragen.',
      mustEnterCollectionPurpose: 'Bitte geben Sie einen Sammlungszweck an!',
      noDescriptionTextAvailable: 'Kein Beschreibungstext vorhanden',
      visibleForDonors: '<strong>Hinweis:</strong> Diese Informationen sind für den Spender sichtbar!'
    }
  },
  evaluation: {
    atDate: 'am | zuletzt am',
    mostDonationsPerDay: 'Meiste Spenden pro Tag',
  },
  nestedOrganization: {
    addChildOrganization: 'Kindorganisation hinzufügen',
    addUser: 'Benutzer hinzufgen',
    ownDevices: 'Eigene Geräte',
    showLocations: 'Orte anzeigen',
    showUsers: 'Benutzer anzeigen'
  },
  dashboard: {
    aggregate: {
      total: 'Spendeneinnahmen',
      average: 'Durchschnitt',
      highest: 'Höchste Einzelspende',
      perDay: 'Spendenhöhe pro Tag'
    },
    collectionType: {
      collection: 'Kollekte',
      donation: 'Spende',
      null: 'Sonstige'
    }
  },
  kigeLoginInfo: {
    reasonsToUseHeader: '5 Gründe für kirchgeld.digital',
    benefitsHeader: 'Wann ist kirchgeld.digital das Richtige für Sie?',
    wayToKige: 'Ihr Weg zu kirchgeld.digital',
    introduction: "kirchgeld.digital digitalisiert und automatisiert die Erhebung von Kirchgeld – inklusive Schnittstelle in die Buchhaltung. Manuelle Schritte werden auf das Nötigste reduziert, der Sachbearbeiter erhält eine kontrollierende / freigebende Rolle und hat so mehr Ressourcen für andere Aufgaben. ",
    features: "Schon gewusst? Das allgemeine Kirchgeld, auch Ortskirchensteuer genannt, ist eine Sonderform der Kirchensteuer, die grundsätzlich am Hauptwohnsitz eines Kirchenmitgliedes erhoben wird. Der kirchlichen Verwaltung wird ein Datensatz vom Einwohnermeldeamt zur Verfügung gestellt, mithilfe dessen dann sogenannte Kirchgeldbriefe versandt werden. Die daraus resultierenden Zahlungen / Spenden müssen anschließend verbucht werden, Dankesbriefe und Zuwendungsbestätigungen werden verschickt.",
    benefits: [
      'Das kirchliche Verwaltungsamt ist für die Abwicklung des Kirchgeldes zuständig.',
      'Das Verwaltungsamt erhält Daten vom Einwohnermeldeamt.',
      'Der bisherige Prozess basiert auf Excel-Tabellen / veralteter oder abgekündigter Software oder auf dem Wissen von einzelnen Mitarbeitern.',
      'Anbindung an die Buchhaltung und automatische Verbuchung des Kirchgeldes ist erwünscht.',
      'Prozesse in der Verwaltung müssen optimiert werden und allgemeines & freiwilliges Kirchgeld sowie Spenden gewinnen an Bedeutung.'
    ],
    reasonsToUse: [
      'Moderne, webbasierte Oberflächen für einfache Bedienbarkeit',
      'Geschützte Umgebung auf europäischen Servern, natürlich EKD-DSG/VO-kompatibel',
      'Individuelle Schnittstellen an viele Buchhaltungsprogramme wie NAVISION',
      'Integriertes Online-Zahlungsmodul – kein zusätzlicher Aufwand bei der Einbindung',
      'Datenmigration Ihrer bestehenden Lösung'
    ],
    contact: '<span style="font-size: 1rem"><span class="text-primary">Stellen Sie eine unverbindliche Anfrage auf </span> <a class="text-decoration-underline" href="https://digitalespende.de/kontakt/">digitalespende.de/kontakt</a></span>',
    offer: "Je nach Spendenvolumen, Gemeindeanzahl und notwendigen Schnittstellen bekommen Sie ein individuell zugeschnittenes Angebot",
    possibilityToStart: "Unterschrift der Verträge inkl. Zahlungsdienstevertrag, Import des Altbestandes: möglicher Start innerhalb von sechs Wochen",
    helpWithDigitization: 'Wir helfen Kirche mit Digitalisierung: Zusätzlich zu ' +
      'kirchgeld.digital bieten wir digitale Online-Lösungen ' +
      'für <a class="text-decoration-underline" href="https://kollekte.app/" target="_blank">Kollekten</a>, <a class="text-decoration-underline" href="https://spende.app/" target="_blank">Spenden</a>, <a class="text-decoration-underline" href="https://www.spendenbescheinigung.digital/" target="_blank">Spendenbescheinigungen</a> und ' +
      '<a class="text-decoration-underline" href="https://veranstaltung.app/" target="_blank">Veranstaltungen</a>.',
    otherServices: 'Ebenfalls erhältlich sind elektronische Spendengeräte wie die <a class="text-decoration-underline" href="https://www.digitalespendensaeule.de/" target="_blank">Digitale Spendensäule</a> oder <a class="text-decoration-underline" href="https://www.digitalespendensaeule.de/" target="_blank">Digitale Spendendose</a>.',
    joinWithUs: 'Unsere umfangreiche Erfahrung mit Verwaltung, Buchhaltung und Denkmalschutz macht uns zum idealen Partner auch für Ihre Gemeinde. Überzeugen Sie sich selbst auf <a class="text-decoration-underline" href="https://digitalespende.de/" target="_blank">digitalespende.de</a>!',
    placeRequest: 'Anfrage stellen'
  },
  kirchgeld: {
    donationStatus: {
      donationStatusIsUnimportant: 'alle',
      hasDonatedThisYear: 'hat im aktuellen Kalenderjahr ({year}) gespendet',
      hasDonatedLastYear: 'hat im VORHERIGEN Kalenderjahr ({year}) gespendet',
      hasDonatedOtherYear: 'hat im Jahr {year} gespendet',
      hasNotDonatedThisYear: 'hat NICHT im aktuellen Kalenderjahr ({year}) gespendet',
      hasNotDonatedLastYear: 'hat NICHT im VORHERIGEN Kalenderjahr ({year}) gespendet',
      hasNotDonatedOtherYear: 'hat NICHT im Jahr {year} gespendet'
    }
  }
}
